import { AxiosResponse } from "axios";
import { axiosInterceptor } from "./axios";

import { SamlIdpSettings, Section, SmtpSettings } from "types";
import { useAppConfigStore } from "store";

const getSettingsApiPath = () => {  
  const appState = useAppConfigStore.getState();
  return `${appState.settingsApiPath}`;
}

const getUserPreferencesApiPath = () => {
  const appState = useAppConfigStore.getState();
  return `${appState.userPreferencesApiPath}`;
}

export const getSectionsApi = async () : Promise<AxiosResponse<any, any>>=>  axiosInterceptor("get",getSettingsApiPath());  

 

export const setSectionsApI = async (sections: Section[]): Promise<AxiosResponse<any, any>> => 
  axiosInterceptor("put", getSettingsApiPath(), sections );


export const getSamlIdpSettingsApi = async () : Promise<AxiosResponse<any, any>> => axiosInterceptor("get",`${getSettingsApiPath()}/idp`);

export const setSamlIdpSettingsApi = async (samlIdpSettings: SamlIdpSettings) : Promise<AxiosResponse<any, any>> => axiosInterceptor("post",`${getSettingsApiPath()}/idp`, samlIdpSettings);

export const getSamlSpSettingsApi = async () : Promise<AxiosResponse<any, any>> => axiosInterceptor("get",`${getSettingsApiPath()}/sp/metadata`);

export const putTestApi = async () : Promise<AxiosResponse<any, any>> => axiosInterceptor("put",`${getSettingsApiPath()}/oui`,);

export const getSmtpSettingsApi = async () : Promise<AxiosResponse<any, any>> => axiosInterceptor("get",`${getUserPreferencesApiPath()}/smtp`);

export const setSmtpSettingsApi = async (smtpSettings: SmtpSettings) : Promise<AxiosResponse<any, any>> => axiosInterceptor("put",`${getUserPreferencesApiPath()}/smtp`, smtpSettings);