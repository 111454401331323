import { App as AntdApp, ConfigProvider, Spin, ThemeConfig, theme } from "antd";
import {
  useEdgeGlobalStore,
  useGoalStore,
  useMetricStore,
  useOrganizationStore,
  useProfileStore,
  useRbacStore,
  useSearchArtifactStore,
  useSettingsStore,
  useTokenStore,
  useWorkflowStepsStore,
  useDashboardStore,
  useAppStore,
  useAppConfigStore,
} from "store";

import { useEffect, useState } from "react";
import { GlobalStyles } from "styles";
import { Router } from "./router/Router";

import "./App.scss";

import { ThemeManager } from "ThemeManager";
import { NotificationHolder } from "./components/Notification";
import { AuthProviderConfiguration } from "types";

const app = (): JSX.Element => {
  const tokenFields = useTokenStore((state) => state.fields);
  const fetchConfig = useAppConfigStore((state) => state.fetchAppConfig);

  /* The fields we need to configure the auth provider come from the appconfig*/
  const initializeAuthProvider = useTokenStore(
    (state) => state.initializeAuthProvider
  );

  const {
    isCloud,
    isSelfHosted,
    isProduction,
    auth0Domain,
    auth0ClientId,
    auth0Audience,
    auth0Connection,
    auth0RedirectUri,
    auth0Issuer,
    keycloakDomain,
    keycloakClientId,
    keycloakRealm,
    keycloakIssuer,
    keycloakRedirectUri,
    keycloakSignInUri,
  } = useAppConfigStore((state) => ({
    isCloud: state.isCloud,
    isSelfHosted: state.isSelfHosted,
    isProduction: state.isProduction,
    auth0Domain: state.auth0Domain,
    auth0ClientId: state.auth0ClientId,
    auth0Audience: state.auth0Audience,
    auth0Connection: state.auth0Connection,
    auth0RedirectUri: state.auth0RedirectUri,
    auth0Issuer: state.auth0Issuer,
    keycloakDomain: state.keycloakDomain,
    keycloakClientId: state.keycloakClientId,
    keycloakRealm: state.keycloakRealm,
    keycloakIssuer: state.keycloakIssuer,
    keycloakRedirectUri: state.keycloakRedirectUri,
    keycloakSignInUri: state.signinUri,
  }));

  const intializedConfig = useAppConfigStore((state) => state.intialized);
  const [loader, setLoader] = useState(false);

  const { lightMode, lightModePrimaryColor, darkModePrimaryColor } =
    useSettingsStore((state) => ({
      lightMode: state.lightMode,
      lightModePrimaryColor: state.lightModePrimaryColor,
      darkModePrimaryColor: state.darkModePrimaryColor,
    }));

  const getThemeAlgorithm = () =>
    lightMode ? theme.defaultAlgorithm : theme.darkAlgorithm;

  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (e.message === "ResizeObserver loop limit exceeded") {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
    setLoader(true);
    fetchConfig().finally(() => setLoader(false));
  }, []);

  /** Do not run on component mount. Run only when the cookie actually changed */
  const notifyError = (error: string) => {
    // notification.error({ message: "Error", description: error, duration: 6 });
    console.error("error", error);
  };

  useEffect(() => {
    const onTokenChange = async () => {
      try {
        setLoader(true);
        if (tokenFields?.isAuthenticated && intializedConfig) {
          if (isSelfHosted) {
            useSettingsStore
              .getState()
              .getSmtpSettings()
              .catch(() => notifyError("Failed to get smtp setting settings"));
          }
          useSettingsStore
            .getState()
            .initMode(
              tokenFields?.lightMode ?? true,
              tokenFields?.lightModePrimaryColor ?? "#325BB1",
              tokenFields?.darkModePrimaryColor ?? "#7F7F7F"
            );
          useSettingsStore
            .getState()
            .getUserPreferences()
            .catch(() => notifyError("Failed to get user preferences")),
            useGoalStore
              .getState()
              .listGoals()
              .catch(() => notifyError("Failed to get goals")),
            useProfileStore
              .getState()
              .getProfile()
              .catch(() => notifyError("Failed to get profile"));
          useWorkflowStepsStore
            .getState()
            .getActions()
            .catch(() => notifyError("Failed to get actions")),
            useWorkflowStepsStore
              .getState()
              .getOperators()
              .catch(() => notifyError("Failed to get operators")),
            useWorkflowStepsStore
              .getState()
              .getTriggers()
              .catch(() => notifyError("Failed to get triggers"));
          useRbacStore
            .getState()
            .getEligibility()
            .catch(() => notifyError("Failed to get eligibility"));
          useRbacStore
            .getState()
            .listUsers()
            .catch(() => notifyError("Failed to get users"));
          useRbacStore
            .getState()
            .listRoles()
            .catch(() => notifyError("Failed to get roles"));
          useRbacStore
            .getState()
            .listPermissionSets()
            .catch(() => notifyError("Failed to get permission sets"));
          useRbacStore
            .getState()
            .listClientCredentials()
            .catch(() => notifyError("Failed to get client credentials"));
          useOrganizationStore
            .getState()
            .getSubscriptionState()
            .catch(() => notifyError("Failed to get subscription state"));
          useOrganizationStore
            .getState()
            .getMspEnabled()
            .catch(() => notifyError("Failed to get msp enabled"));
          useOrganizationStore
            .getState()
            .getProviders()
            .catch(() => notifyError("Failed to get providers"));
          useOrganizationStore
            .getState()
            .getSubscribers()
            .catch(() => notifyError("Failed to get subscribers"));
          useMetricStore
            .getState()
            .fetchMetrics()
            .catch(() => notifyError("Failed to get metrics"));
          useOrganizationStore
            .getState()
            .getTags()
            .catch(() => notifyError("Failed to get tags"));
          useSearchArtifactStore
            .getState()
            .getArtifacts()
            .catch(() => notifyError("Failed to get artifacts"));
          useEdgeGlobalStore
            .getState()
            .getEdges()
            .catch(() => notifyError("Failed to get edges"));
          useDashboardStore
            .getState()
            .getDashboards()
            .catch(() => notifyError("Failed to get dashboards"));
          useAppStore
            .getState()
            .getApps()
            .catch(() => notifyError("Failed to get apps"));
        }
      } catch (error) {
        console.error("error", error);
      } finally {
        setLoader(false);
      }
    };
    onTokenChange();
  }, [tokenFields, intializedConfig]);

  useEffect(() => {
    if (intializedConfig) {
      console.debug("App.tsx : Initializing auth provider");
      const authProviderConfig: AuthProviderConfiguration = {
        isCloud,
        isSelfHosted,
        isProduction,
        auth0Domain,
        auth0ClientId,
        auth0Audience,
        auth0Connection,
        auth0RedirectUri,
        auth0Issuer,
        keycloakDomain,
        keycloakClientId,
        keycloakRealm,
        keycloakIssuer,
        keycloakRedirectUri,
        keycloakSignInUri,
      };
      initializeAuthProvider(authProviderConfig);
    }
  }, [intializedConfig]);

  return (
    <Spin spinning={loader}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: lightMode
              ? lightModePrimaryColor
              : darkModePrimaryColor,
          },
          algorithm: getThemeAlgorithm() as ThemeConfig["algorithm"],
        }}
      >
        <AntdApp>
          <ThemeManager>
            <GlobalStyles>
              {intializedConfig ? <Router /> : <></>}
              <NotificationHolder />
            </GlobalStyles>
          </ThemeManager>
        </AntdApp>
      </ConfigProvider>
    </Spin>
  );
};

export default app;
