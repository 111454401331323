import { PropsWithChildren, useEffect, useState } from "react";
import { useOrganizationStore } from "store";
import { useTokenStore } from "store/token";
import { AuthenticationContext } from "./AuthContext";

export const TokenManager = (props: PropsWithChildren) => {
  const context = useOrganizationStore((state) => state.context);
  const contextRequest = useOrganizationStore((state) => state.contextRequest);
  const authProvider = useTokenStore((state) => state.authProvider);
  const tokenStatus = useTokenStore((state) => state.fields);

  const activateContext = useOrganizationStore(
    (state) => state.activateContext
  );
  const clearContextRequest = useOrganizationStore(
    (state) => state.clearContextRequest
  );

  useEffect(() => {
    if (timer) {
      console.debug("Clearing interval");
      clearInterval(timer);
      setTimer(null);
    }

    if (tokenStatus?.isAuthenticated) {
      const pollIntervalMs = 1000 * 60 * 1;
      console.debug(`Setting up interval for ${pollIntervalMs} ms`);
      setTimer(setInterval(refreshToken, pollIntervalMs));
      if (tokenStatus.context && tokenStatus.context !== context) {
        console.debug(`Activating context ${tokenStatus.context}`);
        clearContextRequest();
        activateContext(tokenStatus.context);
      } else {
        console.debug("Context is already activated");
      }
    }

    return () => {
      if (timer) {
        console.debug("Clearing interval");
        clearInterval(timer);
        setTimer(null);
      }
    };
  }, [tokenStatus]);

  useEffect(() => {
    if (!contextRequest) return;
    /* If there is a change in context request then we need to login with a different scope */
    authProvider?.changeTokenScope(`zsgroup-${contextRequest}`);
  }, [contextRequest]);

  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const refreshToken = async () => {
    console.debug("Refreshing token");
    await authProvider?.refreshToken();
  };

  return (
    <AuthenticationContext.Provider
      value={{ authenticated: !!tokenStatus?.isAuthenticated }}
    >
      {props.children}
    </AuthenticationContext.Provider>
  );
};
