import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Modal, Spin, Typography } from "antd";
import { useTokenStore } from "store/token";
import styles from "./Callback.module.scss";

const { Text } = Typography;
const Callback = () => {
  const nagivate = useNavigate();
  const authProvider = useTokenStore((state) => state.authProvider);

  const fields = useTokenStore((state) => state.fields);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    console.debug("Callback useEffect handlerRedirectCallback");
    authProvider?.handleRedirectCallback();
  }, [authProvider]);

  useEffect(() => {
    if (fields?.isAuthenticated) {
      setOpenModal(true);
      nagivate("/home");
    }
  }, [fields]);

  return (
    <div>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <Modal open={true} footer={null} closable={false} centered>
            <div
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text>Authenticating...</Text>
              <Spin spinning={true} size="large" />
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Callback;
