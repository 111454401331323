import { FC, useState, useEffect, useRef } from "react";
import {
  theme,
  Typography,
  Space,
  Badge,
  Image,
  Tag,
  Card,
  Carousel,
  Popover,
  Modal as AntdModal,
  Flex
} from "antd";
import {
  WarningOutlined,
} from "@ant-design/icons";
import { CarouselRef } from 'antd/es/carousel';
import { useOrganizationStore, useSearchStore, useSettingsStore } from "store";
import { ActiveSearchResultMeta, SearchRunResultMeta, SearchRunStatus } from "types";
import { getAvatarColor, getTagColor } from "utility";
import styled from 'styled-components';

import { getAppLogoUrl } from "utility/app";
const { Text } = Typography;

interface CarouselItem {
  rm: SearchRunResultMeta;
}

export interface SummaryBannerProps {
  filteredTags: string[];
  filteredAppSubscriptions: string[];
  actionMode: boolean;
  singleAppSubscriptionId?: string
}

export const SummaryBanner: FC<SummaryBannerProps> = ({
  filteredTags,
  filteredAppSubscriptions,
  actionMode,
  singleAppSubscriptionId
}) => {
  const { token } = theme.useToken();
  const carouselRef = useRef<CarouselRef>(null);
  const [carouselItems, setCarouselItems] = useState<CarouselItem[]>([]);
  const { lightMode } = useSettingsStore((state) => ({
    lightMode: state.lightMode,
  }));
  const [appSubscriptionHoverId, setAppSubscriptionHoverId] = useState("");

  const [jumpToModal, setJumpToModal] = useState<boolean>(false);
  const { activeSearchResultMeta, currentSearchRun, setActiveSearchResultMeta } = useSearchStore((state) => ({
    activeSearchResultMeta: state.activeSearchResultMeta,
    currentSearchRun: state.currentSearchRun,
    setActiveSearchResultMeta: state.setActiveSearchResultMeta,
  }));

  const {
    mspEnabled,
    tags
  } = useOrganizationStore((state) => ({
    mspEnabled: state.mspEnabled,
    tags: state.tags
  }));

  const StyledCarousel = styled(Carousel)`
    .slick-dots li button {
      border-radius: 0;
      width: 20px;
      height: 3px;
      margin-top: 3px;
      transition: background-color 0.3s ease, opacity 0.3s ease; /* Smooth transition */
    }
    .slick-dots li button:hover {
      background-color: ${token.colorPrimaryHover};
      opacity: 1;  
    }
    .slick-dots li.slick-active button {
      background-color: ${token.colorPrimaryActive};
    }
    .slick-dots li:not(.slick-active) button {
      background-color: #ccc;
      opacity: 0.7;
    }
    .slick-next  {
      color: ${token.colorText};
    }
    .slick-prev  {
      color: ${token.colorText};
    }
  `;

  const goToSlide = (index: number) => {
    if (carouselRef.current) {
      carouselRef.current.goTo(index); // Access the goTo method
    }
  };


  const getVerticalLine = () => {
    return (
      <div style={{width: "1px", height: 20, backgroundColor: token.colorText}}></div>
    )
  }

  const getCarouselItem = (ci: CarouselItem, showFull: boolean) => {
    return (
      <Space
        direction={"horizontal"}
        size={8}
      >
        <Space size={5}>
          {showFull && <Text strong>App:</Text>}
          {showFull && <Image
            preview={false}
            draggable={false}
            width={"90px"}
            height={"20px"}
            src={getAppLogoUrl(ci.rm.appID, lightMode)}
            style={{ marginTop: "4px" }}
            />
          }
        </Space>
        {showFull && getVerticalLine()}
        <Space size={5} >
          <Text strong>Config: </Text>
          <Tag 
            color={getAvatarColor(`${ci.rm.appName}(${ci.rm.appSubscriptionName})`)} 
            onClick={() => actionMode == false && setJumpToModal(true)}
            style={{cursor: "pointer"}}
          >
            {ci.rm.appName}({ci.rm.appSubscriptionName})
          </Tag>
        </Space>
        {showFull && getVerticalLine()}
        <Space size={5}>
          {`${ci.rm.tenantID}_tag` && mspEnabled && <Text strong>Tag:</Text>}
          {`${ci.rm.tenantID}_tag` && mspEnabled &&
            <Tag color={getTagColor(`${ci.rm.tenantID}_tag`)}>
              {tags?.find((tag) => (tag.id == `${ci.rm.tenantID}_tag`))?.value}
            </Tag>
          }
        </Space>
        {`${ci.rm.tenantID}_tag` && mspEnabled && showFull && getVerticalLine()}
        <Space size={5}>
          {showFull && <Text strong>Count:</Text>}
          {showFull ? (
            ci.rm.resultStatus != SearchRunStatus.Completed ? (
              <Popover
                content={
                  `Search failed using app "${ci.rm.appName}" and configuration "${ci.rm.appSubscriptionName}". Check configuration details`
                }
              >
                <WarningOutlined style={{ color: token.colorWarning }} />
              </Popover>
            ) : (
              <Badge
                count={ci.rm.count}
                overflowCount={9999}
                showZero
                color={token.colorText}
                size="default"
              />
            )
          ):(null)
          }
        </Space>
      </Space>
    )
  }

  const getJumpToModal = () => {
    return (
      <AntdModal
        title={"Switch To Configuration"}
        width={800}
        open={true}
        onCancel={() => setJumpToModal(false)}
        footer={null}
      >
        <Flex gap="4px 4px" wrap="wrap" align={"left"}>
          {carouselItems.map((ci, index) => (
            <Card
              size={"small"}
              key={ci.rm.appSubscriptionID}
              onClick={(e) => { 
                setActiveSearchResultMeta({
                  index: index,
                  rm: ci.rm,
                }as ActiveSearchResultMeta); 
                goToSlide(index) }
              }
              style={{ cursor: "pointer" }}
              onMouseOver={() => setAppSubscriptionHoverId(ci.rm.appSubscriptionID)}
              onMouseOut={() => setAppSubscriptionHoverId("")}
              styles={{
                body: {
                  alignContent: "center",
                  justifyContent: "center",
                  borderRadius: token.borderRadius,
                  background: token.colorPrimaryBg
                }
              }}
            >
              {getCarouselItem(ci, false)}
            </Card>
          ))}
        </Flex>

      </AntdModal>

    );
  }

  const renderCarouselContainer = (childerns: any) => {
    return (
      <Card
        size={"small"}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          borderRadius: token.borderRadius,
          background: token.colorPrimaryBg,
        }}
        styles={{
          header: {
            background: token.colorPrimaryBg,
            marginBottom: "-30px",
            textAlign: "center"
          },
          body: {
            alignContent: "center",
            textAlign: "center",
            justifyContent: "center",
            borderRadius: token.borderRadius,
            background: token.colorPrimaryBg,
            minHeight: childerns ? "70px" : undefined
          }
        }}
      >
        {childerns}
      </Card>
    )
  }


  const syncCarouselItems = async (currentActiveSearchResultMeta : ActiveSearchResultMeta) => {
    const items: CarouselItem[] = [];
    if (!currentSearchRun.resultMetas) {
      setCarouselItems([]);
      return;
    }
    if(singleAppSubscriptionId) {
      for(let i=0; i< currentSearchRun.resultMetas.length; i++){
        if(currentSearchRun.resultMetas[i].appSubscriptionID == singleAppSubscriptionId){
          items.push({ rm: currentSearchRun.resultMetas[i] } as CarouselItem);
          currentActiveSearchResultMeta.index = 0;
          currentActiveSearchResultMeta.rm = currentSearchRun.resultMetas[i];
          break;
        }
      }
    }else {
      for (const rm of currentSearchRun.resultMetas
        .sort((a, b) => (a.appName > b.appName ? -1 : 1))
        .sort((a, b) => a.count > b.count ? -1 : 1)
        .filter(
          (rm) =>
            (filteredAppSubscriptions.length
              ? !filteredAppSubscriptions.includes(rm.appSubscriptionID)
              : true) &&
            (filteredTags.length
              ? !filteredTags.includes(`${rm.tenantID}_tag`)
              : true)
        )
      ) {
        items.push({ rm: rm } as CarouselItem);
      }
      if (items.length && currentActiveSearchResultMeta.index == -1) {
        currentActiveSearchResultMeta.index = 0 ;
        currentActiveSearchResultMeta.rm = items[0].rm;
      }
    }
    setCarouselItems(items);
    setActiveSearchResultMeta(currentActiveSearchResultMeta);
    goToSlide(currentActiveSearchResultMeta.index);
  }

  useEffect(() => {
    syncCarouselItems(activeSearchResultMeta);
  }, [currentSearchRun]);

  useEffect(() => {
    if(!actionMode)
      syncCarouselItems({index: -1, rm: undefined} as ActiveSearchResultMeta);
  }, [filteredAppSubscriptions, filteredTags]);

  return (
    <div id="summary-banner-container">
      {jumpToModal && getJumpToModal()}
      {carouselItems.length ? (
        <StyledCarousel
          afterChange={(number) => { 
            setActiveSearchResultMeta({
              index: number,
              rm: carouselItems[number].rm,
            }as ActiveSearchResultMeta);  
          }}
          arrows={carouselItems.length != 0 && actionMode == false}
          dotPosition={"bottom"}
          dots={!actionMode}
          fade
          touchMove
          ref={carouselRef}
          initialSlide={activeSearchResultMeta.index == -1 ? undefined : activeSearchResultMeta.index}
        >
          {carouselItems.map((ci, index) => (
            renderCarouselContainer(getCarouselItem(ci, true))
          ))}
        </StyledCarousel>
      ) : (
        <></>
      )}
    </div>
  )
}