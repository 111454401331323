import { FC, useState, useEffect } from "react";
import {
  theme,
  Spin,
  Layout,
} from "antd";
import {
  SearchResultCardState,
  SearchResultCardType,
  SearchRunResultMeta,
} from "types";

import { useSearchStore } from "store";
import ResultPanel from "./ResultPanel";
import ActionPanel from "./ActionPanel";
import InsightPanel from "./InsightPanel";
import { VerticalCollapsedCard } from "components/VerticalCollapsedCard";
import { commonIcons, searchIcons, workflowIcons } from "assets/icons";

const { Sider, Content } = Layout;
export interface WorkspaceProps {
  searchId: string;
  resultMeta: SearchRunResultMeta;
  actionMode: boolean;
  searchRunActionId?: string
}

//width are in percentages
const verticalCardWidth = 5;
const actionCardWidth = 25;

export const Workspace: FC<WorkspaceProps> = ({
  searchId,
  resultMeta,
  actionMode,
  searchRunActionId
}) => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const [mainCardWidth, setMainCardWidth] = useState(100);

  const { 
    searchCardsState,
    recommendedActions,
    setSearchCardsState, 
    getSearchRunActionsBySearchRunId 
  } = useSearchStore((state) => ({
    searchCardsState: state.searchCardsState,
    recommendedActions: state.recommendedActions,
    setSearchCardsState: state.setSearchCardsState,
    getSearchRunActionsBySearchRunId: state.getSearchRunActionsBySearchRunId,
  }));

  const fetchActions = async () => {
    try {
      const searchRunActions = await getSearchRunActionsBySearchRunId(resultMeta.searchRunID);
      for (const searchRunAction of searchRunActions) {
        for (const rm of searchRunAction.resultMetas) {
          if (rm.searchAppSubscriptionID == resultMeta.appSubscriptionID) {
            if(!actionMode) {
              setSearchCardsState(SearchResultCardType.InsightsCard, SearchResultCardState.Collapsed);
            }
            return;
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchActions();
  }, [resultMeta]);

  useEffect(() => {
    let newMainCardWidth = 100;
    const actionState = searchCardsState.get(SearchResultCardType.ActionCard);
    if (actionState && actionState == SearchResultCardState.Expanded) {
      newMainCardWidth -= actionCardWidth;
    }
    if (actionState && actionState == SearchResultCardState.Collapsed) {
      newMainCardWidth -= verticalCardWidth;
    }
    const insightState = searchCardsState.get(SearchResultCardType.InsightsCard);
    if(insightState && insightState == SearchResultCardState.Collapsed){
      newMainCardWidth -= verticalCardWidth;
    }
    const rtState = searchCardsState.get(SearchResultCardType.ResultTable);
    if(rtState && rtState == SearchResultCardState.Collapsed){
      newMainCardWidth -= verticalCardWidth;
    }

    setMainCardWidth(newMainCardWidth);

  }, [searchCardsState]);

  useEffect(() => {
    if (resultMeta?.count && recommendedActions.length) {
      setSearchCardsState(SearchResultCardType.ActionCard, SearchResultCardState.Expanded);
    } else {
      setSearchCardsState(SearchResultCardType.ActionCard, SearchResultCardState.Hidden);
    }
  }, [recommendedActions]);
  return (
    <Spin spinning={loader}>
      <Layout style={{ maxHeight: `calc(100vh - 277px)` }}>
        <Content>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            {searchCardsState.get(SearchResultCardType.ResultTable) == SearchResultCardState.Expanded &&
              <div
                id="search-result-table-expanded"
                style={{
                  minWidth: `${mainCardWidth}%`,
                }}
              >
                <ResultPanel
                  searchId={searchId}
                  resultMeta={resultMeta}
                  maxHeight={"calc(100vh - 277px)"}
                />
              </div>
            }
            {searchCardsState.get(SearchResultCardType.ResultTable) == SearchResultCardState.Collapsed &&
              <div
                id="search-result-table-collapsed"
                style={{
                  width: "45px",
                }}
              >
                <VerticalCollapsedCard
                  title="Results Table"
                  maxHeight={"calc(100vh - 277px)"}
                  expandLeft={false}
                  icon={searchIcons.searchIcon}
                  onExpand={() => {
                    setSearchCardsState(SearchResultCardType.ResultTable, SearchResultCardState.Expanded);
                  }}
                />
              </div>
            }
            {searchCardsState.get(SearchResultCardType.InsightsCard) == SearchResultCardState.Expanded &&
              <div
                id="search-insight-panel-expanded"
                style={{
                  minWidth: `${mainCardWidth}%`,
                  marginLeft: token.marginXXS,
                }}
              >
                <InsightPanel
                  maxHeight={"calc(100vh - 277px)"}
                  searchAppSubscriptionId={resultMeta.appSubscriptionID}
                  searchRunActionId={searchRunActionId}
                />
              </div>
            }
            {searchCardsState.get(SearchResultCardType.InsightsCard) == SearchResultCardState.Collapsed && 
              <div
                id="search-insight-panel-collapsed"
                style={{
                  width: "45px",
                  marginLeft: token.marginXXS,
                }}
              >
                <VerticalCollapsedCard
                  title="Insights"
                  maxHeight={`calc(100vh - 269px)`}
                  expandLeft={true}
                  icon={commonIcons.InsightsIcon}
                  onExpand={() => {
                    setSearchCardsState(SearchResultCardType.InsightsCard, SearchResultCardState.Expanded);
                  }}
                />
              </div>
            }
            
            {searchCardsState.get(SearchResultCardType.ActionCard) == SearchResultCardState.Expanded &&
              <div
                id="search-action-panel"
                style={{
                  width: `${actionCardWidth}%`,
                  minWidth: `${actionCardWidth}%`,
                  marginLeft: token.marginXXS,
                }}
              >
                <ActionPanel
                  maxHeight={`calc(100vh - 258px)`}
                  searchId={searchId}
                  resultMeta={resultMeta}
                />
              </div>
            }
            {searchCardsState.get(SearchResultCardType.ActionCard) == SearchResultCardState.Collapsed &&
              <div
                id="search-action-panel-collapsed"
                style={{
                  width: "45px",
                }}
              >
                <VerticalCollapsedCard
                  title="Actions"
                  maxHeight={`calc(100vh - 269px)`}
                  expandLeft={true}
                  icon={workflowIcons.actionShortIcon}
                  onExpand={() => {
                    setSearchCardsState(SearchResultCardType.ActionCard, SearchResultCardState.Expanded);
                  }}
                />
              </div>
            }
          </div>
        </Content>

      </Layout>
    </Spin>
  );
};

export default Workspace;