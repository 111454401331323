import { FC, useEffect, useState, useRef } from "react";
import {
  Skeleton,
  Table,
  theme,
  Typography,
  Empty,
} from "antd";

import {
  SearchRunActionData,
  SearchRunResultMeta,
} from "types";

import {
  useEdgeGlobalStore,
  useSearchArtifactStore,
  useSearchStore,
  useSettingsStore,
} from "store";

import { getSearchResultsApi } from "api";
import { normalizeLanguageFromFileName } from "utility/search/normalize_language";
import { ColumnRender } from "./columnRender";

import './index.css';

const { Text } = Typography;

const PAGE_SIZE = 20;

export interface ResultPanelProps {
  searchId: string;
  resultMeta: SearchRunResultMeta;
  maxHeight: string,
}

export const ResultPanel: FC<ResultPanelProps> = ({
  searchId,
  resultMeta,
  maxHeight,
}) => {
  const { token } = theme.useToken();
  const { lightMode } = useSettingsStore((state) => ({
    lightMode: state.lightMode,
  }));
  const tblRef: Parameters<typeof Table>[0]['ref'] = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [allResultsSelected, setAllResultsSelected] = useState(false);
  const { artifacts, getArtifacts } = useSearchArtifactStore((state) => ({
    artifacts: state.artifacts,
    getArtifacts: state.getArtifacts,
  }));
  
  const { 
    searchResultsTableRows,
    searchResultsTableColumns,
    searchRunActionSelectedDatas,
    updateSelectedSearchResults, 
    clearSelectedSearchResults,
    setSearchResultsTableRows,
    setSearchResultsTableColumns,
  } = useSearchStore((state) => ({
    searchResultsTableRows : state.searchResultsTableRows,
    searchResultsTableColumns: state.searchResultsTableColumns,
    searchRunActionSelectedDatas: state.searchRunActionSelectedDatas,
    updateSelectedSearchResults: state.updateSelectedSearchResults,
    clearSelectedSearchResults: state.clearSelectedSearchResults,
    setSearchResultsTableRows: state.setSearchResultsTableRows,
    setSearchResultsTableColumns: state.setSearchResultsTableColumns,
  }));

  const getEdgeEndpoint = useEdgeGlobalStore((state) => state.getEdgeEndpoint);

  const resetSelectedSearchResults = () => {
    clearSelectedSearchResults(resultMeta.appSubscriptionID);
    const data = {
      searchRunID: resultMeta.searchRunID,
      isSelectAll: false,
      rowIndexes: [],
      areRowsSelected: false,
    } as SearchRunActionData;
    updateSelectedSearchResults(resultMeta.appSubscriptionID, data);
  }
  const fetchData = async (pageNumber: number, currentResults: any[], currentColumns: any[]) => {
    if (currentResults.length >= resultMeta.count || loading) return;
    try {
      setLoading(true);
      const edgeEndpoint = await getEdgeEndpoint(resultMeta.edgeID);
      const rows = await getSearchResultsApi(
        edgeEndpoint,
        resultMeta.tenantID,
        searchId,
        resultMeta.searchRunID,
        resultMeta.appSubscriptionID,
        resultMeta.artifactName,
        pageNumber,
        PAGE_SIZE,
        true
      );
      if (!rows.length) return;

      let artifact = artifacts.find((x) => x.name == resultMeta.artifactName);
      if (artifacts.length == 0) {
        const artifacts = await getArtifacts();
        artifact = artifacts.find((x) => x.name == resultMeta.artifactName);
      }

      const processedResponse = rows.map((r: any, index: number) => {
        if (artifact?.name == "Code") {
          let language = r["language"];
          const filename = r["filename"];
          if (!language && filename) {
            language = normalizeLanguageFromFileName(filename);
            if (language) {
              r["language"] = language;
            }
          }
        }
        return {
          ...r,
          key: (pageNumber - 1) * PAGE_SIZE + index,
          index: (pageNumber - 1) * PAGE_SIZE + index,
        };
      });

      if (!currentColumns.length) {
        const d = rows[0];
        const columns = [{
          title: "#",
          dataIndex: "index",
          key: "index",
          order: 0,
          width: "70px",
          fixed: 'left',
          render: (index: number) => (
            <Text>{index + 1}</Text>
          )
        }];

        for (const key in d) {
          let displayName = key;
          if (artifact) {
            const field = artifact.fields[key];
            if (field) {
              displayName = field.displayName;
              columns.push({
                title: displayName,
                dataIndex: "",
                key: "",
                order: field.order,
                width: "auto",
                fixed:'',
                render: (record: any) =>
                  <ColumnRender
                    value={record[key]}
                    textType={field.textType}
                    language={record["language"]}
                    highlight={record["searchterm"]}
                  ></ColumnRender>
              });
            }
          }
        }

        const sortedColumns: any[] = columns.sort(
          (column1, column2) => column1.order - column2.order
        );
        setSearchResultsTableColumns(sortedColumns);
      }
      setCurrentPage(pageNumber + 1);
      if(allResultsSelected == true) selectAllRows([...currentResults, ...processedResponse]);
      setSearchResultsTableRows([...currentResults, ...processedResponse]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  
  useEffect(() => {
    Object.keys(searchRunActionSelectedDatas).forEach ( (key) => {
      if(key != resultMeta.appSubscriptionID){
        clearSelectedSearchResults(key);
      }
    })
    
    if (resultMeta.count > 0) {
      const currentSelectedData = searchRunActionSelectedDatas[resultMeta.appSubscriptionID];
      if(currentSelectedData && currentSelectedData.areRowsSelected) {
        if(currentSelectedData.isSelectAll) {
          selectAllRows(searchResultsTableRows);
          setAllResultsSelected(true);
        }else{
          setSelectedRowKeys(currentSelectedData.rowIndexes);
          setAllResultsSelected(false);
        }
      }else {
        setSearchResultsTableRows([])
        setSearchResultsTableColumns([]);
        setCurrentPage(1);
        resetSelectedSearchResults();
        setSelectedRowKeys([]);
        setAllResultsSelected(false);
        fetchData(1, [], []);
        tblRef.current?.scrollTo({ index: 0 });
      }
    }
  }, [resultMeta, lightMode]);

  const handleTableScroll = ({ currentTarget }: any) => {
    const { scrollTop, scrollHeight, clientHeight } = currentTarget;
    // Check if near the bottom of the scrollable area
    if (scrollHeight - scrollTop <= clientHeight + 50 && !loading) {
      fetchData(currentPage, searchResultsTableRows, searchResultsTableColumns);
    }
  };

  const selectAllRows = (rows: any[]) => {
    const selectedList: number[] = [];
    rows.map((i: any, index: number) => {
      selectedList.push(index);
    });
    setSelectedRowKeys(selectedList);
  };

  const onRowsSelectChange = (newSelectedRowKeys: React.Key[]) => {
    if (newSelectedRowKeys.length == 0) {
      setSelectedRowKeys([]);
      resetSelectedSearchResults();
    } else {
      const newList = newSelectedRowKeys.map((i) => Number(i));
      const data = {
        searchRunID: resultMeta.searchRunID,
        isSelectAll: false,
        rowIndexes: newList,
        areRowsSelected: true,
      } as SearchRunActionData;
      setSelectedRowKeys(newList);
      updateSelectedSearchResults(resultMeta.appSubscriptionID, data);
    }
  };

  const onRowsSelectAll = (selected: boolean) => {
    if (!selected) {
      resetSelectedSearchResults();
      setSelectedRowKeys([]);
      setAllResultsSelected(false);
    } else {
      const data = {
        searchRunID: resultMeta.searchRunID,
        isSelectAll: true,
        rowIndexes: [],
        areRowsSelected: true,
      } as SearchRunActionData;
      updateSelectedSearchResults(resultMeta.appSubscriptionID, data);
      selectAllRows(searchResultsTableRows);
      setAllResultsSelected(true);
    }
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys,
    onChange: onRowsSelectChange,
    onSelectAll: onRowsSelectAll,
    getCheckboxProps: (record: any) => {
      return {
        disabled: false,
      };
    },
  };

  return (
    <div
      id="scrollable-table-container"
      style={{
        width: "100%",
        minWidth: "100%",
        maxHeight: maxHeight,
      }}
    >
      {resultMeta.count && searchResultsTableRows.length? (
        <div
          id="scrollable-table"
          style={{
            width: "100%",
            maxHeight: maxHeight,
          }}
        >  
          <Table
            rowSelection={rowSelection}
            ref={tblRef}
            rowKey="key"
            columns={searchResultsTableColumns}
            dataSource={searchResultsTableRows}
            pagination={false}
            scroll={{ scrollToFirstRowOnChange: false, x: "max-content", y: maxHeight }}
            onScroll={handleTableScroll}
            bordered
          />
        </div>
      ) : (
        <div
          id="spin-results-container"
          style={{
            width: "100%",
            maxHeight: maxHeight,
          }}
        >  
          {resultMeta.count == 0 ? (
            <Empty />
          ) : (
            <Skeleton active />
          )}
        </div>
      )}

    </div>

  );
};

export default ResultPanel;