import { FC, useState, useEffect } from "react";
import { Popover, Spin, Typography, Space, theme, Avatar, Tag } from "antd";
import { useOrganizationStore, useUserInfoStore, useWorkspaceItemStore } from "store";
import { EntitiesDisplay } from "components/EntitiesDisplay";
import { ColumnsType } from "antd/es/table";
import { SearchRun, SearchRunAction, SearchRunStatus, SearchRunStatusMap, SearchServiceCode, SearchServiceCodesMap, WorkflowRunStatusCode, WorkflowRunStatusCodeMap, WorkspaceTabType, dateTimeFormatOptions } from "types";
import { useNavigate } from "react-router-dom";
import { deleteSearchRunActionApi } from "api";
import { HLink } from "components/Link";
import { getTagColor } from "utility";
import { TableProps } from "antd/lib";
import { DataType } from "@textea/json-viewer";

const { Text, Link } = Typography;

const SearchActionTab: FC = () => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const { token } = theme.useToken();

  const { mspEnabled, context, tags } = useOrganizationStore((state) => ({
    mspEnabled: state.mspEnabled,
    context: state.context,
    tags: state.tags,
  }));

  const {
    workspaceItemsMap,
    getWorkspaceItems,
    actionRunsPage,
    setCurrentPage,
    actionRunsFilters,
    setWorkspaceFilters,
    actionRunsSorters,
    setWorkspaceSorters,
  } = useWorkspaceItemStore((state) => ({
    workspaceItemsMap: state.workspaceItemsMap,
    getWorkspaceItems: state.getWorkspaceItems,
    actionRunsPage: state.workspacePage[WorkspaceTabType.ActionRuns],
    setCurrentPage: state.setCurrentPage,
    actionRunsFilters: state.workspaceFilters[WorkspaceTabType.ActionRuns],
    setWorkspaceFilters: state.setWorkspaceFilters,
    actionRunsSorters: state.workspaceSorters[WorkspaceTabType.ActionRuns],
    setWorkspaceSorters: state.setWorkspaceSorters,
  }));

  const { userInfos, loadUserInfos } = useUserInfoStore((state) => ({
    userInfos: state.userInfos,
    loadUserInfos: state.loadUserInfos,
  }));

  const loadItems = async () => {
    try {
      setLoader(true);
      await getWorkspaceItems(WorkspaceTabType.ActionRuns, actionRunsPage?.number as number, actionRunsPage?.size as number);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const onPageChange = (pageNumber: number, pageSize: number) => {
    setLoader(true);
    setCurrentPage(WorkspaceTabType.ActionRuns, pageNumber, pageSize)
    getWorkspaceItems(WorkspaceTabType.ActionRuns, pageNumber, pageSize).then(() => {
      setLoader(false);
    });
  }

  useEffect(() => {
    loadItems();
  }, [actionRunsFilters, actionRunsSorters, context]);

  //Load user infos of workspace items
  useEffect(() => {
    const userIds = [] as string[];
    const items = workspaceItemsMap.get(WorkspaceTabType.ActionRuns);
    items && items?.map((workspaceItem: any) => {
      workspaceItem.userID && userIds.push(workspaceItem.userID);
    });
    userIds.length > 0 && loadUserInfos(userIds);
  }, [workspaceItemsMap?.get(WorkspaceTabType.ActionRuns)]);

  const renderStatus = (status: string): JSX.Element => {
    const statusCode = status as SearchRunStatus;
    switch (statusCode) {
      case SearchRunStatus.Running:
        return <Text type="warning">{SearchRunStatusMap.get(statusCode)}</Text>;
      case SearchRunStatus.Completed:
        return <Text type="success">{SearchRunStatusMap.get(statusCode)}</Text>;
      case SearchRunStatus.Failed:
      case SearchRunStatus.Canceled:
      case SearchRunStatus.Terminated:
      case SearchRunStatus.TimedOut:
        return <Text type="danger">{SearchRunStatusMap.get(statusCode)}</Text>;
      default:
        return <></>;
    }
  };

  const columns: ColumnsType<SearchRunAction> = [
    {
      title: "Name",
      dataIndex: "",
      render: (record) => {
        const name = `${record.name}(${record.referenceName})`;
        return (
          <Text>
            <Popover content={record.id}>
              <HLink onClick={() => navigate(`/search-run-actions/${record.id}`)} text={name} />
            </Popover>
          </Text>
        );
      },
    },
    {
      title: "Initiator",
      dataIndex: "serviceCode",
      key: "serviceCode",
      filterSearch: true,
      filters: Array.from(SearchServiceCodesMap, ([serviceCode, value]) => ({ text: value, value: serviceCode })),
      filteredValue: actionRunsFilters["serviceCode"],
      render: (serviceCode) => {
        return (
          <Text>{SearchServiceCodesMap.get(serviceCode) || SearchServiceCodesMap.get(SearchServiceCode.SearchService)}</Text>
        );
      },
    },
    {
      title: "Executed By",
      dataIndex: "userID",
      key: "userID",
      render: (userID: string) => (
        <Space size={token.marginXXS}>
          {userInfos[userID]?.logoUrl && (
            <Avatar src={userInfos[userID]?.logoUrl} />
          )}
          <Text>
            {userInfos[userID]?.firstName} {userInfos[userID]?.lastName}
          </Text>
        </Space>
      ),
    },
    {
      title: "Tag",
      dataIndex: "tagID",
      key: "tagID",
      hidden: !mspEnabled,
      filterSearch: true,
      filters: tags.map((tag) => ({ text: tag.value, value: tag.id.split("_")[0] })),
      filteredValue: actionRunsFilters["tagID"],
      render: (tagID: string) => {
        const tag = tags?.find((tag) => (tag.id == tagID));
        return tag ? <Tag color={getTagColor(tag.id)}>{tag.value}</Tag> : <></>
      },
    },
    {
      title: "Owner",
      dataIndex: "tenantID",
      key: "tenantID",
      hidden: !mspEnabled,
      filterSearch: true,
      filters: tags.map((tag) => ({ text: tag.value, value: tag.id.split("_")[0] })),
      filteredValue: actionRunsFilters["tenantID"],
      render: (tenantID: string) => {
        const tag = tags?.find((tag) => (tag.id == `${tenantID}_tag`));
        return tag ? <Tag color={getTagColor(tag.id)}>{tag.value}</Tag> : <></>
      },
    },
    {
      title: "Started On",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      sortDirections: ['descend', 'ascend', null],
      render: (createdAt: string) =>
        new Date(createdAt).toLocaleTimeString(
          undefined,
          dateTimeFormatOptions
        ),
    },
    {
      title: "Completed On",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: true,
      sortDirections: ['descend', 'ascend', null],
      render: (updatedAt: string) =>
        new Date(updatedAt).toLocaleTimeString(
          undefined,
          dateTimeFormatOptions
        ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filterSearch: true,
      filters: Array.from(SearchRunStatusMap, ([tag, value]) => ({ text: value, value: tag })),
      filteredValue: actionRunsFilters["status"],
      render: renderStatus,
    },
  ];

  const deleteAllowed = (record: SearchRun) => {
    //return record.status != "running";
    return true
  };

  const deleteItems = async (itemKeys: string[]) => {
    await Promise.all(
      itemKeys.map(async (itemKey) => {
        try {
          return await deleteSearchRunActionApi(itemKey);
        } catch (error) {
          console.error(
            "failed to delete search run action",
            itemKey,
            error
          );
        }
      })
    )
  }

  const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter) => {
    setWorkspaceSorters(WorkspaceTabType.ActionRuns, sorter);
    setWorkspaceFilters(WorkspaceTabType.ActionRuns, filters);
    setCurrentPage(WorkspaceTabType.ActionRuns);
  }

  return (
    <Spin spinning={loader}>
      <EntitiesDisplay
        header={"Action Executions"}
        dataSource={workspaceItemsMap.get(WorkspaceTabType.ActionRuns) as any[]}
        columns={columns}
        pageNumber={actionRunsPage?.number}
        pageSize={actionRunsPage?.size}
        total={actionRunsPage?.total as number}
        onChange={onChange}
        onPageChange={onPageChange}
        getItemProps={(record: SearchRunAction) => {
          return {
            disabled: `${record.tenantID}_tag` != context,
          };
        }}
        actions={
          [
            {
              key: "delete",
              label: "Delete",
              enable: (itemIds) => {
                if (itemIds && itemIds.length) {
                  return itemIds.every((id) => workspaceItemsMap?.get(WorkspaceTabType.ActionRuns)?.find((item) => item.id == id && deleteAllowed(item)))
                } else {
                  return false
                }
              },
              showWarn: true,
              onClick: (itemIds) => {
                deleteItems(itemIds as string[])
                  .then(() => loadItems())
                  .catch((error) => console.error("failed to delete workspace items", error));
              }
            }
          ]
        }
      />
    </Spin>
  );
};

export default SearchActionTab;
