import { SearchRun, SearchRunActionParameters } from "types";

export const enum SearchRunActionStatus {
  Unspecified = "unspecified",
  Running = "running",
  Completed = "completed",
  Failed = "failed",
  Cancelled = "canceled",
  Terminated = "terminated",
  TimedOut = "timed_out",
}

export interface SearchRunActionMetrics {
  timeTaken: string;
}

export interface RunSearchActionEdgeResult {
  status: SearchRunActionStatus;
  reason: string;
  updatedAt: string;
}

export interface SearchRunActionResultMeta {
  id: string;
  searchRunActionID: string;
  searchAppSubscriptionID: string;
  areRowsSelected: boolean;
  rowIndex: number;
  resultStatus: SearchRunActionStatus;
  resultStatusReason: string;
}

// SearchRunActionResultMetas 
export type SearchRunActionResultMetas = SearchRunActionResultMeta[];

export interface SearchRunAction {
  id: string;
  searchRunID: string;
  searchRuns: SearchRun;
  name: string;
  referenceName: string;
  description: string;
  actionID: string;
  actionName: string;
  parameters: SearchRunActionParameters;
  status: SearchRunActionStatus;
  resultStatus: SearchRunActionStatus;
  providerID: string;
  providerParameters: SearchRunActionParameters;
  appID: string;
  appName: string;
  appSubscriptionID: string;
  appSubscriptionName: string;
  edgeID: string;
  resultCount: number;
  resultMetas: SearchRunActionResultMetas;
  metrics: SearchRunActionMetrics;
  tagID: string;
  tenantID: string;
  userID: string;
  createdAt: string;
  updatedAt: string;
  
}
