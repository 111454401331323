import { FC, useEffect, useState } from "react";
import { 
  Spin, 
  Typography, 
  theme,
  Modal as AntModal, 
  Popover, 
  Descriptions, 
  Button,
  Space,
  Badge,
} from "antd";

import { useOrganizationStore } from "store";

import { EntitiesDisplay } from "components/EntitiesDisplay";
import { ColumnsType } from "antd/es/table";
import { TableProps } from "antd/lib";
import { DataType } from "@textea/json-viewer";
import { useUpdateStore } from "store/updater";
import { Update, UpdateStatusCode, UpdateStatusCodeMap } from "types/updater";
import { dateTimeFormatOptions } from "types";
import { HLink } from "components/Link";

const { Text } = Typography;

const Content: FC = () => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const [runNowLoader, setRunNowLoader] = useState(false);
  const [updatesLoader, setUpdatesLoader] = useState(false);
  const context = useOrganizationStore((state) => state.context);
  const [viewUpdates, setViewUpdates] = useState(false);

  const { 
    updates, 
    getUpdates,
    totalCount,
    clearUpdates,
    updatePage,
    setCurrentPage,
    updateFilters,
    setUpdateLogFilter,
    updateSorters,
    setUpdateLogSorter,
    getSettings,
    settings,
    getUpdate,
    currentUpdate,
    runUpdateNow,
  } = useUpdateStore((state) => ({
    updates: state.updates,
    getUpdates: state.getUpdates,
    totalCount: state.totalCount,
    clearUpdates: state.clearUpdates,
    updatePage: state.pageInfo,
    setCurrentPage: state.setCurrentPage,
    updateFilters: state.filter,
    setUpdateLogFilter: state.setFilter,
    updateSorters: state.sorter,
    setUpdateLogSorter: state.setSorter,
    getSettings: state.getSettings,
    settings: state.settings,
    getUpdate: state.getUpdate,
    currentUpdate: state.currentUpdate,
    runUpdateNow: state.runUpdateNow,
  }));

  const loadUpdateSettings = async () => {
    try {
      setLoader(true);
      await getSettings();
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const runUpdate = async () => {
    try {
      setRunNowLoader(true);
      await runUpdateNow();
    } catch (error) {
      console.log(error);
    } finally {
      setRunNowLoader(false);
    }
  };

  const loadUpdates = async () => {
    try {
      setUpdatesLoader(true);
      await getUpdates();
    } catch (error) {
      console.log(error);
    } finally {
      setUpdatesLoader(false);
    }
  };

  useEffect(() => {
    loadUpdateSettings();
    return () => {
      updates.length> 0 && clearUpdates();
    };
  }, []);

  useEffect(() => {
    loadUpdates();
  }, [updateFilters, updateSorters, updatePage]);

  const getUpdateStatus = (update: Update): JSX.Element => {
    switch (update.status) {
      case UpdateStatusCode.NotRunning:
        return <Badge status="default" text={<Text>{UpdateStatusCodeMap.get(update.status)}</Text>} />;
      case UpdateStatusCode.Running:
        return <Badge status="processing" text={<Text>{UpdateStatusCodeMap.get(update.status)}</Text>} />;
      case UpdateStatusCode.Completed:
        return <Badge status="success" text={<Text>{UpdateStatusCodeMap.get(update.status)}</Text>} />;
      case UpdateStatusCode.Failed:
      case UpdateStatusCode.Canceled:
      case UpdateStatusCode.Terminated:
      case UpdateStatusCode.TimedOut:
        return  <Popover
                  content={
                    <Descriptions 
                      title={"More Details"}
                      bordered
                      column={1}
                      colon={false}
                      style={{ width: 600 }}
                    >
                      <Descriptions.Item label="Reason">{update.reason}</Descriptions.Item>
                      <Descriptions.Item label="Error">{<Text copyable>{update.error}</Text>}</Descriptions.Item>
                    </Descriptions>
                  }
                >
                  <Badge status="error" text={<Text style={{ cursor: "pointer" }}>{UpdateStatusCodeMap.get(update.status)}</Text>} />
                </Popover>
      default:
        return <></>;
    }
  };

  const updateColumns: ColumnsType<Update> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name) => (
        <Text>{name}</Text>
      ),
    },
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
      sorter: true,
      sortDirections: ['descend', 'ascend', null],
      render: (name) => (
        <Text>{name}</Text>
      ),
    },
    {
      title: "Status",
      dataIndex: "",
      key: "status",
      filterSearch: true,
      filters: Array.from(UpdateStatusCodeMap, ([tag, value]) => ({ text: value, value: tag })),
      filteredValue: updateFilters["status"],
      render: (record) => getUpdateStatus(record),
    },
    {
      title: "Updated At",
      dataIndex: "endTime",
      key: "endTime",
      sorter: true,
      sortDirections: ['descend', 'ascend', null],
      render: (endTime) => (
        new Date(endTime).toLocaleTimeString(undefined, dateTimeFormatOptions)
      ),
    },
  ];


  const onChange: TableProps<DataType>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    setUpdateLogSorter(sorter);
    setUpdateLogFilter(filters);
    setCurrentPage();
  };

  return (
    <Spin spinning={loader}>
      <Descriptions 
        bordered
        column={1}
        colon={true}
      >
        <Descriptions.Item label="Version">{settings?.contentVersion ? settings.contentVersion : "N/A"}</Descriptions.Item>
        <Descriptions.Item label="Last Update At">{settings?.lastUpdatedAt ? new Date(settings.lastUpdatedAt).toLocaleTimeString(undefined, dateTimeFormatOptions) : "N/A"}</Descriptions.Item>
        <Descriptions.Item label="Update Now">
          <Space size={token.size}>
            <Button 
              type="primary" 
              loading={runNowLoader}
              onClick={runUpdate}
            >
              Run
            </Button>
            {currentUpdate 
              ?
                currentUpdate?.status == UpdateStatusCode.NotRunning 
                ?
                  <Text type="danger">{currentUpdate?.reason}</Text>
                :
                  getUpdateStatus(currentUpdate)
              :
                <></>
            }
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label="Update History">
          <HLink
            underline
            onClick={() => setViewUpdates(true)}
            text={"View"}
            alwaysLinkColor
          />
        </Descriptions.Item>
      </Descriptions>
      {viewUpdates &&
        <AntModal
          title={"Update History"}
          width={800}
          open={viewUpdates}
          loading={updatesLoader}  
          onCancel={() => setViewUpdates(false)}
          footer={[
            <Button key="ok" type="primary" onClick={() => setViewUpdates(false)}>
              OK
            </Button>
          ]}
          bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 250px)' }}
        >
          <EntitiesDisplay
            header="Update Logs"
            hideHeader={true}
            dataSource={updates}
            columns={updateColumns}
            pageNumber={updatePage.number}
            pageSize={updatePage.size}
            total={totalCount}
            onChange={onChange}
            onPageChange={(pageNumber: number, pageSize: number) =>
              setCurrentPage(pageNumber, pageSize)
            }
          />
        </AntModal>
      }
    </Spin>
  );
};

export default Content;
