import { AppConfigDto, getAppConfigApi } from "api/appconfig";
import { AppConfigStore } from "types/appconfig";
import { create } from "zustand"
import auth0 from "auth0-js";
import Keycloak from "keycloak-js";
export const useAppConfigStore = create<AppConfigStore>(
  (set, ) => ({           
    keycloakPasswordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12}/,
    intialized: false,
    baseUrl: null,
    apiBaseUrl : null,
    signupApiBaseUrl : null,
    auth0Domain : null,
    auth0ClientId : null,
    auth0Audience : null,
    auth0Connection : null,
    auth0RedirectUri : null,
    keycloakDomain : null,
    keycloakClientId : null,
    keycloakRealm : null,
    keycloakIssuer : null,
    keycloakRedirectUri : null,
    signinUri : null,
    ssoLoginUrl : null,
    oauth2RedirectUri : null,
    auth: null,
    auth0Issuer : null,
    isProduction : false,
    isSelfHosted : false,
    isCloud : false,
    organizationId : null,
    defaultEdgeId : null,
    sendEmailActionId : null,
    updateSettingsId : null,
    umgtBasePath : null,
    signupApiBasePath : null,
    tenantsApiPath : null,
    passwordResetApiPath : null,
    ssoUrlApiPath : null,
    usersApiPath : null,
    rolesApiPath : null,
    permissionSetsApiPath : null,
    permissionsApiPath : null,
    resourceApiPath : null,
    subscribersApiPath : null,
    providersApiPath : null,
    clientCredentialsApiPath : null,
    profileApiPath : null,
    userPreferencesApiPath : null,
    goalsApiPath : null,
    tagsApiPath : null,
    dashboardsApiPath : null,
    visibilityApiBasePath : null,
    activitiesApiPath : null,
    metricsApiPath : null,
    metricsDefinitionsApiPath : null,
    metricsLayoutsApiPath : null,
    visibilityPoliciesApiPath : null,
    settingsApiPath : null,
    appApiBasePath : null,
    appsApiPath : null,
    appEdgeApiPath : null,
    appRegionsApiPath : null,
    appLogoBasePath : null, 
    appSubscriptionsApiPath : null,
    edgeApiBasePath : null,
    edgesApiPath : null,
    edgeSubscriptionsApiPath : null,
    edgeRbacApiPath : null,
    searchApiBasePath : null,
    searchxRunApiPath : null,
    searchRunsApiPath : null,
    recentSearchesApiPath : null,
    popularSearchesApiPath : null,
    searchesApiPath : null,
    searchRunActionsApiPath : null,
    artifactsApiPath : null,
    artifactProvidersApiPath : null,
    searchPoliciesApiPath : null,
    workflowApiBasePath : null,
    triggersApiPath : null,
    actionsApiPath : null,
    operatorsApiPath : null,
    workflowsApiPath : null,
    workflowRunsApiPath : null,
    workflowFilesApiPath : null,
    triggerProvidersApiPath : null,
    actionProvidersApiPath : null,
    workflowPoliciesApiPath : null,
    approvalApiBasePath : null,
    approvalsApiPath : null,
    casemgmtApiBasePath : null,
    casesApiPath : null,
    caseActivitiesApiPath : null,
    caseNotesApiPath : null,
    caseAttachmentsApiPath : null,
    caseMgmtPoliciesApiPath : null,
    updaterApiBasePath : null,
    updateSettingsApiPath : null,
    updatesApiPath : null,
    contentApiBasePath : null,
    tenantHeaderKeyID : null,
    keycloak: null,
    issuer:  null,
    
    fetchAppConfig : async () => {
      const appconfigDto : AppConfigDto = await getAppConfigApi();
      set((state) => ({
        ...state,
        baseUrl: appconfigDto.baseUrl,
        auth0Domain : appconfigDto.auth0Domain,
        auth0ClientId : appconfigDto.auth0ClientId,
        auth0Audience : appconfigDto.auth0Audience,
        auth0Connection : appconfigDto.auth0Connection,
        auth0RedirectUri : appconfigDto.auth0RedirectUri,
        keycloakDomain : appconfigDto.keycloakDomain,
        keycloakClientId : appconfigDto.keycloakClientId,
        keycloakRealm : appconfigDto.keycloakRealm,
        keycloakIssuer : appconfigDto.keycloakDomain? `${appconfigDto.keycloakDomain}/realms/${appconfigDto.keycloakRealm}` : null,
        keycloakRedirectUri : appconfigDto.keycloakRedirectUri,
        signinUri : appconfigDto.signinUri,
        apiBaseUrl : appconfigDto.apiBaseUrl,
        signupApiBaseUrl : appconfigDto.signupApiBaseUrl,
        ssoLoginUrl : appconfigDto.ssoLoginUrl,
        oauth2RedirectUri : appconfigDto.oauth2RedirectUri,
        auth: new auth0.WebAuth({ domain: appconfigDto.auth0Domain, clientID: appconfigDto.auth0ClientId, audience: appconfigDto.auth0Audience, redirectUri: appconfigDto.auth0RedirectUri , scope: 'openid profile email'}),           
        auth0Issuer : `https://${appconfigDto.auth0Domain}/`,
        isProduction : appconfigDto.environment === 'production',
        isSelfHosted : appconfigDto.deployment === 'onprem',
        isCloud : appconfigDto.deployment === 'cloud',
        organizationId : "bda37b9d-3403-442a-9c10-2c52796a5d45",
        defaultEdgeId : "264b49df-9842-40ff-800d-8be069100903",
        sendEmailActionId : "3c960ec6-4146-469e-b53e-285b4cda0d49",
        updateSettingsId : "4cfb4b19-2ee6-4baa-bfb7-4b67d1a6bedd",
        umgtBasePath : appconfigDto.apiBaseUrl,
        signupApiBasePath : `${appconfigDto.signupApiBaseUrl}/signup/api/v1`,
        tenantsApiPath : `${appconfigDto.signupApiBaseUrl}/signup/api/v1/tenants`,
        passwordResetApiPath : `${appconfigDto.signupApiBaseUrl}/signup/api/v1/resetpassword`,
        ssoUrlApiPath : `${appconfigDto.signupApiBaseUrl}/signup/api/v1/sso-url`,
        usersApiPath : `${appconfigDto.apiBaseUrl}/umgt/api/v1/users`,
        rolesApiPath : `${appconfigDto.apiBaseUrl}/umgt/api/v1/roles`,
        permissionSetsApiPath : `${appconfigDto.apiBaseUrl}/umgt/api/v1/permissionsets`,
        permissionsApiPath : `${appconfigDto.apiBaseUrl}/umgt/api/v1/permissions`,
        resourceApiPath : `${appconfigDto.apiBaseUrl}/umgt/api/v1/resources`,
        subscribersApiPath : `${appconfigDto.apiBaseUrl}/umgt/api/v1/msp/subscriptions`,
        providersApiPath : `${appconfigDto.apiBaseUrl}/umgt/api/v1/msp/providers`,
        clientCredentialsApiPath : `${appconfigDto.apiBaseUrl}/umgt/api/v1/client-credentials`,
        profileApiPath : `${appconfigDto.apiBaseUrl}/umgt/api/v1/profile`,
        userPreferencesApiPath : `${appconfigDto.apiBaseUrl}/umgt/api/v1/preferences`,
        goalsApiPath : `${appconfigDto.apiBaseUrl}/goals/api/v1/goals`,
        tagsApiPath : `${appconfigDto.apiBaseUrl}/umgt/api/v1/tags`,
        dashboardsApiPath : `${appconfigDto.apiBaseUrl}/dashboards/api/v1/dashboards`,
        visibilityApiBasePath : `${appconfigDto.apiBaseUrl}/visibility/api/v1`,
        activitiesApiPath : `${appconfigDto.apiBaseUrl}/visibility/api/v1/activities`,
        metricsApiPath : `${appconfigDto.apiBaseUrl}/visibility/api/v1/metrics`,
        metricsDefinitionsApiPath : `${appconfigDto.apiBaseUrl}/visibility/api/v1/metric-definitions`,
        metricsLayoutsApiPath : `${appconfigDto.apiBaseUrl}/visibility/api/v1/metric-layouts`,
        visibilityPoliciesApiPath : `${appconfigDto.apiBaseUrl}/visibility/api/v1/policies`,
        settingsApiPath : `${appconfigDto.apiBaseUrl}/settings/api/v1`,
        appApiBasePath : `${appconfigDto.apiBaseUrl}/app/api/v1`,
        appsApiPath : `${appconfigDto.apiBaseUrl}/app/api/v1/apps`,
        appLogoBasePath : `${appconfigDto.baseUrl}/apps/media/assets`,
        appEdgeApiPath : `${appconfigDto.apiBaseUrl}/app/api/v1/app-edge/recommend`,
        appRegionsApiPath : `${appconfigDto.apiBaseUrl}/app/api/v1/app-edge/regions`,
        appSubscriptionsApiPath : `${appconfigDto.apiBaseUrl}/app/api/v1/app-subscriptions`,
        edgeApiBasePath : `${appconfigDto.apiBaseUrl}/edge/api/v1`,
        edgesApiPath : `${appconfigDto.apiBaseUrl}/edge/api/v1/edges`,
        edgeSubscriptionsApiPath : `${appconfigDto.apiBaseUrl}/edge/api/v1/edge-subscriptions`,
        edgeRbacApiPath : `${appconfigDto.apiBaseUrl}/edge/api/v1/edge-rbac`,
        searchApiBasePath : `${appconfigDto.apiBaseUrl}/search/api/v1`,
        searchxRunApiPath : `${appconfigDto.apiBaseUrl}/search/api/v1/search-x/run`,
        searchRunsApiPath : `${appconfigDto.apiBaseUrl}/search/api/v1/search-runs`,
        recentSearchesApiPath : `${appconfigDto.apiBaseUrl}/search/api/v1/recent-searches`,
        popularSearchesApiPath : `${appconfigDto.apiBaseUrl}/search/api/v1/popular-searches`,
        searchesApiPath : `${appconfigDto.apiBaseUrl}/search/api/v1/searches`,
        searchRunActionsApiPath : `${appconfigDto.apiBaseUrl}/search/api/v1/search-run-actions`,
        artifactsApiPath : `${appconfigDto.apiBaseUrl}/search/api/v1/artifacts`,
        artifactProvidersApiPath : `${appconfigDto.apiBaseUrl}/search/api/v1/artifact-providers`,
        searchPoliciesApiPath : `${appconfigDto.apiBaseUrl}/search/api/v1/policies`,
        workflowApiBasePath : `${appconfigDto.apiBaseUrl}/workflow/api/v1`,
        triggersApiPath : `${appconfigDto.apiBaseUrl}/workflow/api/v1/triggers`,
        actionsApiPath : `${appconfigDto.apiBaseUrl}/workflow/api/v1/actions`,
        operatorsApiPath : `${appconfigDto.apiBaseUrl}/workflow/api/v1/operators`,
        workflowsApiPath : `${appconfigDto.apiBaseUrl}/workflow/api/v1/workflows`,
        workflowRunsApiPath : `${appconfigDto.apiBaseUrl}/workflow/api/v1/workflow-runs`,
        workflowFilesApiPath : `${appconfigDto.apiBaseUrl}/workflow/api/v1/workflow-files`,
        triggerProvidersApiPath : `${appconfigDto.apiBaseUrl}/workflow/api/v1/trigger-providers`,
        actionProvidersApiPath : `${appconfigDto.apiBaseUrl}/workflow/api/v1/action-providers`,
        workflowPoliciesApiPath : `${appconfigDto.apiBaseUrl}/workflow/api/v1/policies`,
        approvalApiBasePath : `${appconfigDto.apiBaseUrl}/approval/api/v1`,
        approvalsApiPath : `${appconfigDto.apiBaseUrl}/approval/api/v1/approvals`,
        casemgmtApiBasePath : `${appconfigDto.apiBaseUrl}/casemgmt/api/v1`,
        casesApiPath : `${appconfigDto.apiBaseUrl}/casemgmt/api/v1/cases`,
        caseActivitiesApiPath : `${appconfigDto.apiBaseUrl}/casemgmt/api/v1/case-activities`,
        caseNotesApiPath : `${appconfigDto.apiBaseUrl}/casemgmt/api/v1/case-notes`,
        caseAttachmentsApiPath : `${appconfigDto.apiBaseUrl}/casemgmt/api/v1/case-attachments`,
        caseMgmtPoliciesApiPath : `${appconfigDto.apiBaseUrl}/casemgmt/api/v1/policies`,
        updaterApiBasePath : `${appconfigDto.apiBaseUrl}/updater/api/v1`,
        updateSettingsApiPath : `${appconfigDto.apiBaseUrl}/updater/api/v1/settings`,
        updatesApiPath : `${appconfigDto.apiBaseUrl}/updater/api/v1/updates`,
        contentApiBasePath : `${appconfigDto.apiBaseUrl}/umgt/api/v1`,
        tenantHeaderKeyID : "x-zstreams-tenant-id",
        keycloak: appconfigDto.keycloakDomain? new Keycloak({ url: appconfigDto.keycloakDomain, clientId: appconfigDto.keycloakClientId, realm: appconfigDto.keycloakRealm }) : undefined,
        intialized: true,
        issuer : appconfigDto.deployment === 'onprem' ? `${appconfigDto.keycloakDomain}/realms/${appconfigDto.keycloakRealm}` : `https://${appconfigDto.auth0Domain}/`
      }));
    }
  })
);


