import { SearchMetaData, AppInfos,IODataValueMap, SearchRunAction } from "types";

export const enum SearchRunStatus {
  Unspecified = "unspecified",
  Running = "running",
  Completed = "completed",
  Failed = "failed",
  Canceled = "canceled",
  Terminated = "terminated",
  TimedOut = "timed_out",
}

export const SearchRunStatusMap: Map<SearchRunStatus, string>  =  new Map<SearchRunStatus, string> (
  [
    [SearchRunStatus.Unspecified, "Unspecified"],
    [SearchRunStatus.Running, "Running"], 
    [SearchRunStatus.Completed, "Completed"],
    [SearchRunStatus.Failed, "Failed"], 
    [SearchRunStatus.Canceled, "Canceled"], 
    [SearchRunStatus.Terminated, "Terminated"],
    [SearchRunStatus.TimedOut, "Timed Out"],
  ]
)

export enum SearchRunActionType {
  Cancel = "cancel",
  RunActions = "run-actions"
}

export interface SearchRunResultMeta {
  id: string;
  searchRunID: string;
  appID: string;
  appName: string;
  appSubscriptionID: string;
  appSubscriptionName: string;
  artifactName: string;
  count: number;
  resultStatus: SearchRunStatus;
  edgeID: string;
  tenantID: string
}

export type SearchRunResultMetas = SearchRunResultMeta[];

export interface SearchRunActionData  {
  searchRunID: string;
  isSelectAll: boolean;
  rowIndexes: number[];
  areRowsSelected: boolean;
}
export type SearchRunActionDatas = Record<string, SearchRunActionData>;

export type SearchRunActionParameters = IODataValueMap

export interface SearchRunActionInfo  {
  name: string;
  referenceName: string;
  description: string;
  actionID: string;
  parameters: SearchRunActionParameters;
  providerID: string; 
  providerParameters: SearchRunActionParameters;
  appID: string;
  appSubscriptionID: string;
}

export interface SearchActionInfo {
  id: string;
  name: string;
  displayName: string;
  description: string;
  appInfos: AppInfos;
  active: boolean;
}
export type SearchActionInfos = SearchActionInfo[];
export interface SearchRunMetrics {
  tenantsCount: number;
  appsCount: number;
  timeTaken: string;
}


export interface SearchRun {
  id: string;
  searchID: string;
  searchMetaData: SearchMetaData;
  actionInfo: SearchRunActionInfo;
  actionDatas: SearchRunActionDatas;
  status: SearchRunStatus;
  resultStatus: SearchRunStatus;
  resultCount: number;
  resultMetas: SearchRunResultMetas;
  searchRunActions: SearchRunAction[];
  metrics: SearchRunMetrics;
  serviceCode: string;
  createdAt: string;
  updatedAt: string;
  tagID: string;
  tenantID: string;
  userID: string;
}


export interface SearchRunRecommendedActions {
  actionInfos: SearchActionInfos;
}
