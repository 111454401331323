import { useEffect, useState, FC } from "react";
import {
  Space,
  theme,
  Typography,
  Card,
  Form,
  Spin,
} from "antd";
import pRetry, {FailedAttemptError} from 'p-retry';

import { ArrowRightOutlined } from "@ant-design/icons";

import {  SearchResultCardState, SearchResultCardType, SearchRunAction, SearchRunActionStatus, SearchRunResultMeta } from "types";
import { useSearchStore } from "store";
import { commonIcons } from "assets/icons";
import { SvgIcon } from "components/SvgIcon";
import ActionBanner from "./actionBanner";

const { Text } = Typography;

export interface InsightPanelProps {
  searchAppSubscriptionId: string;
  searchRunActionId?: string
  maxHeight: string;

}

const InsightPanel: FC<InsightPanelProps> = ({
  searchAppSubscriptionId,
  searchRunActionId,
  maxHeight,
}) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const [monitoringActions, setMonitoringActions] = useState<string[]>([]);
  const [loader, setLoader] = useState(false);
  const abortController = new AbortController();

  const {
    currentSearchRunActions,
    getSearchRunAction,
    setSearchCardsState,
  } = useSearchStore((state) => ({
    currentSearchRunActions: state.currentSearchRunActions,
    setSearchCardsState: state.setSearchCardsState,
    getSearchRunAction: state.getSearchRunAction,
  }));

  const syncSearchRunActionInBackground = async (searchRunActionId: string) => {
    const sr = await getSearchRunAction(searchRunActionId, false);
    if (sr.status == SearchRunActionStatus.Running) {
      throw new Error(sr.status)
    }
  };

  useEffect(() => {
    for (const sra of currentSearchRunActions) {
      if (
        sra.status == SearchRunActionStatus.Running &&
        !monitoringActions.find((x: string) => x == sra.id)
      ) {
        setMonitoringActions([...monitoringActions, sra.id]);
        pRetry(() => syncSearchRunActionInBackground(sra.id), {
          retries: 60,
          minTimeout: 5000,
          maxTimeout: 5000,
          signal: abortController.signal
        }).catch((e: FailedAttemptError) => {
          console.log("pretry sync search run status completed, exiting sync of status");
        })

      }
    }
  }, [currentSearchRunActions]);

  useEffect(() => {
    return () => {abortController.abort("exiting search run result panel page")} ;
  }, []);

  return (
    <Card
      size="small"
      title={
        <Space direction="horizontal">
          <SvgIcon component={commonIcons.InsightsIcon} style={{ marginTop: "5px" }} />
          <Text style={{ color: token.colorTextLightSolid }}>
            Insights
          </Text>
        </Space>
      }
      styles={{
        header: {
          backgroundColor: token.colorPrimary,
          textAlign: "left",
        },
        body: {
          backgroundColor: token.colorBgContainer,
          maxHeight: maxHeight,
          overflowX: "hidden",
        }
      }}
      extra={
        <ArrowRightOutlined size={20} 
          style={{ color: token.colorTextLightSolid }}
          onClick={() => {
            setSearchCardsState(SearchResultCardType.InsightsCard, SearchResultCardState.Collapsed);
          }}
        />
      }
    >
      <Spin spinning={loader}>
        <Space
          size={token.sizeXS}
          direction="vertical"
          style={{ display: "flex" }}
        >
          {currentSearchRunActions.map((action: SearchRunAction, index: number) => (
            <ActionBanner
              key={action.id}
              searchAppSubscriptionId={searchAppSubscriptionId}
              searchRunAction={action}
              expanded={searchRunActionId? action.id == searchRunActionId: !index}
            />
          )
          )}
        </Space>
      </Spin>

    </Card>
  )
}

export default InsightPanel;