import { DataType } from "@textea/json-viewer";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { 
    Artifact,
    Search,
    AppInfos,
    SearchRun, 
    SearchActionInfos,
    SearchRunAction, 
    SearchRunActionDatas, 
    ArtifactProviders,
    SearchRunActionInfo,
    PageInfo,
    SearchMetaData,
    SearchRunActionData,
    SearchRunResultMeta
} from "types";


export const searchArtifactStoreName = "search-artifacts-store"
export const searchArtifactProviderStoreName = "search-artifact-providers-store"
export const searchGlobalStoreName = "search-global-store";

export enum SearchTabType {
    SavedSearches = "savedSearches",
    RecentSearches = "recentSearches",
    PopularSearches = "popularSearches"
}

export enum SearchResultCardType {
    ResultTable = "resultTable",
    ActionCard = "actionCard",
    InsightsCard = "insightCard",
}

export enum SearchResultCardState {
    Hidden = "hidden",
    Expanded = "expanded",
    Collapsed = "collapsed"
}

export interface ActiveSearchResultMeta {
    index: number;
    rm: SearchRunResultMeta |undefined;
}

export interface SearchStore {
    searchLoader?: boolean;
    searchMetaData: SearchMetaData;
    searchRunActionSelectedDatas: SearchRunActionDatas;
    searchItemsMap: Map<SearchTabType, Search[]>;
    recommendedActions: SearchActionInfos;
    currentSearchRun: SearchRun;
    currentSearchRunTags: string[];
    currentSearchRunAction: SearchRunAction;
    currentSearchRunActions: SearchRunAction[];
    showDetailedResults: boolean;
    searchPage: Record<SearchTabType, PageInfo>,
    searchFilters: Record<SearchTabType, Record<string, FilterValue | null>>;
    searchSorters: Record<SearchTabType, SorterResult<DataType> | SorterResult<DataType>[]>;
    searchSearchTextMap: Record<SearchTabType, Map<string, string>>;
    activeTab: SearchTabType;
    activeSearchResultMeta : ActiveSearchResultMeta ;
    isAiQueryEngineEnabled: boolean;
    searchCardsState: Map<SearchResultCardType, SearchResultCardState>;
    searchResultsTableRows: any[];
    searchResultsTableColumns: any[];
      

    setSearchLoader: (val: boolean) => void;
    setSearchMetaData: (val: SearchMetaData) => void;
    setAiQueryEngineEnabled: (val : boolean) => void;
    
    setShowDetailedResults: (showDetailed: boolean) => void;
    getSearchItems: (searchTabType: SearchTabType, pageNumber: number, pageSize: number) => Promise<void>;

    runSearchX: () => Promise<string>;
    runSearch: () => Promise<string>;
    cancelSearchRun: (searchRunId: string) => Promise<void>;
    getSearchRun: (searchRunId?: string) => Promise<SearchRun>;
    getSearchRunRecommendedActions: (searchRunId?: string) => Promise<void>;

    createSearch: (search: Search, tagsOnly?: boolean) => Promise<Search>;
    updateSearch: (searchId: string, name: string, description: string, serviceCode: string) => Promise<void>;
    deleteSearch: (searchId: string) => Promise<void>;

    clearAllSelectedSearchResults: () => void;
    updateSelectedSearchResults: (appSubscriptionID: string, data: SearchRunActionData) => void;
    clearSelectedSearchResults: (appSubscriptionID: string) => void;
    
    runSearchActions: (searchRunId: string, searchRunActioninfo: SearchRunActionInfo, searchRunActionSelectedDatas: SearchRunActionDatas) => Promise<SearchRunAction>;
    getSearchRunAction: (searchRunActionId: string, isCurrent: boolean) => Promise<SearchRunAction>;
    getSearchRunActionsBySearchRunId: (searchRunId: string) => Promise<SearchRunAction[]>;
    cancelSearchRunAction: (searchRunActionId: string) => Promise<void>;
    clearCurrentSearchRunAction: () => void;
    clearCurrentSearchRunActions: () => void;
    clearRecommendedActions: () => void;
    resetSearchState: (skipMetaData: boolean) => void;
    setActiveTab: (tabType: SearchTabType) => void;
    setActiveSearchResultMeta: (activeSearchResultMeta: ActiveSearchResultMeta ) => void;
    setSearchCardsState:( cardType: SearchResultCardType, cardState: SearchResultCardState) => void;
    
    setCurrentPage: (tabType: SearchTabType, pageNumber?: number, pageSize?: number) => void;
    setSearchFilters: (tabType: SearchTabType, filters?: Record<string, FilterValue | null>) => void;
    setSearchSorters: (tabType: SearchTabType, sorters?: SorterResult<DataType> | SorterResult<DataType>[]) => void;
    setSearchSearchTextMap: (tabType: SearchTabType, searches: Map<string, string>) => void;
    
    setSearchResultsTableRows: (rows: any[]) => void;
    setSearchResultsTableColumns: (columns: any[]) => void;
}


export interface SearchArtifactStore {
    artifacts: Artifact[];
    getArtifacts: () => Promise<Artifact[]>;
}

export interface SearchArtifactProvidersStore {
    artifactProvidersMap: Map<string, ArtifactProviders>;
    getArtifactProviders: (artifactId: string) => Promise<void>;
}


export interface SearchGlobalStore {
    searches: Search[];
    getSearches: () => Promise<void>;
    createSearch: (search: Search) => Promise<Search>;
  }