import {
  Form,
  Input,
  InputNumber,
  Button,
  Row,
  Col,
  Switch,
  notification,
} from "antd";
import { useEffect, useState } from "react";
import { useSettingsStore } from "store/settings";
import { SmtpSettings } from "types";

export const SmtpInput: React.FC = () => {
  const [form] = Form.useForm<SmtpSettings>();
  const { setSmtpSettings, smtpSettings } = useSettingsStore();
  const [authEnabled, setAuthEnabled] = useState<boolean>(
    smtpSettings?.auth ?? false
  );
  const [isEditable, setIsEditable] = useState<boolean>(false);

  useEffect(() => {
    form.setFieldsValue({
      host: smtpSettings?.host ?? "",
      port: smtpSettings?.port ?? 587,
      user: smtpSettings?.user ?? "",
      from: smtpSettings?.from ?? "",
      fromDisplayName: smtpSettings?.fromDisplayName ?? "",
      replyTo: smtpSettings?.replyTo ?? "",
      replyToDisplayName: smtpSettings?.replyToDisplayName ?? "",
      envelopeFrom: smtpSettings?.envelopeFrom ?? "",
      ssl: smtpSettings?.ssl ?? false,
      startTls: smtpSettings?.startTls ?? false,
      auth: smtpSettings?.auth ?? false,
    });
  }, [smtpSettings, form]);

  const onFinish = async (values: SmtpSettings) => {
    // Remove password if it's empty
    try {
      const submittedValues = { ...values };
      if (!submittedValues.password) {
        delete submittedValues.password;
      }
      await setSmtpSettings(submittedValues);
      notification.success({
        message: "Success",
        description: "SMTP settings saved successfully",
        duration: 6,
      });
      setIsEditable(false);
    } catch (e) {
      console.error("Error setting SMTP settings", e);
      notification.error({
        message: "Error",
        description: "Failed to set SMTP settings",
        duration: 6,
      });
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={smtpSettings}
    >
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item label="Edit Mode">
            <Switch checked={isEditable} onChange={setIsEditable} />
          </Form.Item>
        </Col>
      </Row>

      {/* SMTP Host and Port */}
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label="SMTP Host"
            name="host"
            rules={[{ required: true, message: "Please input the SMTP host!" }]}
          >
            <Input placeholder="smtp.example.com" disabled={!isEditable} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="SMTP Port" name="port">
            <InputNumber
              min={1}
              max={65535}
              style={{ width: "100%" }}
              disabled={!isEditable}
            />
          </Form.Item>
        </Col>
      </Row>

      {/* SMTP Authentication */}
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item label="SMTP Auth" name="auth" valuePropName="checked">
            <Switch
              checked={authEnabled}
              onChange={(checked) => {
                setAuthEnabled(checked);
                form.setFieldsValue({ auth: checked });
              }}
              disabled={!isEditable}
            />
          </Form.Item>
        </Col>
      </Row>

      {authEnabled && (
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="SMTP User"
              name="user"
              rules={[
                {
                  required: authEnabled,
                  message: "Please input the SMTP user!",
                },
              ]}
            >
              <Input placeholder="user@example.com" disabled={!isEditable} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="SMTP Password" name="password">
              <Input.Password placeholder="Password" disabled={!isEditable} />
            </Form.Item>
          </Col>
        </Row>
      )}

      {/* From & Reply-To */}
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label="From Email"
            name="from"
            rules={[
              { required: true, message: "Please input the from email!" },
              { type: "email", message: "Please enter a valid email address!" },
            ]}
          >
            <Input placeholder="from@example.com" disabled={!isEditable} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="From Display Name" name="fromDisplayName">
            <Input placeholder="Display Name" disabled={!isEditable} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label="Reply-To Email"
            name="replyTo"
            rules={[
              { type: "email", message: "Please enter a valid email address!" },
            ]}
          >
            <Input placeholder="reply-to@example.com" disabled={!isEditable} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Reply-To Display Name" name="replyToDisplayName">
            <Input placeholder="Display Name" disabled={!isEditable} />
          </Form.Item>
        </Col>
      </Row>

      {/* Envelope From */}
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label="Envelope From"
            name="envelopeFrom"
            rules={[
              { type: "email", message: "Please enter a valid email address!" },
            ]}
          >
            <Input
              placeholder="envelope-from@example.com"
              disabled={!isEditable}
            />
          </Form.Item>
        </Col>
      </Row>

      {/* Security Settings */}
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item label="SSL" name="ssl" valuePropName="checked">
            <Switch disabled={!isEditable} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="StartTLS" name="startTls" valuePropName="checked">
            <Switch disabled={!isEditable} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={!isEditable}>
          Save Settings
        </Button>
      </Form.Item>
    </Form>
  );
};
