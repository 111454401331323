import { useEffect, useState, FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Button,
  Collapse,
  Space,
  Typography,
  theme,
  Spin,
  Skeleton,
} from "antd";
import { StopOutlined } from "@ant-design/icons";

import pRetry, { FailedAttemptError } from 'p-retry';

import { commonIcons } from "assets/icons";
import { SvgIcon } from "components/SvgIcon";
import { useOrganizationStore, useSearchStore } from "store";
import PageWrapper from "components/PageWrapper";
import { Workspace } from "./Workspace";
import { SearchResultCardState, SearchResultCardType, SearchRunActionStatus } from "types";
import {  getRunActionStatus, getSubHeadingMessageForSearchActionResults } from "./Utils";
import ControlButton from "components/ControlButton/controlButton";
import { notification } from 'utility/notification';
import { SummaryBanner } from "./summaryBanner";

const { Text, Title } = Typography;

export const SearchRunActionComponent = () => {
  const context = useOrganizationStore((state) => state.context);
  const { token } = theme.useToken();
  const [searchAppSubscriptionId, setSearchAppSubscriptionId] = useState<string | undefined>(undefined);

  const abortController = new AbortController();
  const { id: searchRunActionId } = useParams();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const {
    currentSearchRun,
    currentSearchRunAction,
    searchLoader,
    activeSearchResultMeta,
    getSearchRunAction,
    getSearchRun,
    cancelSearchRunAction,
    resetSearchState,
    setSearchCardsState,
  } = useSearchStore((state) => ({
    currentSearchRun: state.currentSearchRun,
    currentSearchRunAction: state.currentSearchRunAction,
    searchLoader: state.searchLoader,
    activeSearchResultMeta: state.activeSearchResultMeta,
    getSearchRunAction: state.getSearchRunAction,
    getSearchRun: state.getSearchRun,
    cancelSearchRunAction: state.cancelSearchRunAction,
    resetSearchState: state.resetSearchState,
    setSearchCardsState: state.setSearchCardsState,
  }));

  const syncSearchRunActionInBackground = async () => {
    if (searchRunActionId && searchRunActionId != "") {
      const sra = await getSearchRunAction(searchRunActionId, true);
      return sra.status;
    }
  }

  useEffect(() => {
    resetSearchState(false);
    return () => { abortController.abort("exiting search run action page"); resetSearchState(false) };
  }, []);

  useEffect(() => {
    const asyncUseEffect = async () => {
      if (searchRunActionId && searchRunActionId != "") {
        const sra = await getSearchRunAction(searchRunActionId, true);
        setSearchCardsState(SearchResultCardType.InsightsCard, SearchResultCardState.Expanded);
        
        await getSearchRun(sra.searchRunID);
        pRetry(() => syncSearchRunActionInBackground(), {
          retries: 60,
          minTimeout: 5000,
          maxTimeout: 5000,
          signal: abortController.signal
        }).catch((e: FailedAttemptError) => {
          console.log("pretry sync search run actionstatus completed, exiting sync of status");
        })
      }
    };
    asyncUseEffect();
  }, [searchRunActionId, context]);


  const cancelAction = async () => {
    try {
      setLoader(true);
      await cancelSearchRunAction(currentSearchRunAction.id);
      notification.success({
        message: "Action run cancellation is pipelined succesfully",
        duration: 6,
      });

    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while pipeling action cancellation",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  }


  const getHeaderControlButtons = () => {
    return (
      <Space size={1}>
        <ControlButton
          displayName={"Back"}
          svgComponent={
            <SvgIcon
              onClick={() => {
                navigate(-1);
              }}
              component={commonIcons.backIcon}
            />
          }
          hoverable={true}
        />
      </Space>
    );
  }

  const getHeaderActionButtons = () => {
    return (
      <Space size={8}>
        <Space size={8}>
          {(currentSearchRunAction.status == SearchRunActionStatus.Running) &&
            <Button
              type="primary"
              icon={<StopOutlined />}
              onClick={cancelAction}
            >
              Stop Action
            </Button>
          }
        </Space>
      </Space>
    );
  }

  const getTitle = () => {
    if(currentSearchRunAction && currentSearchRunAction.name &&  currentSearchRunAction.name != "") {
      return (
        <Space size={8}>
          <Text 
            style={{ fontSize: token.fontSizeHeading5 }} strong
          >
            {`${currentSearchRunAction.name}(${currentSearchRunAction.referenceName})`}
          </Text>
          {currentSearchRunAction && 
            <Text style={{ fontSize: "20", fontWeight: "normal", color: token.colorText }}>
              {getSubHeadingMessageForSearchActionResults(currentSearchRunAction)}
            </Text>
          }
          {currentSearchRunAction && getRunActionStatus(currentSearchRunAction)}
        </Space>
      );
    }
    return (
      <></>
    )
  };

  useEffect(() => {
    if(searchAppSubscriptionId) return;
    let foundSearchAppSubscriptionId = false
    if(currentSearchRunAction && currentSearchRunAction.resultMetas && currentSearchRun && currentSearchRun.resultMetas) {
      for (const rm of currentSearchRunAction.resultMetas) {
        for (const srm of currentSearchRun.resultMetas) {
          if (rm.searchAppSubscriptionID == srm.appSubscriptionID) {
            foundSearchAppSubscriptionId = true
            setSearchAppSubscriptionId(rm.searchAppSubscriptionID)
            break;
          }
        }
        if (foundSearchAppSubscriptionId) break;
      }
    }
  }, [currentSearchRun])

  return (
    <PageWrapper
      header={
        <Space style={{ display: "flex" }} size={0} direction="vertical">
          <div
            id="search-run-actions-header"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              padding: token.paddingXS,
              backgroundColor: token.colorBorderSecondary,
              alignItems: "center",
            }}
          >
            {getHeaderControlButtons()}
            {getTitle()}
            {getHeaderActionButtons()}
          </div>
          {searchAppSubscriptionId &&
            <div style={{ margin: token.marginXS, marginBottom: 0, }}>
              <SummaryBanner
                filteredAppSubscriptions={[]}
                filteredTags={[]}
                actionMode={true}
                singleAppSubscriptionId={searchAppSubscriptionId}
              />
            </div>
          }
        </Space>
      }
      content={
        <div
          id="search-run-action-workspace"
          style={{
            width: "100%",
            padding: token.paddingXS,
            marginTop: "-5px"
          }}
        >
          <Spin spinning={searchLoader || loader}>
            {activeSearchResultMeta && activeSearchResultMeta.rm  ? (
              <Workspace
                searchId={currentSearchRun.searchID}
                searchRunActionId={searchRunActionId}
                resultMeta={activeSearchResultMeta.rm }
                actionMode={true}
              />
            ) : (
              <Skeleton active />
            )}
          </Spin>

        </div>
      }
    ></PageWrapper>
  );
};
