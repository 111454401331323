import {
  acknowledgeProviderCancelApi,
  createSusbscriberApi,
  createTagApi,
  deleteTagApi,
  detachFromProviderApi,
  enrollWithProviderApi,
  getProviderStateApi,
  getProvidersApi,
  getSubscriberStateApi,
  getSubscribersApi,
  listTagsApi,
  updateProviderStateApi,
  updateSubscriberApi,
  updateTagApi
} from "api";
import { MspStore, SubscriberUpdateRequest, SubscriptionState, TagCreateRequest, TagUpdateRequest } from "types";
import { create } from "zustand";

export const useOrganizationStore = create<MspStore>(
  (set, get) => ({       
    treeDirection: "TB",
    context:null,
    contextRequest: null,
    tags: [],
    mspEnabled: false,
    external: false,
    subscribers: {},
    providers: {},
    currentProviderId: null,
    subscriptionState: null,

    /** Allows provider to change subscriber state to any state */
    updateSubscriberFields: async ( data : SubscriberUpdateRequest) => {
      await updateSubscriberApi(data);
      await get().getSubscribers();
    },
    /* These functions are called in provider context.  */
    setMspEnabled: async (active: boolean , external :boolean ) => {            
      /** If active is false, then external automatically goes to false */
      const s = await updateProviderStateApi(active, active ? external : false);
      set((state) => ({
        ...state,
        mspEnabled: s.data.active,
        external: s.data.external,
      }));
    },

    /** Provider marks subscriber state from cancel_initiated_by_subscriber to uninitialized */
    acknowledgeCancel: async (organizationId) => {
      await updateSubscriberApi({ state: SubscriptionState.uninitialized, subscriber: organizationId });
      await get().getSubscribers();
    },
    /** Provider marks subscriber state from initiated to approved */
    approveSubscriber: async (organizationId) => {
      await updateSubscriberApi({ state: SubscriptionState.approved, subscriber: organizationId });
      await get().getSubscribers();
    },
        /** Provider marks subscriber state from initiated to rejected */
    rejectSubscriber: async (organizationId) => {
      await updateSubscriberApi({ state: SubscriptionState.rejected, subscriber: organizationId });
      await get().getSubscribers();
    },
    /** Provider marks subscriber state from approved to cancel_initiated_by_provider  */
    detachSubscriber: async (organizationId) => {
      await updateSubscriberApi({subscriber: organizationId, state: SubscriptionState.cancelInitiatedByProvider});
      await get().getSubscribers();
    },   
    getSubscribers: async () => {
      const response = await getSubscribersApi();
      set((state) => ({
        ...state,
        subscribers: response.data?.tenants?.reduce((acc, curr) => ({ ...acc, [curr.tenantId]: curr }), {}) || state.subscribers,
      }));
    },
    createSubscriber: async (organization) => { 
      await createSusbscriberApi(organization);
      await get().getSubscribers();
    },
    getMspEnabled: async () => {
      const response = await getProviderStateApi();      
      set((state) => ({
        ...state,
        mspEnabled: response.data.active,
        external: response.data.external,
      }));
    },
    
    activateContext: (tagId) => {
      set((state) => ({
        ...state,
        context: tagId,
        contextRequest: null,
      }));
    },
      
    setContextRequest: (value) => {
      set((state) => ({
        ...state,
        contextRequest: value,
      }));
    },
    clearContextRequest: () => {
      set((state) => ({
        ...state,
        contextRequest: null,
      }));
    },
    /** Called in all contexts */
  
    getProviders : async () => {
      const response = await getProvidersApi();
      set((state) => ({
        ...state,
        providers: response.data?.providers?.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}) || state.providers,
      }));
    },
  

        /** Called in subscriber context */
    getSubscriptionState: async () => {
      const response = await getSubscriberStateApi();
      set((state) => ({
        ...state,
        currentProviderId: response.data?.msspId || null,
        subscriptionState: response.data?.enrolmentState || null,
      }));
    },

    /** State goes from uninitialized to initiated */
    attachToProvider: async (providerId) => {
      await enrollWithProviderApi(providerId);
      await get().getProviders();
      await get().getSubscriptionState();      
    },
        
    /** State goes from approved to cancel_initiated_by_subscriber */
    detachFromProvider: async () => {
      await detachFromProviderApi();
      await get().getProviders();
      await get().getSubscriptionState();
    },

    /**  State goes from cancel_initiated_by_provider to uninitialized */
    acknowledgeProviderCancel: async () => {
      await acknowledgeProviderCancelApi();
      await get().getSubscriptionState();
    },

    getTags: async () =>{
      const response = await listTagsApi();
      set((state) => ({
        ...state,
        tags: response.data.tags,
      }));
    },
    createTag: async (tag: TagCreateRequest) => {
      await createTagApi(tag);
      await get().getTags();
    },
    deleteTag: async (tagId: string) => {
      await deleteTagApi(tagId);
      await get().getTags();
    },

    updateTag: async (id: string, tag: TagUpdateRequest) => {
      await updateTagApi(id, tag);
      await get().getTags();
    }, 
    
    setTreeDirection: (direction: string) => {
      set((state) => ({
        ...state,
        treeDirection: direction,
      }));
    }    
  }));
  