import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Button, Form, Input, Spin, Typography } from "antd";
import { HLink } from "components/Link";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Auth0Error } from "auth0-js";
import { useAppConfigStore, useTokenStore } from "store";
import { v4 as uuidv4 } from "uuid";
import SignInSignUpWrapper from "../../components/SignInSignUpWrapper";
import styles from "./SignIn.module.scss";

import { notification } from "utility/notification";
import { LoginOptions } from "types/uam/authprovider";

const { Text } = Typography;

const SignIn: FC = () => {
  const [form] = Form.useForm();
  const [loader, setLoader] = useState<boolean>(false);
  const [sso, setSSO] = useState<boolean>(false);
  const navigate = useNavigate();
  const setState = useTokenStore((state) => state.setState);
  const authProvider = useTokenStore((state) => state.authProvider);
  const isProduction = useAppConfigStore((state) => state.isProduction);
  const isSelfHosted = useAppConfigStore((state) => state.isSelfHosted);
  const ssoLoginUrl = useAppConfigStore((state) => state.ssoLoginUrl);

  const onSubmit = (value: any) => {
    const doSSO = async () => {
      const res = `${ssoLoginUrl}?user=${btoa(value.email)}`;
      window.location.href = res;
    };
    if (sso) {
      doSSO();
    } else {
      console.log("value : ", value);
      setLoader(true);
      const authState = `${isProduction ? "prod" : "dev"}-${uuidv4()}`;
      const b64AuthState = btoa(authState);
      setState(b64AuthState);
      const options: LoginOptions = {
        username: value.email,
        password: value.password,
        responseType: "token id_token",
        state: b64AuthState,
        callback: (err, result) => {
          console.log(err);
          return;
        },
      };
      console.log("Invoking login with credentials");
      authProvider?.loginWithCredentials(options);
    }
  };

  useEffect(() => {
    const asyncLogin = async () => {
      try {
        setLoader(true);
        authProvider?.loginWithRedirect({
          callback: (err, result) => {
            console.log(err);
            return;
          },
        });
      } finally {
        setLoader(false);
      }
    };
    if (isSelfHosted) asyncLogin();
  }, [authProvider, isSelfHosted]);

  return (
    <Spin spinning={loader}>
      {!isSelfHosted && (
        <SignInSignUpWrapper>
          <div
            className="verticalAlignDiv"
            style={{ width: "75%", maxWidth: "400px" }}
          >
            <div className={styles.formWrap}>
              <div className={styles.FormDiv}>
                <Form
                  form={form}
                  name="signInForm"
                  initialValues={{ remember: true }}
                  autoComplete="off"
                  layout="vertical"
                >
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      { required: true, message: "Email is required!" },
                      () => ({
                        validator(rule, value) {
                          if (!value) {
                            return Promise.resolve();
                          }
                          if (!/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(value)) {
                            return Promise.reject("Enter a valid email");
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      className="authInput"
                      size="large"
                      prefix={<MailOutlined />}
                      placeholder="Enter your email address"
                    />
                  </Form.Item>
                  {!sso && (
                    <Form.Item
                      name="password"
                      label="Password"
                      rules={[
                        { required: true, message: "Password is required!" },
                      ]}
                    >
                      <Input.Password
                        className="authInput"
                        size="large"
                        prefix={<LockOutlined />}
                        placeholder="Enter your Password"
                      />
                    </Form.Item>
                  )}
                  <Form.Item>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        className="authbtn_100"
                        block={sso}
                        type="primary"
                        htmlType="submit"
                        onClick={() =>
                          form
                            .validateFields()
                            .then(onSubmit)
                            .catch((err) => {
                              console.log(err);
                            })
                        }
                      >
                        Sign In
                      </Button>
                      {!sso && (
                        <HLink
                          className="textLink"
                          onClick={() => navigate(`/forgot-password`)}
                          text={"Reset your password?"}
                        />
                      )}
                    </div>
                  </Form.Item>
                </Form>

                <div className={styles.or}>OR</div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                    alignItems: "center",
                  }}
                >
                  <Text>Using enterprise Single Sign On?</Text>
                  <Button type="link" onClick={() => setSSO(!sso)}>
                    {sso ? "Back to login options" : "Sign in with SSO"}
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                    alignItems: "center",
                  }}
                >
                  <Text>{"Dont't have an account ? "}</Text>
                  <Button type="link" onClick={() => navigate("/signup")}>
                    Sign Up here
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </SignInSignUpWrapper>
      )}
    </Spin>
  );
};

export default SignIn;
