import { useEffect, useState, FC } from "react";
import {
  Space,
  theme,
  Typography,
  Tooltip,
  Card,
  Flex,
} from "antd";
import SearchInput from "components/SearchInput";
import { useSearchStore, useSettingsStore } from "store";
import { SearchActionInfo } from "types";
import { SvgIcon } from "components/SvgIcon";
import { workflowIcons } from "assets/icons";

const { Text } = Typography;

export interface ActionListProps {
  onSelect: (actionInfo: SearchActionInfo) => void;
}

export const ActionList: FC<ActionListProps> = ({
  onSelect,
}) => {
  const { token } = theme.useToken();
  const [filter, setFilter] = useState("");
  const [actionHoverId, setActionHoverId] = useState("");

  const { recommendedActions, } = useSearchStore((state) => ({
    recommendedActions: state.recommendedActions,
  }));
  const { mode } = useSettingsStore((state) => ({
    mode: state.lightMode,
  }));

  return (
    <div style={{ display: "flex", flexDirection: "column", paddingLeft: token.paddingXXS }}>
      <SearchInput placeholder="search actions" onFilter={(e) => setFilter(e.target.value)} />
      {recommendedActions?.filter((x: SearchActionInfo) => x.displayName.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
        .sort((a, b) => ~~b.active - ~~a.active).map((i) => (
        <Space key={i.id} size={token.size} direction="vertical" style={{ display: "flex", marginTop: "3px" }}>
          <Card
            hoverable={i.active}
            style={{
              height: "40px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              background: (i.active ? (i.id == actionHoverId ? token.colorPrimaryBgHover : token.colorBgBase) : mode ? "#f5f5f5" : "#1f1f1f"),
            }}
            onClick={() => {
              i.active && onSelect(i);
            }}
            onMouseEnter={() => setActionHoverId(i.id)}
            onMouseLeave={() => setActionHoverId("")}
          >
            <Tooltip
              color={token.colorPrimaryHover}
              title={i.description}
              placement={"right"}
            >
              <Flex gap="small" justify={"flex-start"}>
                <SvgIcon 
                   size={token.size}
                   component={mode ? workflowIcons.actionSidebarLightIcon : workflowIcons.actionSidebarDarkIcon} 
                />
                <Text>
                  {i.displayName}
                </Text>
              </Flex>
            </Tooltip>
          </Card>
        </Space>
      ))}
    </div>
  )
}
