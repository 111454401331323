import {
  Workflow,
  WorkflowTriggerRef,
  WorkflowNodeType,
  WorkflowStepAction,
  WorkflowStepOperator,
  WorkflowTriggerProvider,
  WorkflowStepProvider,
  Trigger,
  TriggerType,
  OperatorType,
  Action,
  Operator,
  triggerBandColor,
  actionBandColor,
  operatorBandColor,
  WorkflowSteps,
  WorkflowStepType,
  WorkflowPendingConfigurations,
  WorkflowRun,
  WorkflowRunStatusCode,
  WorkflowTriggerProviders,
  WorkflowAppSubscriptionInfo,
  PolicyCategoryType,
  WorkflowStepProviders,
  WorkflowPendingAudits,
} from "types";

import { useSearchArtifactProvidersStore, useWorkflowStepProvidersStore, useWorkflowStepsStore } from "store";
//import { getAppLogoPath } from "api"
import { generateRandomString } from "utility";
import { companyIcons, workflowIcons } from "assets/icons";
import { DecisionStepLabel } from "components/HyprFlows/Editor/constants";
import { MenuProps } from "antd";
import { usePolicyStore } from "store/policy";
interface WorkflowStepProvidersInfo {
  providers: WorkflowStepProviders;
  isAuditRequired: boolean;
}
export function getTriggerDisplayName(workflow: Workflow) {
  if (!workflow.triggerRef) {
    return "";
  }
  const triggers = useWorkflowStepsStore.getState().triggers;
  const trigger = triggers.find((i: Trigger) => i.type == workflow.triggerRef.triggerType) as Trigger;
  if (!trigger) {
    return "";
  }

  switch (trigger.type) {
    case TriggerType.Manual:
    case TriggerType.Schedule:
      return trigger.displayName;
    case TriggerType.Custom: {
      if (workflow.triggerRef.triggerID) {
        const trigger = triggers.find((i: Trigger) => i.id == workflow.triggerRef.triggerID) as Trigger;
        if (trigger) {
          return trigger.displayName;
        }
      } else {
        return "Select trigger...";
      }
    }
  }
}

export function getTitle(nodeType: WorkflowNodeType, resourceId: string) {
  let title = "";
  let displayName: string | undefined;
  switch (nodeType) {
    case WorkflowNodeType.Trigger:
      title = "Trigger";
      displayName = getStepName(
        resourceId,
        WorkflowNodeType.Trigger
      ).displayName;
      break;
    case WorkflowNodeType.Action:
      title = "Action";
      displayName = getStepName(
        resourceId,
        WorkflowNodeType.Action
      ).displayName;
      break;
    case WorkflowNodeType.Operator:
      title = "Operator";
      displayName = getStepName(
        resourceId,
        WorkflowNodeType.Operator
      ).displayName;
      break;
  }
  if (displayName) {
    title = title + " - " + displayName;
  }
  return title;
}


export function createNewWorkflow(name: string, description: string, triggerType: string, isSubworkflow: boolean, edgeId?: string, labels?: string[]) {
  const workflowIn = ({
    name: name,
    description: description,
    isSubworkflow: isSubworkflow,
    steps: {}
  } as any) as Workflow;

  if (labels) {
    workflowIn.tags = labels;
  }

  const triggerRef: WorkflowTriggerRef = ({
    isConfigured: false,
    isAuditRequired: false,
    triggerType: triggerType,
    nextStep: ""
  } as any) as WorkflowTriggerRef;


  const triggers = useWorkflowStepsStore.getState().triggers;
  if (triggerRef.triggerType == TriggerType.Manual || triggerRef.triggerType == TriggerType.Schedule) {
    const trigger = triggers.find((i: Trigger) => i.type == triggerRef.triggerType) as Trigger;
    if (trigger) {
      triggerRef.triggerID = trigger.id;
    }
    triggerRef.isAuditRequired = false;

    //create provider with edge id selected by user
    const providers = [] as WorkflowTriggerProviders;
    const triggerProvider = {} as WorkflowStepProvider;
    triggerProvider.appSubscriptionInfos = [] as WorkflowAppSubscriptionInfo[];
    triggerProvider.appSubscriptionInfos.push({
      edgeID: edgeId,
    } as WorkflowAppSubscriptionInfo)
    providers.push(triggerProvider);
    triggerRef.providers = providers;

    if (triggerRef.triggerType == TriggerType.Manual) {
      triggerRef.isConfigured = true;
    }
  }
  workflowIn.triggerRef = triggerRef;
  workflowIn.isConfigured = false;
  return workflowIn;
}

export function getWorkflowPendingConfigurations(workflow: Workflow) {
  const configurations: WorkflowPendingConfigurations = [];

  if (workflow.triggerRef && !workflow.triggerRef.isConfigured) {
    configurations.push({ type: "trigger", id: "trigger", obj: workflow.triggerRef });
  }
  if (workflow.steps) {
    for (const [stepId, step] of Object.entries(workflow.steps)) {
      if (!step.isConfigured) {
        configurations.push({ type: step.type, id: stepId, obj: step });
      }
    }
  }
  return configurations;
}

export function getWorkflowPendingAudits(workflow: Workflow) {
  const audits: WorkflowPendingAudits = [];

  if (workflow.triggerRef && workflow.triggerRef.isAuditRequired) {
    audits.push({ type: "trigger", id: "trigger", obj: workflow.triggerRef });
  }
  if (workflow.steps) {
    for (const [stepId, step] of Object.entries(workflow.steps)) {
      if (step.isAuditRequired) {
        audits.push({ type: step.type, id: stepId, obj: step });
      }
    }
  }
  return audits;
}



export function getAllWorkflowStepsWithOrder(steps: WorkflowSteps, stepId: string, workflowSteps: [string, WorkflowStepType][]) {
  const step = Object.entries(steps).find(([id]) => id == stepId);
  if (step) {
    workflowSteps.push(step);
    if (step[1].type == WorkflowNodeType.Operator) {
      const decisionSteps = step[1].decisionSteps;
      if (Object.keys(decisionSteps).length) {
        decisionSteps[DecisionStepLabel.True] != "" && getAllWorkflowStepsWithOrder(steps, decisionSteps[DecisionStepLabel.True], workflowSteps);
        decisionSteps[DecisionStepLabel.False] != "" && getAllWorkflowStepsWithOrder(steps, decisionSteps[DecisionStepLabel.False], workflowSteps);
      }
      const parallelSteps = step[1].parallelSteps;
      if (Object.keys(parallelSteps).length) {
        parallelSteps.map((parallelStep) => {
          parallelStep != "" && getAllWorkflowStepsWithOrder(steps, parallelStep, workflowSteps);
        })
      }
      const loopStep = step[1].loopStep;
      loopStep != "" && getAllWorkflowStepsWithOrder(steps, loopStep, workflowSteps);
    }
    const nextStep = step[1].nextStep;
    nextStep != "" && getAllWorkflowStepsWithOrder(steps, nextStep, workflowSteps);
  }
}

/* Unused code
export function getNodeLogos(nodeType: WorkflowNodeType, id: string, workflow: Workflow) {
  const logos: string[] = [];
  const hypredgeLogo = companyIcons.hyprEdgeLogoWebSvg;
  switch (nodeType) {
    case WorkflowNodeType.Operator:
      logos.push(hypredgeLogo);
      break;
    case WorkflowNodeType.Trigger: {
      if (workflow.triggerRef) {
        switch (workflow.triggerRef.triggerType) {
          case TriggerType.Schedule, TriggerType.Manual:
            logos.push(hypredgeLogo);
            break;
          case TriggerType.Custom: {
            if (workflow.triggerRef.isConfigured) {
              Object.values(workflow.triggerRef.providers).map((provider: WorkflowTriggerProvider) => {
                logos.push(getAppLogoPath(provider.appID));
              });
            }
            break;
          }
        }
      }
      break;
    }
    case WorkflowNodeType.Action: {
      if (workflow.steps) {
        const step = workflow.steps[id] as WorkflowStepAction;
        if (step) {
          if (step.isConfigured) {
            logos.push(getAppLogoPath(step.provider.appID));
          }
        }
      }
      break;
    }
  }
  return logos;
}
*/

export function getStepLogo(nodeType: WorkflowNodeType, resourceType: string) {
  switch (nodeType) {
    case WorkflowNodeType.Trigger:
      return workflowIcons.triggerIcon;
    case WorkflowNodeType.Action:
      return workflowIcons.actionIcon;
    case WorkflowNodeType.Operator: {
      switch (resourceType) {
        case OperatorType.Condition:
          return workflowIcons.conditionOperator;
        case OperatorType.Wait:
          return workflowIcons.waitOperator;
        case OperatorType.Loop:
          return workflowIcons.loopOperator;
        case OperatorType.Search:
          return workflowIcons.searchOperator;
        case OperatorType.Break:
          return workflowIcons.breakOperator;
        case OperatorType.KV:
          return workflowIcons.kvOperator;
        case OperatorType.Parallel:
          return workflowIcons.parallelOperator;
        case OperatorType.Approval:
          return workflowIcons.approvalOperator;
        case OperatorType.Subworkflow:
          return workflowIcons.subworkflowOperator;
        case OperatorType.Http:
          return workflowIcons.httpOperator;
        case OperatorType.Db:
          return workflowIcons.dbOperator;
        case OperatorType.Ai:
          return workflowIcons.aiOperator;
        case OperatorType.Script:
          return workflowIcons.scriptOperator;
        default:
          return workflowIcons.conditionOperator;
      }
    }
    default:
      return workflowIcons.triggerIcon;
  }
}

export function getStepColor(nodeType: WorkflowNodeType) {

  switch (nodeType) {
    case WorkflowNodeType.Trigger:
      return triggerBandColor;
    case WorkflowNodeType.Action:
      return actionBandColor;
    case WorkflowNodeType.Operator:
      return operatorBandColor;
    default:
      return triggerBandColor;
  }
}

export function getStepIcon(nodeType?: WorkflowNodeType, resourceType?: string) {
  switch (nodeType) {
    case WorkflowNodeType.Trigger:
      return workflowIcons.triggerShortIcon;
    case WorkflowNodeType.Action:
      return workflowIcons.actionShortIcon;
    case WorkflowNodeType.Operator: {
      switch (resourceType) {
        case OperatorType.Condition:
          return workflowIcons.conditionShortOperatorIcon;
        case OperatorType.Wait:
          return workflowIcons.waitShortOperatorIcon;
        case OperatorType.Loop:
          return workflowIcons.loopShortOperatorIcon;
        case OperatorType.Search:
          return workflowIcons.searchShortOperatorIcon;
        case OperatorType.Break:
          return workflowIcons.breakShortOperatorIcon;
        case OperatorType.KV:
          return workflowIcons.kvShortOperatorIcon;
        case OperatorType.Parallel:
          return workflowIcons.parallelShortOperatorIcon;
        case OperatorType.Approval:
          return workflowIcons.approvalShortOperatorIcon;
        case OperatorType.Subworkflow:
          return workflowIcons.subworkflowShortOperatorIcon;
        case OperatorType.Http:
          return workflowIcons.httpShortOperatorIcon;
        case OperatorType.Db:
          return workflowIcons.dbShortOperatorIcon;
        case OperatorType.Ai:
          return workflowIcons.aiShortOperatorIcon;
        case OperatorType.Script:
          return workflowIcons.scriptShortOperatorIcon;
        default:
          return workflowIcons.conditionShortOperatorIcon;
      }
    }
    default:
      return workflowIcons.triggerIcon;
  }
}

export const generateReferenceName = (name: string, workflow: Workflow) => {
  if (name != "") {
    let referenceName = name.replaceAll(" ", "_").replaceAll("-", "_").toLowerCase()
    if (Object.values(workflow.steps).find((step) => step.name == name))
      referenceName += "_" + generateRandomString(3).toLowerCase();
    return referenceName
  } else {
    return "";
  }
}

export const validateReferenceName = (workflow: Workflow, stepId: string, referenceName: string): Promise<any> => {
  return (Object.entries(workflow.steps).find(([id, value]) => (id != stepId && value.referenceName === referenceName))
    ? Promise.reject(new Error('Reference name should be unique across workflow!'))
    : Promise.resolve());
};

export function getStepName(id: string, stepType: WorkflowNodeType) {
  switch (stepType) {
    case WorkflowNodeType.Trigger: {
      const trigger = useWorkflowStepsStore.getState().triggers.find((i: Trigger) => i.id == id) as Trigger;
      if (trigger)
        return { name: trigger.name, displayName: trigger.displayName };
      break;
    }
    case WorkflowNodeType.Action: {
      const action = useWorkflowStepsStore.getState().actions.find((i: Action) => i.id == id) as Action;
      if (action)
        return { name: action.name, displayName: action.displayName };
      break;
    }
    case WorkflowNodeType.Operator: {
      const operator = useWorkflowStepsStore.getState().operators.find((i: Operator) => i.id == id) as Operator;
      if (operator)
        return { name: operator.name, displayName: operator.displayName };
      break;
    }
    default:
      return { name: "", displayName: "" };
  }
  return { name: "", displayName: "" };
}

export function getStepType(id: string, stepType: WorkflowNodeType) {
  let stepActualType = "";
  switch (stepType) {
    case WorkflowNodeType.Trigger: {
      const trigger = useWorkflowStepsStore.getState().triggers.find((i: Trigger) => i.id == id) as Trigger;
      if (trigger)
        stepActualType = trigger.type;
      break;
    }
    case WorkflowNodeType.Action: {
      const action = useWorkflowStepsStore.getState().actions.find((i: Action) => i.id == id) as Action;
      if (action)
        stepActualType = action.type
      break;
    }
    case WorkflowNodeType.Operator: {
      const operator = useWorkflowStepsStore.getState().operators.find((i: Operator) => i.id == id) as Operator;
      if (operator)
        stepActualType = operator.type;
      break;
    }
    default:
      return "";
  }
  return stepActualType;
}

export const getDefaultActionProvidersInfo = async (actionId: string) => {
  if(useWorkflowStepProvidersStore.getState().actionProvidersMap.get(actionId) == undefined)
    await useWorkflowStepProvidersStore.getState().getActionProviders(actionId);
  
  const actionProviders = useWorkflowStepProvidersStore.getState().actionProvidersMap.get(actionId);
  if (actionProviders && actionProviders.providers && actionProviders.providers.length != 0) {
    //Policies
    const providerPolicies = usePolicyStore.getState().policies[PolicyCategoryType.ActionProviders];
    let policy = undefined;
    if (providerPolicies) {
      policy = providerPolicies.find((p) => p.name == actionId)
      if (policy) {
        for (const ap of actionProviders.providers) {
          for (const as of ap.appInfo.appSubscriptionsInfos) {
            if (as.id == policy.value.data) {
              return {
                providers: [{
                  providerID: ap.id, 
                  appID: ap.appID,
                  displayName: ap.appInfo.displayName,
                  appSubscriptionInfos: [{
                    name: as.name,
                    appSubscriptionID: as.id,
                    edgeID: as.edgeID,
                  } as WorkflowAppSubscriptionInfo]
                } as WorkflowStepProvider],
                isAuditRequired: false
              } as WorkflowStepProvidersInfo;
            }
          }
    
        }
      }
    }
    //Not able to find in policies now lets see default action provider, if everything is single
    const validProviders: WorkflowStepProvider []  = [];
    for (const ap of actionProviders.providers) {
      if ( ap.appInfo.appSubscriptionsInfos && ap.appInfo.appSubscriptionsInfos.length){
        validProviders.push({
            providerID: ap.id, 
            appID: ap.appID,
            displayName: ap.appInfo.displayName,
            appSubscriptionInfos: [{
              name: ap.appInfo.appSubscriptionsInfos[0].name,
              appSubscriptionID: ap.appInfo.appSubscriptionsInfos[0].id,
              edgeID: ap.appInfo.appSubscriptionsInfos[0].edgeID,
            } as WorkflowAppSubscriptionInfo]
          } as WorkflowStepProvider
        )
      }
    }
    return {
      providers: validProviders,
      isAuditRequired: validProviders.length > 1
    } as WorkflowStepProvidersInfo;
  }
}

export const getDefaultArtifactProvidersInfo = async (artifactId: string) => {
  if(useSearchArtifactProvidersStore.getState().artifactProvidersMap.get(artifactId) == undefined)
    await useSearchArtifactProvidersStore.getState().getArtifactProviders(artifactId);
  
  const artifactProviders = useSearchArtifactProvidersStore.getState().artifactProvidersMap.get(artifactId);
  if (artifactProviders && artifactProviders.providers && artifactProviders.providers.length != 0) {
    //Policies
    const providerPolicies = usePolicyStore.getState().policies[PolicyCategoryType.ArtifactProviders];
    let policy = undefined;
    if (providerPolicies) {
      policy = providerPolicies.find((p) => p.name == artifactId)
      if (policy) {
        const providers: WorkflowStepProviders = [];
        for (const ap of artifactProviders.providers) {
          for (const as of ap.appInfo.appSubscriptionsInfos) {
            if ((policy.value.data as string[]).find( (x) => x == as.id)) {
              providers.push({
                providerID: ap.id, 
                appID: ap.appID,
                displayName: ap.appInfo.displayName,
                appSubscriptionInfos: [{
                  name: as.name,
                  appSubscriptionID: as.id,
                  edgeID: as.edgeID,
                } as WorkflowAppSubscriptionInfo]
              } as WorkflowStepProvider)
            }
          }
        }
        return {
          providers: providers,
          isAuditRequired: false,
        } as WorkflowStepProvidersInfo;
      }
    }
    const validProviders: WorkflowStepProvider []  = [];
    for (const ap of artifactProviders.providers) {
      if ( ap.appInfo.appSubscriptionsInfos && ap.appInfo.appSubscriptionsInfos.length) {
        validProviders.push ({
          providerID: ap.id, 
          appID: ap.appID,
          displayName: ap.appInfo.displayName,
          appSubscriptionInfos: [{
            name: ap.appInfo.appSubscriptionsInfos[0].name,
            appSubscriptionID: ap.appInfo.appSubscriptionsInfos[0].id,
            edgeID: ap.appInfo.appSubscriptionsInfos[0].edgeID,
          } as WorkflowAppSubscriptionInfo]
        } as WorkflowStepProvider);
      }
    }
    return {
      providers: validProviders,
      isAuditRequired: false,
    } as WorkflowStepProvidersInfo;
  }
}

export const autoPopulateActionStepProvider = async (actionStep: WorkflowStepAction) => {
  if(actionStep.isConfigured) return false;

  if (actionStep.provider && actionStep.provider.providerID && actionStep.provider.providerID != "") {
    return false
  }
  const providersInfo = await getDefaultActionProvidersInfo(actionStep.actionID);
  if(providersInfo && providersInfo.providers.length) {
    actionStep.provider = providersInfo.providers[0];
    actionStep.isAuditRequired = providersInfo.isAuditRequired;
    return true;
  }
  return false;
}

export const autoPopulateOperatorStepProvider = async (opStep: WorkflowStepOperator) => {
  if(opStep.isConfigured) return false;

  switch(opStep.operatorType){
    case OperatorType.Parallel:
    case OperatorType.Wait: {
      return true;
    }
    case OperatorType.Ai: {
      let changeOccured = false;
      if(!opStep.parameters.action_id || opStep.parameters.action_id == "") {
        //auto
        const aiActions = useWorkflowStepsStore.getState().actions.filter ( (a: Action) => a.category == "ai");
        if(aiActions.length == 1) {
          opStep.parameters.action_id = aiActions[0].id;
        }
      }
      if(opStep.parameters.action_id && opStep.parameters.action_id != "") {
        if(!(opStep.actionProvider && opStep.actionProvider.providerID && opStep.actionProvider.providerID != "")){
          const providersInfo = await getDefaultActionProvidersInfo(opStep.parameters.action_id);
          if(providersInfo && providersInfo.providers.length) {
            opStep.actionProvider = providersInfo.providers[0];
            opStep.isAuditRequired = providersInfo.isAuditRequired;
            changeOccured = true
          }
        }
      }
      return changeOccured;
    }
    case OperatorType.Search: {
      let changeOccured = false;
      //deal with action  provider
      if(opStep.parameters.action_id && opStep.parameters.action_id != ""){
        if( !(opStep.actionProvider && opStep.actionProvider.providerID && opStep.actionProvider.providerID != "")) {
          const providersInfo = await getDefaultActionProvidersInfo(opStep.parameters.action_id);
          if(providersInfo && providersInfo.providers.length) {
            opStep.actionProvider = providersInfo.providers[0];
            opStep.isAuditRequired = providersInfo.isAuditRequired;
            changeOccured = true
          }
        }
      }

      //deal with artifact provider
      if(opStep.parameters.artifact_id && opStep.parameters.artifact_id != "") {
        if(!opStep.searchProviders || opStep.searchProviders.length == 0) {
          const providersInfo = await getDefaultArtifactProvidersInfo(opStep.parameters.artifact_id);
          if(providersInfo && providersInfo.providers.length) {
            opStep.searchProviders = providersInfo.providers;
            if(opStep.isAuditRequired == false) {
              opStep.isAuditRequired = providersInfo.isAuditRequired;
            }
            changeOccured = true;
          }
        }
      }
      return changeOccured
    }
    default: 
      return false;
  }
}

export const autoPopulateTriggerRefProvider = async (triggerRef: WorkflowTriggerRef) => {
  if(triggerRef.isConfigured) return false;

  if(triggerRef.triggerType != TriggerType.Custom) {
    return false
  }

  if(triggerRef.triggerID == "") {
    return false
  }
  
  //already have providers
  if (triggerRef.providers && triggerRef.providers.length) {
    return false
  }

  if(useWorkflowStepProvidersStore.getState().triggerProvidersMap.get(triggerRef.triggerID) == undefined)
    await useWorkflowStepProvidersStore.getState().getTriggerProviders(triggerRef.triggerID, triggerRef.triggerType);
  
  const triggerProviders = useWorkflowStepProvidersStore.getState().triggerProvidersMap.get(triggerRef.triggerID);
  if (triggerProviders && triggerProviders.providers && triggerProviders.providers.length != 0) {

    //Policies
    const providerPolicies = usePolicyStore.getState().policies[PolicyCategoryType.TriggerProviders];
    let policy = undefined;
    if (providerPolicies) {
      policy = providerPolicies.find((p) => p.name == triggerRef.triggerID);
      if (policy) {
        const providers: WorkflowStepProviders = [];
        for (const tp of triggerProviders.providers) {
          for (const as of tp.appInfo.appSubscriptionsInfos) {
            if ((policy.value.data as string[]).find( (x) => x == as.id)) {
              providers.push({
                providerID: tp.id, 
                appID: tp.appID,
                displayName: tp.appInfo.displayName,
                appSubscriptionInfos: [{
                  name: as.name,
                  appSubscriptionID: as.id,
                  edgeID: as.edgeID,
                } as WorkflowAppSubscriptionInfo]
              } as WorkflowStepProvider)
            }
          }
        }
        triggerRef.providers = providers;
        triggerRef.isAuditRequired = false;
        return true;
      }
    }

    //Not able to find in policies now lets see default action provider
    const validProviders: WorkflowTriggerProviders  = [];
    for (const tp of triggerProviders.providers) {
      if ( tp.appInfo.appSubscriptionsInfos && tp.appInfo.appSubscriptionsInfos.length) {
        const wtp: WorkflowTriggerProvider = {} as WorkflowTriggerProvider;
        wtp.providerID = tp.id;
        wtp.appID = tp.appID
        wtp.displayName = tp.appInfo.displayName;
        wtp.appSubscriptionInfos = [{
          name: tp.appInfo.appSubscriptionsInfos[0].name,
          appSubscriptionID: tp.appInfo.appSubscriptionsInfos[0].id,
          edgeID: tp.appInfo.appSubscriptionsInfos[0].edgeID,
        } as WorkflowAppSubscriptionInfo];
        validProviders.push(wtp);
      }
    }
    if(validProviders.length) {
      triggerRef.providers = [validProviders[0]];
      triggerRef.isAuditRequired = true;
      return true
    }
    
  }
  return false;

}

export const autoPopulateWorkflowStepsProviders = async (workflowIn: Workflow, triggerFill: boolean, stepsFill: string[]) => {
  try {
    let anyWorkflowChangeHappend = false;
    // if(!usePolicyStore.getState().policies.get(PolicyCategoryType.ActionProviders)?.length) {
    //   await usePolicyStore.getState().getPolicies(PolicyCategoryType.ActionProviders);
    // }
    anyWorkflowChangeHappend = await autoPopulateTriggerRefProvider(workflowIn.triggerRef);

    await Promise.all(
      Object.entries(workflowIn.steps).map(async ([stepId, step]) => {
        if(stepsFill.length && !stepsFill.find( (x) => x == stepId)){
          return
        }
        switch (step.type) {
          case WorkflowNodeType.Action: {
            const actionChangeHappened = await autoPopulateActionStepProvider(step as WorkflowStepAction)
            if(actionChangeHappened) {
              workflowIn.steps[stepId] = step;
              if(anyWorkflowChangeHappend == false) {
                anyWorkflowChangeHappend = true
              }
            }
            break;
          }
          case WorkflowNodeType.Operator: {
            const operatorChangeHappened = await autoPopulateOperatorStepProvider(step as WorkflowStepOperator)
            if(operatorChangeHappened) {
              workflowIn.steps[stepId] = step;
              if(anyWorkflowChangeHappend == false) {
                anyWorkflowChangeHappend = true
              }
            }
          }
        }
      })
    );
    return anyWorkflowChangeHappend;

  } catch (error: any) {
    console.error(error);
    return false;
  }
}