import { FC, useEffect, useState } from "react";
import {
  Collapse,
  theme,
  Typography,
  Space,
  Image,
  Badge,
  Tree,
  Tooltip,
  Tag,
  Popover,
} from "antd";
import { LinkOutlined, WarningOutlined } from "@ant-design/icons";

import { JsonViewer, defineDataType } from '@textea/json-viewer'

import {
  RunSearchActionEdgeResult,
  SearchRunAction,
  SearchRunActionResultMeta,
  SearchRunActionStatus,
  dateTimeFormatOptions,
} from "types";
import {  useEdgeGlobalStore, useSettingsStore } from "store";
import { getRunActionStatus } from "../../Utils";
import {
  getSearchActionOutputsApi,
  getSearchActionParametersApi,
} from "api";
import { getAvatarColor } from "utility";
import { SvgIcon } from "components/SvgIcon";
import { workflowIcons } from "assets/icons";
import { getAppLogoUrl } from "utility/app";
import { HLink } from "components/Link";


const { Text } = Typography;
const { Panel } = Collapse;

export interface ActionBannerProps {
  searchRunAction: SearchRunAction;
  searchAppSubscriptionId: string;
  expanded: boolean;
}

interface LogNode {
  key: string;
  title: any;
  isLeaf?: boolean;
  selectable?: boolean;
  icon?: any;
  children?: LogNode[];
}

const ActionBanner: FC<ActionBannerProps> = ({
  searchRunAction,
  searchAppSubscriptionId,
  expanded,
}) => {
  const { token } = theme.useToken();
  const { lightMode } = useSettingsStore((state) => ({
    lightMode: state.lightMode,
  }));
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [loadedKeys, setLoadedKeys] = useState<React.Key[]>([]);
  const [treeData, setTreeData] = useState<LogNode[]>([]);
  const getEdgeEndpoint = useEdgeGlobalStore((state) => state.getEdgeEndpoint);

  const getUpdatedTime = (updatedAt: string) => {
    return new Date(updatedAt).toLocaleTimeString(
      undefined,
      dateTimeFormatOptions
    );
  };

  const getIconByStatus = (status: SearchRunActionStatus) => {
    switch (status) {
      case SearchRunActionStatus.Failed:
        return <SvgIcon size={token.sizeSM} component={workflowIcons.logFailedIcon} hexColor="red" />;
      case SearchRunActionStatus.Completed:
        return <SvgIcon size={token.sizeSM} component={workflowIcons.logSuccessIcon} hexColor="green" />;
      default:
        return <SvgIcon size={token.sizeSM} component={workflowIcons.logBlankIcon} />;
    }
  }

  const getActionLogNodes = (resultMeta: SearchRunActionResultMeta): LogNode[] => {
    const logNodes = [] as LogNode[];
    logNodes.push({
      key: `${resultMeta.rowIndex}/${resultMeta.id}/result`,
      title: "Result",
      isLeaf: false,
      selectable: false,
      icon: getIconByStatus(resultMeta.resultStatus),
    });
    logNodes.push({
      key: `${resultMeta.rowIndex}/${resultMeta.id}/parameters`,
      title: "Parameters",
      isLeaf: false,
      selectable: false,
      icon: getIconByStatus(resultMeta.resultStatus),
    });
    logNodes.push({
      key: `${resultMeta.rowIndex}/${resultMeta.id}/outputs`,
      title: "Outputs",
      isLeaf: false,
      selectable: false,
      icon: getIconByStatus(resultMeta.resultStatus),
    });
    return logNodes;
  }

  const getAllKeys = (data: any[]) => {
    let keys: string[] = [];
    data.forEach(item => {
      keys.push(item.key);
      if (item.children) {
        keys = keys.concat(getAllKeys(item.children));
      }
    });
    return keys;
  };

  const loadLogNodes = () => {
    let logNodes = [] as LogNode[];
    const defaultExpandedKeys: string[] = [];
    if (searchRunAction.resultMetas.length == 1 && searchRunAction.resultMetas[0].areRowsSelected == false) {
      logNodes = ([...getActionLogNodes(searchRunAction.resultMetas[0])]);
      defaultExpandedKeys.push(...getAllKeys(logNodes));
    } else {
      let expandedForOneRow = false;
      for (const rm of searchRunAction.resultMetas
          .sort((a: SearchRunActionResultMeta, b: SearchRunActionResultMeta) => a.rowIndex > b.rowIndex ? 1 : -1))
      {
        if (rm.searchAppSubscriptionID != searchAppSubscriptionId) {
          continue;
        }
        const tilteRowIndex = rm.rowIndex + 1
        logNodes.push({
          key: `${rm.rowIndex}`,
          title: `Row ${tilteRowIndex}`,
          isLeaf: false,
          selectable: false,
          icon: getIconByStatus(rm.resultStatus),
          children: getActionLogNodes(rm)
        });
        if (expandedForOneRow == false) {
          defaultExpandedKeys.push(...getAllKeys(logNodes));
          expandedForOneRow = true;
        }
      }
    }
    setTreeData(logNodes);
    setExpandedKeys(defaultExpandedKeys);
  };

  const urlDataType = defineDataType({
    is: (value) => value instanceof URL,
    Component: (props) =>
      <Tooltip title="click here for details" color={token.colorPrimaryText}>
        <a href={props.value as string} target="_blank" rel="noopener noreferrer">
          ...
        </a>
      </Tooltip>
  })
  const onCopy = (path: any, value: any) => {
    let valueToCopy = value;
    if (typeof value === 'string' && value.startsWith('"') && value.endsWith('"')) {
      valueToCopy = value.slice(1, -1); // Remove the surrounding quotes
    }
    // Copy to clipboard
    navigator.clipboard.writeText(valueToCopy);
  };

  const getJsonViewTreeNodes = (key: string, data: any): LogNode[] => {
    const logNodes = [] as LogNode[];
    logNodes.push({
      key: key + "/values",
      title: <JsonViewer
        rootName={false}
        editable={false}
        displayDataTypes={false}
        displaySize={false}
        enableClipboard={true}
        defaultInspectDepth={1}
        collapseStringsAfterLength={100}
        value={data}
        theme={lightMode ? "light" : "dark"}
        highlightUpdates={true}
        sx={{ paddingLeft: 2 }}
        valueTypes={[urlDataType]}
        onCopy={onCopy}
      />,
      isLeaf: true,
      selectable: false,
    });
    return logNodes;
  };

  const getErrorTreeNodes = (key: string, msg: string): LogNode[] => {
    const logNodes = [] as LogNode[];
    logNodes.push({
      key: key + "/error",
      title: msg,
      isLeaf: true,
      selectable: false,
      icon: <WarningOutlined style={{ color: "orange" }} />
    });
    return logNodes;
  };

  const updateTreeData = (list: LogNode[], key: React.Key, children: LogNode[]): LogNode[] => 
    list.map((node) => {
      if (node.key === key) {
        return {
          ...node,
          children,
        };
      }
      if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key, children),
        };
      }
      return node;
    });
  

  const onLoadData = ({ key, children }: any) => {
    return new Promise<void>((resolve, reject) => {
      if (children) {
        resolve();
        return;
      }

      const s = key.split("/");
      if (s.length < 3) {
        resolve();
        return;
      }
      const rowIndex = s[0];
      const resultMetaId = s[1];
      const dataType = s[2];
      
      switch (dataType) {
        case "result": {
          const resultMeta = searchRunAction.resultMetas.find((rm) => rm.id == resultMetaId);
          if (resultMeta) {
            const result: RunSearchActionEdgeResult = {
              status: resultMeta.resultStatus,
              reason: resultMeta.resultStatusReason,
              updatedAt: getUpdatedTime(searchRunAction.updatedAt)
            } as RunSearchActionEdgeResult;
            const resultNodes = getJsonViewTreeNodes(key, result);
            setTimeout(() => {
              setTreeData((origin: any) => {
                const newTreeData = updateTreeData(origin, key, resultNodes);
                setExpandedKeys((prev) => [...prev, key]);
                return newTreeData;
              });
              resolve();
            }, 1000);
           
          } else {
            console.log("failed to get action results, no resultmeta found", resultMetaId);
            setTimeout(() => {
              setTreeData((origin: any) => {
                const newTreeData = updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get action result, try again"))
                setExpandedKeys((prev) => [...prev, key]);
                return newTreeData;
              });
              reject("failed to get action result, try again");
            }, 1000); 
          }
          break;
        }
        case "parameters":
          getEdgeEndpoint(searchRunAction.edgeID)
            .then((edgeEndpoint) => {
              getSearchActionParametersApi(
                edgeEndpoint,
                searchRunAction.tenantID,
                searchRunAction.searchRunID,
                searchRunAction.id,
                searchRunAction.appSubscriptionID,
                rowIndex
              ).then((parameters) => {
                const parameterNodes = getJsonViewTreeNodes(key, parameters)
                setTimeout(() => {
                  setTreeData((origin: any) => {
                    const newTreeData = updateTreeData(origin, key, parameterNodes);
                    setExpandedKeys((prev) => [...prev, key]);
                    return newTreeData;
                  });
                  resolve();
                },1000);
              })
                .catch((err) => {
                  console.log("failed to get action parameters", err);
                  setTimeout(() => {
                    setTreeData((origin: any) => {
                      const newTreeData = updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get action parameters, try again"))
                      setExpandedKeys((prev) => [...prev, key]);
                      return newTreeData;
                    });
                    reject("failed to get action parameters, try again");
                  }, 1000);
                });
            })
            .catch((err) => {
              console.log("failed to get edge endpoint", err);
              setTimeout(() => {
                setTreeData((origin: any) => {
                  const newTreeData = updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get edge endpoint, try again"))
                  setExpandedKeys((prev) => [...prev, key]);
                  return newTreeData;
                });
                reject("failed to get edge endpoint, try again");
              }, 1000);
            });
          break;
        case "outputs":
          getEdgeEndpoint(searchRunAction.edgeID)
            .then((edgeEndpoint) => {
              getSearchActionOutputsApi(
                edgeEndpoint,
                searchRunAction.tenantID,
                searchRunAction.searchRunID,
                searchRunAction.id,
                searchRunAction.appSubscriptionID,
                rowIndex
              ).then((outputs) => {
                const outputNodes = getJsonViewTreeNodes(key, outputs)
                setTimeout(() => {
                  setTreeData((origin: any) => {
                    const newTreeData = updateTreeData(origin, key, outputNodes);
                    setExpandedKeys((prev) => [...prev, key]);
                    return newTreeData;
                  });
                  resolve();
                }, 1000);
              })
                .catch((err) => {
                  console.log("failed to get action outputs", err);
                  setTimeout(() => {
                    setTreeData((origin: any) => {
                      const newTreeData = updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get action outputs, try again"))
                      setExpandedKeys((prev) => [...prev, key]);
                      return newTreeData;
                    });
                    reject("failed to get action outputs, try again");                
                  }, 1000);
                });
            })
            .catch((err) => {
              console.log("failed to get edge endpoint", err);
              setTimeout(() => {
                setTreeData((origin: any) => {
                  const newTreeData = updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get action outputs, try again"))
                  setExpandedKeys((prev) => [...prev, key]);
                  return newTreeData;
                });
                reject("failed to get edge endpoint, try again");                
              }, 1000);
            });
          break;
        default:
          resolve();
      }
    });
  };

  useEffect(() => {
    loadLogNodes();
    setLoadedKeys([]);
  }, [searchRunAction, lightMode]);

  return (
    <Collapse
      defaultActiveKey={expanded ? [searchRunAction.id] : []}
      size="small"
    >
      <Panel
        header={
          <Space size={8} direction="horizontal">
            <Text>{`${searchRunAction.name}(${searchRunAction.referenceName})`}</Text>
            <Image
              preview={false}
              draggable={false}
              width={"90px"}
              height={"20px"}
              src={getAppLogoUrl(searchRunAction.appID, lightMode)}
              style={{ marginTop: "4px" }}
            />
            <Tag color={getAvatarColor(`${searchRunAction.appName}(${searchRunAction.appSubscriptionName})`)}>
              {searchRunAction.appName}({searchRunAction.appSubscriptionName} )
            </Tag>
            {getRunActionStatus(searchRunAction)}
          </Space>
        }
        extra={
          <Space>
            <Badge
              count={searchRunAction.metrics?.timeTaken}
              color={token.colorInfo}
            />
            <Popover content={"View Details"}>
              <a href={`/search-run-actions/${searchRunAction.id}`} target="_blank" rel="noopener noreferrer">
                <LinkOutlined />
              </a>
            </Popover>
          </Space>
        }
        key={searchRunAction.id}
      >
        <Tree
          showIcon
          onExpand={(keys) => setExpandedKeys(keys)}
          expandedKeys={expandedKeys}
          loadData={onLoadData}
          onLoad={(keys) => setLoadedKeys(keys)}
          loadedKeys={loadedKeys}
          treeData={treeData}
        />
      </Panel>
    </Collapse>
  );
};

export default ActionBanner;