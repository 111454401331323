
import { FC } from "react";
import {
  Typography,
  Tooltip,
  List,
  theme
} from "antd";

import { useMediaQuery } from "react-responsive";
import { BigScreenQuery } from "types";
import { JsonViewer } from '@textea/json-viewer'
import { CodeBlock, atomOneDark, atomOneLight } from "react-code-blocks";

import { HLink } from "components/Link";
import { TextType } from "types";
import { useSettingsStore } from "store";

const { Text } = Typography;

export interface ColumnRenderProps {
  value: any;
  textType?: TextType;
  language?: any;
  highlight?: any;
}

export const ColumnRender: FC<ColumnRenderProps> = ({
  value,
  textType,
  language,
  highlight,
}) => {
  const isBigScreen = useMediaQuery(BigScreenQuery);
  const { token } = theme.useToken();
  const { lightMode } = useSettingsStore((state) => ({
    lightMode: state.lightMode,
  }));

  const onCopy = (path: any, value: any) => {
    let valueToCopy = value;
    if (typeof value === 'string' && value.startsWith('"') && value.endsWith('"')) {
      valueToCopy = value.slice(1, -1); // Remove the surrounding quotes
    }
    // Copy to clipboard
    navigator.clipboard.writeText(valueToCopy);
  };

  
  const defaultRender = () => {
    return (
      <JsonViewer
        rootName={false}
        editable={false}
        displayDataTypes={false}
        displaySize={false}
        enableClipboard={true}
        collapseStringsAfterLength={100}
        value={value}
        highlightUpdates={false}
        // sx={{ paddingLeft: 2}}
        defaultInspectDepth={0}
        onCopy={onCopy}
        theme={{
          base00: token.colorBgContainer, // Background color of the viewer
          base01: token.colorBgContainer, // Lighter background (not commonly used)
          base02: token.colorBgContainer, // Gutter or line numbers (if applicable)
          base03: "#888888", // Comments or metadata (if used)
          base04: "#555555", // Slightly brighter comment color
          base05: token.colorTextSecondary, // Default text color (keys, labels, generic text)
          base06: token.colorTextSecondary, // Secondary text color (not commonly used)
          base07: token.colorTextSecondary, // Brightest text color (often unused)
          base08: token.colorText, // Strings (e.g., "Hello world")
          base09: token.colorText, // Numbers (e.g., 123, 45.6)
          base0A: token.colorText, // Constants, booleans (true, false, null)
          base0B: token.colorText, // Function names (if applicable)
          base0C: token.colorText, // Special values (e.g., regex, dates)
          base0D: token.colorText, // Keys (e.g., "name" in {"name": "ChatGPT"})
          base0E:token.colorText, // Operators (if any)
          base0F: token.colorText, // Errors, warnings, or special cases
        }}

      />
    )
  }
  const getColumnRender = () => {
    if (!textType) {
      return defaultRender();
    }

    switch (textType) {
      case TextType.Artifact:
        return defaultRender();
      case TextType.Url:
        return (
          <HLink
            ellipsis
            style={{ width: "200px" }}
            href={value}
            target="_blank"
            rel="noopener noreferrer"
            text={String(value)}
            tooltip={String(value)}
          />
        );
      case TextType.Map:
      case TextType.Array:
        if ("string" == typeof value) {
          value = value.split(",")
        }
        return value?.length > 0 && (
          <List
            dataSource={value}
            renderItem={(item: string) => (
              <List.Item >
                <Tooltip title={item}>
                  <Text style={{ width: isBigScreen ? "400px" : "200px" }} ellipsis>{item}</Text>
                </Tooltip>
              </List.Item>
            )}
          />
        );
      case TextType.Code: {
        let newValue = [...value].join("\n");
        const tabSpaces = "  ";
        const startNewLineRegEx = /^(\/n)/g;
        const endNewLineRegEx = /$\/n/g;
        newValue = newValue.replaceAll("\t", tabSpaces);
        newValue = newValue.replaceAll(startNewLineRegEx, "\n");
        newValue = newValue.replaceAll(endNewLineRegEx, "\n");
        return (
          <div style={{ width: "auto" }}>
            <CodeBlock
              text={newValue}
              theme={lightMode ? atomOneLight : atomOneDark}
              language={language}
              showLineNumbers={false}
              wrapLongLines={false}
              as={undefined}
              forwardedAs={undefined}
              highlight={highlight}
            />
          </div>
        );
      }
      default:
        return defaultRender();
    }
  };
  return (
    <div>{getColumnRender()}</div>
  )
}