
import { AuthProvider, LoginOptions, TokenFields } from "./authprovider";
import Keycloak from "keycloak-js";
import { AbstractAuthProvider } from "./abstractauthprovider";

export interface KeycloakInitializationOptions {
  domain: string;
  clientID: string;
  realm: string;
  redirectUri: string;
  signInUri: string;
  subscribe: (token: string, fields: TokenFields) => Promise<void>;
}

export class KeycloakAuthProvider extends AbstractAuthProvider implements AuthProvider {
  private keycloak: Keycloak;
  private redirecUri : string;
  private signInUri : string;

  
  async loginWithCredentials(options: LoginOptions) {
    (options)
    throw new Error("Method not implemented.");
  }
  

  async loginWithRedirect(options: LoginOptions) {
    if (!this.keycloak) 
      throw new Error("Keycloak not initialized");

    if ( this.keycloak.didInitialize) {
      await this.keycloak.login({ redirectUri: this.redirecUri});
    } else {
      await this.keycloak.init({onLoad: "login-required",redirectUri: this.redirecUri});
    }

    const authenticated   = !!this.keycloak.authenticated;
    if (authenticated && this.keycloak.token) {
      const token: string = this.keycloak.token;
      const tokenFields: TokenFields = this.processToken(token);    
      await this.subscribe(token, tokenFields);   
      options.callback(null, null);               
    }
  }
  
  async logout(): Promise<void> {
    if (!this.keycloak) 
      throw new Error("Keycloak not initialized");
    await this.keycloak.logout({redirectUri: this.signInUri});
  }
  
  async refreshToken(): Promise<void> {
    if (!this.keycloak) 
      throw new Error("Keycloak not initialized");
    const updated : boolean = await this.keycloak.updateToken(30);
    if (updated && this.keycloak.token) {
      const token: string = this.keycloak.token;
      const tokenFields: TokenFields = this.processToken(token);      
      await this.subscribe(token, tokenFields);
    }
  }

  async handleRedirectCallback(): Promise<void> {
    console.debug("Handling redirect callback");
    await this.keycloak.init({onLoad: "check-sso",redirectUri: this.redirecUri});
    const isAuthenticated = !!this.keycloak.authenticated;
    console.debug("Is authenticated", isAuthenticated);
    if (isAuthenticated && this.keycloak.token) {
      const token: string = this.keycloak.token;
      const tokenFields: TokenFields = this.processToken(token);      
      await this.subscribe(token, tokenFields);      
    }
  } 

  async changeTokenScope(scope: string): Promise<void> {
    if (!this.keycloak) 
      throw new Error("Keycloak not initialized");
    console.debug("Changing token scope to", scope);
    const newScopes = `openid profile email ${scope}`;
    await this.keycloak.login({redirectUri: this.redirecUri, scope: newScopes});
    const isAuthenticated = !!this.keycloak.authenticated;
    if (isAuthenticated && this.keycloak.token) {
      const token: string = this.keycloak.token;
      const tokenFields: TokenFields = this.processToken(token);      
      await this.subscribe(token, tokenFields);      
    }
  }


  constructor(options: KeycloakInitializationOptions) {
    super(options.subscribe);
    this.redirecUri = options.redirectUri;
    this.signInUri = options.signInUri;
    this.keycloak = new Keycloak({
      realm: options.realm,
      url: options.domain,
      clientId: options.clientID
    });
  }
}
