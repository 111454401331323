import { FC, useEffect, useState, useRef } from "react";

import {
  Card,
  Layout,
  Space,
  Typography,
  theme,
  Image,
  Badge,
  Tabs,
  Dropdown,
  Button,
  MenuProps,
  Spin
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  DownloadOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignTopOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";

import { commonIcons } from "assets/icons";
import { SvgIcon } from "components/SvgIcon";
import { useDeveloperXStore, useSettingsStore } from "store";
import PageWrapper from "components/PageWrapper";
import { getStyles } from "utility/styles";
import ControlButton from "components/ControlButton/controlButton";
import { useNavigate, useParams } from "react-router-dom";
import { App, SpecStateType, AppTabType } from "types";
import { getAppLogoUrl } from "utility/app";
import AppOverviewTab from "./appOverviewTab";
import AppProvidersTab from "./appProvidersTab";
import AppCard from "pages/Apps/AppCards";
import { Content } from "antd/es/layout/layout";
import Modal from "components/Modal";
import { notification } from 'utility/notification';

const { Text } = Typography;
export const AppDeveloperView = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const { appId: appId } = useParams();
  const { token } = theme.useToken();
  const [deleteAppModal, setDeleteAppModal] = useState(false);
  const { mode } = useSettingsStore((state) => ({
    mode: state.lightMode,
  }));
  const classes = getStyles({
    container: {
      gap: token.marginXS,
      overflow: "hidden"
    },
  })();
  const [enablePublishModal, setEnablePublishModal] = useState<boolean>(false);
  const saveNeededRef = useRef(false);
  
  const {
    selectedApp,
    activeTab,
    activeAppTab,
    getApp,
    deleteApp,
    updateApp,
    setActiveAppTab,
  } = useDeveloperXStore((state) => ({
    selectedApp: state.selectedApp,
    activeTab: state.activeTab,
    activeAppTab: state.activeAppTab,
    getApp: state.getApp,
    updateApp: state.updateApp,
    deleteApp: state.deleteApp,
    setActiveAppTab: state.setActiveAppTab,
  }));

  useEffect(() => {
    if (appId && appId != "") {
      const asyncUseEffect = async () => {
        await getApp(activeTab, appId);
      }
      asyncUseEffect();
    }
  }, [appId]);

  const getHeaderControlButtons = () => {
    return (
      <Space size={1}>
        <ControlButton displayName={"Back"}
          svgComponent={
            <SvgIcon
              onClick={() => {
                navigate(-1);
              }}
              component={commonIcons.backIcon}
            />
          }
          hoverable={true}
        />
      </Space>
    );
  }

  const getAppStatus = (app: App) => {
    return (
      <>
        {app.state == SpecStateType.StatePublished && <Badge count="published" color="green" />}
        {app.state == SpecStateType.StateShared && <Badge count="published" color="green" />}
        {app.state == SpecStateType.StateShared && <Badge count="shared" color="blue" />}
        {(app.state == SpecStateType.StatePublishedDraft || app.state == SpecStateType.StateDraft) && <Badge count="draft" color="orange" />}

      </>
    )
  };

  const getTitle = () => {
    return (
      <Space size={8}>
        <Image
          preview={false}
          draggable={false}
          width={"auto"}
          height={"40px"}
          src={getAppLogoUrl(selectedApp.id, mode)}
          alt="company-logo"
        />
        <Text>{selectedApp.displayName}</Text>
        {getAppStatus(selectedApp)}
      </Space>
    );
  }

  const shareCurrentApp = async () => {
    //TODO
    console.log("Share Current App and its elemets");
    // try {
    //   setLoader(true);
    //   await shareWorkflow(selectedWorkflow.id)
    // } catch (error) {
    //   console.log(error);
    //   notification.error({
    //     message: "Something went wrong while sharing your workflow...!",
    //     duration: 6,
    //   });
    // } finally {
    //   setLoader(false);
    // }
  };

  const draftModeItems: MenuProps["items"] = [
    {
      key: "dump",
      label: "Dump",
      icon: <DownloadOutlined />,
    },
    {
      key: "delete",
      label: <div style={{ color: "red" }}>Delete</div>,
      icon: <DeleteOutlined style={{ color: "red" }} />,
    },
  ];


  const handleDraftModeMenuClick: MenuProps["onClick"] = (e) => {
    //TODO
    e.key == "dump" && console.log("On dump");
    e.key == "delete" && setDeleteAppModal(true);
  };

  const publishedModeItems: MenuProps["items"] = [
    {
      key: "unpublish",
      label: "Unpublish",
      icon: <VerticalAlignBottomOutlined />,
    },
    {
      key: "dump",
      label: "Dump",
      icon: <DownloadOutlined />,
    },
  ];

  const handlePublishedModeMenuClick: MenuProps["onClick"] = (e) => {
    //TODO
    e.key == "unpublish" && console.log("unpublish");
    e.key == "dump" && console.log("dump");
  };

  const publishedDraftModeItems: MenuProps["items"] = [
    {
      key: "dump",
      label: "Dump",
      icon: <DownloadOutlined />,
    },
  ];

  const handlepublishedDraftModeMenuClick: MenuProps["onClick"] = (e) => {
    e.key == "dump" && console.log("dump");
  };

  const getMenuOptions = () => {
    switch (selectedApp.state) {
      case SpecStateType.StateDraft:
        return {
          items: draftModeItems,
          onClick: handleDraftModeMenuClick,
        };
      case SpecStateType.StatePublished:
      case SpecStateType.StateShared:
        return {
          items: publishedModeItems,
          onClick: handlePublishedModeMenuClick,
        };
      case SpecStateType.StatePublishedDraft:
        return {
          items: publishedDraftModeItems,
          onClick: handlepublishedDraftModeMenuClick,
        };
      default:
        return {};
    }
  };

  const ShowMainButtons: FC = () => {
    return (
      <Space size={8}>
        <Button
          type="primary"
          icon={<VerticalAlignTopOutlined />}
          //TODO
          disabled={selectedApp.state == SpecStateType.StatePublished || selectedApp.state == SpecStateType.StateShared}
          onClick={() => setEnablePublishModal(true)}
        >
          Publish
        </Button>
        <Button
          type="primary"
          icon={<EditOutlined />}
          //TODO
          disabled={!(selectedApp.state == SpecStateType.StatePublished || selectedApp.state == SpecStateType.StateShared)}
          onClick={() => setEnablePublishModal(true)}
        >
          Edit
        </Button>
        {!selectedApp.shared && selectedApp.state == SpecStateType.StatePublished &&
          <Button
            type="primary"
            icon={<ShareAltOutlined />}
            disabled={selectedApp.shared}
            onClick={() => shareCurrentApp()}
          >
            Share
          </Button>
        }
      </Space>
    );
  };

  const getHeaderActionButtons = () => {
    return (
      <Space>
        <ShowMainButtons />
        <Dropdown placement="bottomLeft" menu={getMenuOptions()}>
          <Button
            icon={<SvgIcon component={commonIcons.moreOutlinedIcon} />}
            ghost
          />
        </Dropdown>
      </Space>
    )
  }

  const deleteSelectedApp = async () => {
    try {
      setLoader(true);
      await deleteApp(selectedApp.id);
      setDeleteAppModal(false);
      navigate(`/developer`);
      notification.success({
        message: "App deleted successfully",
        duration: 6,
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while deleting your app...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };


  const onChange = async (now: boolean) => {
    if (now && selectedApp.id) {
      await updateApp(selectedApp);
    }else {
      saveNeededRef.current = true;
    }
  };

  const updateAppAsync = async () => {
    const needUpdate = saveNeededRef.current;
    if (needUpdate && selectedApp.id) {
      await updateApp(selectedApp);
      saveNeededRef.current = false;
    }
  };

  useEffect(() => {
    const pollInterval = 15 * 1000;
    const intervalId = setInterval(updateAppAsync, pollInterval);
    return () => {
      clearInterval(intervalId);
      updateAppAsync();
    }
  }, []);


  return (
    <Spin spinning={loader}>
      {deleteAppModal && (
        <Modal
          title="Delete App"
          onClose={() => {
            setDeleteAppModal(false);
          }}
          loader={loader}
          open={deleteAppModal}
          onSubmit={deleteSelectedApp}
        >
          {"Are you sure you want to delete your app?"}
        </Modal>
      )}
      <PageWrapper
        header={
          <div
            id="search-run-header"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              padding: token.paddingXS,
              backgroundColor: token.colorBorderSecondary,
              alignItems: "center",
            }}
          >
            {getHeaderControlButtons()}
            {getTitle()}
            {getHeaderActionButtons()}
          </div>
        }
        content={
          <div id="app-details-container" className={classes.container}>
            <Layout
              style={{
                width: "100%",
                padding: token.paddingXS,
                overflowY: "auto",
                overflowX: "hidden"
              }}
            >
              <Content style={{
                height: "100%",
                width: "100%",
              }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <Card style={{ margin: token.margin, width: "100%" }}>
                    <Tabs
                      defaultActiveKey={activeAppTab}
                      onChange={(key) => setActiveAppTab(key as AppTabType)}
                      items={[
                        {
                          label: (
                            <Space size={token.marginXXS}>
                              <Text>Overview</Text>
                            </Space>
                          ),
                          key: AppTabType.Overview,
                          children: (
                            <div style={{ margin: token.margin, display: "flex", justifyContent: "center" }}>
                              {/* <div style={{position: "absolute", left: "0", top : "0"}}>
                                <AppCard key={0} app={selectedApp} appSubscriptions={[]} onAppEdit={ dummyFunc} onOpenAppConfig={dummyFunc}></AppCard>
                              </div> */}
                              <AppOverviewTab editMode={true} onChange={onChange}/>
                            </div>
                          ),
                        },
                        {
                          label: (
                            <Space size={token.marginXXS}>
                              <Text>Providers</Text>
                            </Space>
                          ),
                          key: AppTabType.Providers,
                          children: (
                            <div style={{ margin: "auto", display: "flex", justifyContent: "center" }}>
                              <AppProvidersTab />
                            </div>
                          ),
                        },
                      ]}
                    />

                  </Card>
                </div>
              </Content>
            </Layout>
          </div>
        }
        feedback={<></>}
      ></PageWrapper>
    </Spin>
  );
};
