import { create } from "zustand";

import { AuthProviderConfiguration, TokenStore } from "types/uam/token";
import { TokenFields } from "types/uam/authprovider";
import { Auth0AuthProvider } from "types/uam/auth0authprovider";
import { KeycloakAuthProvider } from "types/uam/kcauthprovider";

/* 
* Do not use persist middleware for token store. Of  at all required, use a partialize function to persist only the required fields
Curently persistance brings in post auth data in new sessions that causes issues
*/

export const useTokenStore = create<TokenStore>()(
  // persist(
    (set,get) => ({ 
      initialized: false,
      token: null,
      state: null,
      fields: null,
      authProvider: null,
      setState: async (state: string| null) => {
        set(() => ({
          state: state
        }));
      },
      initializeAuthProvider: (configuration : AuthProviderConfiguration) => {
        const initialized = get().initialized;
        console.log("Initializing auth provider", configuration, initialized);
        if (! get().initialized) {
          if (configuration.isCloud ) {
            const auth0 = new Auth0AuthProvider({
              domain: configuration.auth0Domain ?? "",
              clientID: configuration.auth0ClientId ?? "",
              audience: configuration.auth0Audience ?? "",
              redirectUri: configuration.auth0RedirectUri ?? "",
              realm: configuration.auth0Connection ?? "",
              state: "",
              subscribe: async (token: string , fields: TokenFields) => {
                set({fields: fields, token: token});
              },
            });
            console.log("Auth0 initialized");
            set({authProvider: auth0, initialized: true});
          } else {
            const keycloak = new KeycloakAuthProvider({
              realm: configuration.keycloakRealm ?? "",
              clientID: configuration.keycloakClientId ?? "",
              domain: configuration.keycloakDomain ?? "",
              redirectUri: configuration.keycloakRedirectUri ?? "",
              signInUri: configuration.keycloakSignInUri ?? "",
              subscribe: async (token: string, fields: TokenFields) => {
                set({fields: fields, token: token});
              },
            });
            console.log("Keycloak initialized");
            set({authProvider: keycloak, initialized: true});
          }
        }
      }      
    }),
    // {
    //   name: 'hypredge-tokens',
    //   partialize: (state) => {
    //     const { initialized, authProvider, ...persistedState } = state; 
    //     return persistedState;
    //   },      
    // }
  // )
)