import { Navigate, Outlet } from "react-router-dom";
import { useTokenStore } from "store/token";

const PrivateRoutes = () => {
  const tokenFields = useTokenStore((state) => state.fields);

  const path = window.location.pathname;
  const getUrl = () => {
    if (tokenFields?.subject)
      return `/relogin?user=${btoa(tokenFields.subject)}&path=${btoa(path)}`;
    return `/signin`;
  };
  const authenticated = tokenFields?.isAuthenticated;
  return authenticated ? <Outlet /> : <Navigate to={getUrl()} />;
};

export default PrivateRoutes;
