import { FC, useState } from "react";
import {
  theme,
  Button,
  Avatar,
  Typography,
} from "antd";

import { ExclamationCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";

import SimpleOAuth2Login from 'react-simple-oauth2-login';

import { OAuthFlowAuthorizeCode } from "types";
import axios from 'axios'
import { authorizationCode } from 'axios-oauth-client'
import { useAppConfigStore } from "store";

const { Text } = Typography;

export interface OAuth2LoginProps {
  enable: boolean;
  authorizeCode?: OAuthFlowAuthorizeCode;
  onSuccess: (refreshToken: string) => void;
  onFailure: (error: any) => void;
}

export const OAuth2Login: FC<OAuth2LoginProps> = ({
  enable,
  authorizeCode,
  onSuccess,
  onFailure
}) => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const [isLoginCompleted, setIsLoginCompleted] = useState<boolean>(false);
  const [isLoginFailed, setIsLoginFailed] = useState<boolean>(false);

  const oAuth2RedirectURI = useAppConfigStore.getState().oauth2RedirectUri;

  const getRefreshToken = async (code: string, scope: string) => {
    try {
      const getAuthorizationCode = authorizationCode(
        axios.create(),
        authorizeCode?.tokenURL,
        authorizeCode?.clientID,
        authorizeCode?.clientSecret,
        oAuth2RedirectURI
      )
      const response = await getAuthorizationCode(code, scope);
      setIsLoginCompleted(true);
      onSuccess(response.refresh_token);
    } catch (error: any) {
      console.error("error in exchange authorization code for tokens: ", error);
      setIsLoginCompleted(true);
      setIsLoginFailed(true);
      onFailure(error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <SimpleOAuth2Login
        authorizationUrl={authorizeCode?.authURL as string}
        clientId={authorizeCode?.clientID as string}
        redirectUri={oAuth2RedirectURI as string}
        responseType="code"
        scope={authorizeCode?.scopes.join(' ')}
        extraParams={{"access_type": "offline", "prompt": "consent"}}
        onSuccess={(res) => getRefreshToken(res.code, res.scope)}
        onFailure={(error) => {
          setLoader(false);
          console.error("error in authorization: ", error);
          setIsLoginCompleted(true);
          setIsLoginFailed(true);
          onFailure(error);
        }}
        render={(renderProps) => (
          <Button
            style={{ width: "100%", height: 40, marginTop: token.margin }}
            type="primary"
            disabled={!enable}
            loading={loader}
            onClick={() => {
              setIsLoginCompleted(false);
              setIsLoginFailed(false);
              setLoader(true);
              renderProps.onClick(); 
            }}
          >
            Login & Consent
          </Button>
        )}
      />
      {isLoginCompleted 
        ?
        isLoginFailed 
          ?
            <div style={{ "marginTop": token.marginSM }}>
              <Avatar size="small" src={<ExclamationCircleOutlined style={{ color: "red" }} />} />
              <Text>failed to authorize</Text>
            </div>
          :
            <div style={{ "marginTop": token.marginSM }}>
              <Avatar size="small" src={<CheckCircleOutlined style={{ color: "green" }} />} />
              <Text>Successfully authorizated</Text>
            </div>
        :
        <></>
      }
    </>
  );
};