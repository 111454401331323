import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';
import "./index.scss";

// import 'antd/dist/antd.css';

import { BrowserRouter } from "react-router-dom";
import App from "./App";

const MOUNT_NODE = document.getElementById("root") as HTMLElement;

const root = createRoot(MOUNT_NODE);

// To support '/ui' path for on-prem deployments
const baseName = window.location.pathname.startsWith("/ui") ? "/ui" : "/";

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
root.render(
  <BrowserRouter basename={baseName}>
    <App />
  </BrowserRouter>
);
