import { AbstractAuthProvider } from "./abstractauthprovider";
import { AuthProvider, LoginOptions, TokenFields } from "./authprovider";
import auth0 from "auth0-js";
import { Auth0Error } from "auth0-js";

export interface Auth0InitializationOptions {
  domain: string;
  clientID: string;
  audience: string;
  redirectUri: string;
  realm: string;
  state: string;
  subscribe: (token: string, fields: TokenFields) => Promise<void>;  
}

export class Auth0AuthProvider extends AbstractAuthProvider implements AuthProvider {
  private auth0: auth0.WebAuth;
  private redirecUri : string;
  private realm: string;
  async loginWithCredentials(options: LoginOptions){
    if (!options.username || !options.password) {
      console.log("Username or password not provided");
      return;
    }
    console.log("Logging in with credentials", options);
    this.auth0?.login(
      {
        email: options.username,
        password: options.password,
        realm: this.realm,
        responseType: "token id_token",
        redirectUri: this.redirecUri,
        scope: "openid profile email",
        state: options.state,
      },

      function (error: Auth0Error | null, result: any) {
        if (error) {
          console.log("Error logging in", error);
          options.callback(error, null);
        } else {
          console.log("Logged in successfully", result);
          options.callback(null, result);
        }
      }
    );
  }
  
  loginWithRedirect(options: LoginOptions): Promise<void> {
    (options)    
    throw new Error("Method not implemented.");
  }
  logout(): Promise<void> {
    throw new Error("Method not implemented.");
  }
  isAuthenticated?: boolean;
  
  refreshToken(): Promise<void> {
    throw new Error("Method not implemented.");
  }

  handleRedirectCallback(): Promise<void> {
    this.auth0.parseHash((err, authResult) => {
      console.log("Parsing hash");
      if (err) {
        console.log("Error parsing hash", err);
        return;
      }
      else {
        if (authResult?.accessToken) {
          const token = authResult.accessToken;
          const tokenFields: TokenFields = this.processToken(token);    
          this.subscribe(token, tokenFields);                
        } else {
          console.log("Failed to get access token");
        }
      }
    });    
    return Promise.resolve();
  }
  
  changeTokenScope(scope: string): Promise<void> {
    (scope)
    throw new Error("Method not implemented.");
  }
  
  constructor(options: Auth0InitializationOptions) {
    super(options.subscribe);
    this.redirecUri = options.redirectUri;
    this.realm = options.realm;
    this.auth0 = new auth0.WebAuth( {
      domain: options.domain,
      clientID: options.clientID,
      audience: options.audience,
      scope: "openid profile email"
    });

  }
}