import { FC, useEffect, useState } from "react";
import {
  Form,
  Space,
  Select,
  Spin,
  message,
  Button,
  Typography,
  theme,
  Input,
  Row,
  Switch,
} from "antd";

import {
  Action,
  AppInfo,
  Artifact,
  ArtifactActions,
  ArtifactProvider,
  Condition,
  Conditions,
  TriggerType,
  WorkflowAppSubscriptionInfo,
  WorkflowNodeCardProps,
  WorkflowStepOperator,
  WorkflowStepProvider,
  WorkflowStepProviders,
} from "types";

import ConditionsForm from "components/ConditionsForm";
import { FormInstance } from "antd/es/form";
import { validateFormFields } from "utility";
import { notification } from 'utility/notification';
import { parse } from 'utility/search';
import NodeCardApps from "./Apps";
import {
  useSearchArtifactProvidersStore,
  useSearchArtifactStore,
  useWorkflowStepProvidersStore,
  useWorkflowStepsStore,
  useWorkflowStore,
} from "store";
import CollapsePanel from "components/CollapsePanel";
import { workflowIcons } from "assets/icons";
import { CopyOutlined } from "@ant-design/icons";
import { SvgIcon } from "components/SvgIcon";
import { FieldLabel } from "components/FieldLabel";
import {
  buildWorkflowParameterTree,
  buildTriggerOutputTree,
  buildStepOutputTree,
  buildSearchActionParametersTree
} from "components/Suggestions";
import { generateReferenceName } from "utility/workflow";
import ActionForm from "components/HyprFlows/ActionForm";
import { getArtifactFieldsById } from "utility/search/lexer";
import RichTextEditor from "components/RichTextEditor";
const { Text } = Typography;
const { Option } = Select;

const SearchOperatorCard: FC<WorkflowNodeCardProps> = ({
  id,
  workflowId,
  resourceId,
  nodeType,
  editMode,
  onClose
}) => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();

  const [disableActionSelection, setDisableActionSelection] = useState(false);
  const [showSearchApps, setShowSearchApps] = useState(false);
  const [showActionForm, setShowActionForm] = useState(false);
  const [parameterSuggestionsTree, setParameterSuggestionsTree] = useState<[]>([]);
  const [searchActionParameterSuggestionsTree, setSearchActionParameterSuggestionsTree] = useState<[]>([]);

  //Search Query
  const [selectedArtifactId, setSelectedArtifactId] = useState<string>("");
  const [conditionsForm, setConditionsForm] = useState<undefined | FormInstance>(undefined);
  const [selectedConditions, setSelectedConditions] = useState<Array<Condition | Conditions>>([{} as Condition]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [markPassOnAllAppsSucceed, setMarkPassOnAllAppsSucceed] = useState<boolean>(false);
  const [searchAppInfos, setSearchAppInfos] = useState(new Map<string, AppInfo>());
  const [selectedSearchApps, setSelectedSearchApps] = useState<Record<string, string | string[]>[]>([]);

  //search Action
  const [actionDataForm, setActionDataForm] = useState<undefined | FormInstance>(undefined);
  const [selectedActionId, setSelectedActionId] = useState<string>("");
  const [actionData, setActionData] = useState<Record<string, any>>({});

  const { selectedWorkflow, updateWorkflow } = useWorkflowStore((state) => ({
    selectedWorkflow: state.selectedWorkflow,
    updateWorkflow: state.updateWorkflow,
  }));

  const [referenceName, setReferenceName] = useState<string>(selectedWorkflow?.steps[id]?.referenceName);
  const [description, setDescription] = useState<string>(selectedWorkflow?.steps[id]?.description);

  const { artifacts, getArtifacts } = useSearchArtifactStore((state) => ({
    artifacts: state.artifacts,
    getArtifacts: state.getArtifacts,
  }));
  const { actions } = useWorkflowStepsStore((state) => ({
    actions: state.actions,
  }));

  const syncArtifacts = async () => {
    try {
      if (!artifacts.length) {
        await getArtifacts();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    syncArtifacts();
  }, []);

  const syncCurrentState = async () => {
    const operatorStep = selectedWorkflow.steps[id] as WorkflowStepOperator;

    if (operatorStep) {
      
      const parametersValues = operatorStep.parameters;
      const searchQuery = parametersValues["query"] as string;
      if (searchQuery && searchQuery != "") {
        const parseResult = parse(searchQuery);
        const artifactId = parametersValues["artifact_id"] as string;
        setSelectedArtifactId(artifactId);
        form.setFieldValue("artifact", artifactId);

        //remove double quotes from condition value if any
        parseResult.conditions = parseResult.conditions.map((condition: Condition | Conditions, index: number) => {
          if (Array.isArray(condition)) {
            return condition.map((andCondition: Condition, index: number) => {
              andCondition.value = andCondition.value.replace(/^"(.*)"$/, `$1`);
              return andCondition;
            });
          } else {
            condition.value = condition.value.replace(/^"(.*)"$/, `$1`);
            return condition;
          }
        });

        setSelectedConditions(parseResult.conditions as Conditions);

        if (operatorStep.searchProviders) {
          const searchApps = [] as Record<string, string | string[]>[];
          operatorStep.searchProviders.map((provider) => {
            searchApps.push({
              providerId: provider.providerID,
              appSubscriptions: provider.appSubscriptionInfos.map((appSubscriptionInfo) => (
                appSubscriptionInfo.appSubscriptionID
              ))
            });
          });

          setSelectedSearchApps(searchApps);
          form.setFieldValue("searchApps", searchApps);
        }

        const actionId = parametersValues["action_id"] as string;
        if (actionId != "") {
          setSelectedActionId(actionId);
          form.setFieldValue("actionId", actionId);

          const actionData = {} as Record<string, any>;
          actionData.paramValues = operatorStep.actionParameters;

          if (operatorStep.actionProvider && operatorStep.actionProvider.providerID) {
            actionData.providerId = operatorStep.actionProvider.providerID;
            actionData.appSubscriptions = operatorStep.actionProvider?.appSubscriptionInfos?.map((s) => (s.appSubscriptionID));
            actionData.providerParamValues = operatorStep.actionProvider?.parameters;
          }

          setActionData(actionData);
          form.setFieldValue("actionData", actionData);
        }
      }

      const markPassOnAllAppsSucceedParam = parametersValues["mark_pass_on_all_apps_succeed"] as boolean;
      if(markPassOnAllAppsSucceedParam != undefined) {
        setMarkPassOnAllAppsSucceed(markPassOnAllAppsSucceedParam);
        form.setFieldValue("markPassOnAllAppsSucceed", markPassOnAllAppsSucceedParam);
      }else {
        setMarkPassOnAllAppsSucceed(false);
        form.setFieldValue("markPassOnAllAppsSucceed", false);
      }
      
    }
  };

  const buildParameterSuggestions = () => {
    if (selectedWorkflow) {
      const suggestionsTree: [] = [];
      buildWorkflowParameterTree(selectedWorkflow.parameters, suggestionsTree);
      selectedWorkflow.triggerRef.triggerType == TriggerType.Custom && buildTriggerOutputTree(selectedWorkflow.triggerRef.triggerID, suggestionsTree);
      buildStepOutputTree(selectedWorkflow, id, suggestionsTree);
      setParameterSuggestionsTree(suggestionsTree);
    }
  };

  useEffect(() => {
    syncCurrentState();
    buildParameterSuggestions();
  }, [artifacts])

  const getArtifactActions = (artifactId: string): Action[] => {
    const lactions: Action[] = [];
    const artifact = artifacts?.find((i: Artifact) => i.id === artifactId);
    if (!artifact) {
      return lactions;
    }
    artifact.actions.forEach((aa) => {
      const action = actions.find((action) => aa.id == action.id);
      if (action) {
        lactions.push(action)
      }
    })

    return lactions;
  };

  const { artifactProvidersMap, getArtifactProviders } =
    useSearchArtifactProvidersStore((state) => ({
      artifactProvidersMap: state.artifactProvidersMap,
      getArtifactProviders: state.getArtifactProviders,
    }));

  const loadArtifactProviders = async (artifctId: string) => {
    try {
      setLoader(true);
      await getArtifactProviders(artifctId);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (selectedArtifactId && selectedArtifactId != "") {
      loadArtifactProviders(selectedArtifactId);
      setShowSearchApps(true);
    } else {
      setShowSearchApps(false);
    }
  }, [selectedArtifactId]);

  const syncSearchAppInfos = async () => {
    try {
      const artifactProvider = artifactProvidersMap.get(selectedArtifactId);
      const appInfos = new Map<string, AppInfo>();
      if (artifactProvider) {
        artifactProvider.providers &&
          artifactProvider.providers.map((provider: ArtifactProvider) => {
            if (provider?.appInfo?.appSubscriptionsInfos) {
              provider.appInfo.appSubscriptionsInfos = provider.appInfo.appSubscriptionsInfos.filter((as) => as.tenantID == selectedWorkflow.tenantID);
            }
            appInfos.set(provider.id, provider.appInfo);
          });
      }
      setSearchAppInfos(appInfos);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    syncSearchAppInfos();
  }, [artifactProvidersMap]);

  const { actionProvidersMap, getActionProviders } =
    useWorkflowStepProvidersStore((state) => ({
      actionProvidersMap: state.actionProvidersMap,
      getActionProviders: state.getActionProviders,
    }));

  const loadActionProviders = async (actionId: string) => {
    try {
      if (actionProvidersMap.get(actionId) == undefined) {
        setLoader(true);
        await getActionProviders(actionId);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const buildSearchActionParameterSuggestions = () => {
    if (selectedWorkflow) {
      const suggestionsTree: [] = [];
      const artifact = artifacts?.find((artifact: Artifact) => artifact.id == selectedArtifactId)
      artifact && buildSearchActionParametersTree(artifact.displayName, suggestionsTree);
      setSearchActionParameterSuggestionsTree(suggestionsTree);
    }
  };

  useEffect(() => {
    if (selectedActionId && selectedActionId != "") {
      loadActionProviders(selectedActionId);
      buildSearchActionParameterSuggestions();
      setShowActionForm(true);
    } else {
      setShowActionForm(false);
    }
  }, [selectedActionId]);

  const handleActionChangeOnAppSelection = (apps: Record<string, string | string[]>[]) => {
    if(apps.length == 1 && apps[0].appSubscriptions.length == 1) {
      setDisableActionSelection(false);
    }else {
      setSelectedActionId("");
      form.setFieldValue("actionId", "");
      setShowActionForm(false);
      setDisableActionSelection(true);
      const actionData = {} as Record<string, any>;
      setActionData(actionData);
      form.setFieldValue("actionData", actionData);
    }
  }

  const isConditionReady = (condition: Condition) => {
    return (
      condition.fact &&
      condition.fact != "" &&
      condition.operator &&
      condition.operator != "" &&
      condition.value &&
      condition.value != ""
    );
  };

  const buildQueryConditions = (conditions: Array<Condition | Conditions>, queryConditions: string[]) => {

    conditions.map((condition: Condition | Conditions, index: number) => {
      (index != 0) && queryConditions.push('or');
      if (Array.isArray(condition)) {
        condition.map((andCondition: Condition, index: number) => {
          (index != 0) && queryConditions.push('and');
          if (isConditionReady(andCondition)) {
            queryConditions.push(andCondition.fact);
            queryConditions.push(andCondition.operator);
            if (andCondition.value?.split(" ").length > 1) {
              queryConditions.push('"' + andCondition.value + '"');
            } else {
              queryConditions.push(andCondition.value);
            }
          }
        });
      } else {
        if (isConditionReady(condition)) {
          queryConditions.push(condition.fact);
          queryConditions.push(condition.operator);
          if (condition.value?.split(" ").length > 1) {
            queryConditions.push('"' + condition.value + '"');
          } else {
            queryConditions.push(condition.value);
          }
        }
      }
    });
  };

  const buildQuery = (artifactId: string, conditions: Array<Condition | Conditions>) => {
    const artifact = artifacts?.find((artifact) => artifact.id == artifactId)?.name;
    if (artifact) {
      let query = artifact;

      if (conditions && conditions.length) {
        const queryConditions: string[] = [];
        buildQueryConditions(conditions, queryConditions);
        if (queryConditions.length) {
          query += " " + queryConditions.join(" ");
        }
      }
      return query;
    }
    return "";
  };

  const updateSelectedWorkflow = async () => {
    try {
      setLoader(true);
      await updateWorkflow(selectedWorkflow);
      notification.success({
        message: `Updated workflow ${selectedWorkflow.name} successfully`,
        duration: 6,
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: `Updating workflow ${selectedWorkflow.name} failed`,
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const getAppInfo = (providerId: string) => {
    const actionProvider = actionProvidersMap.get(selectedActionId);
    return actionProvider?.providers.find((provider) => provider.id == providerId)?.appInfo;
  };

  const OnFinish = (values: any) => {
    form
      .validateFields()
      .then(() => {
        const operatorStep = selectedWorkflow.steps[id] as WorkflowStepOperator;
        operatorStep.isConfigured = true;
        operatorStep.name = values.name;
        operatorStep.referenceName = referenceName;
        operatorStep.description = values.description;

        operatorStep.parameters = {};
        operatorStep.parameters.artifact_id = values.artifact as string
        operatorStep.parameters.query = buildQuery(values.artifact, values.conditions as Conditions);

        const searchProviders = [] as WorkflowStepProviders;
        selectedSearchApps.map((selectedSearchApp) => {
          if (selectedSearchApp) {
            const searchProvider = {} as WorkflowStepProvider;
            const providerId = selectedSearchApp.providerId as string;
            if (providerId) {
              const appInfo = searchAppInfos.get(providerId);
              if (appInfo) {
                searchProvider.appID = appInfo.id;
                searchProvider.displayName = appInfo.displayName;
                searchProvider.providerID = providerId;
                searchProvider.appSubscriptionInfos = [] as WorkflowAppSubscriptionInfo[];
                const subscriptions = selectedSearchApp.appSubscriptions as string[];
                subscriptions?.map((subscriptionId) => {
                  searchProvider.appSubscriptionInfos.push({
                    appSubscriptionID: subscriptionId,
                  } as WorkflowAppSubscriptionInfo)
                });
              }
              searchProviders.push(searchProvider);
            }
          }
        });
        operatorStep.searchProviders = searchProviders;

        if (values.actionId) {
          operatorStep.parameters.action_id = values.actionId as string;
          const actionProvider = {} as WorkflowStepProvider;
          const actionData = values.actionData;
          if (actionData) {
            operatorStep.actionParameters = actionData.paramValues;
            const providerId = actionData.providerId as string;
            if (providerId) {
              actionProvider.providerID = providerId;
              const appInfo = getAppInfo(providerId);
              if (appInfo) {
                actionProvider.appID = appInfo.id;
                actionProvider.displayName = appInfo.displayName;
                actionProvider.parameters = actionData.providerParamValues;
                actionProvider.appSubscriptionInfos = [] as WorkflowAppSubscriptionInfo[];
                const subscriptions = actionData.appSubscriptions as string[];
                subscriptions?.map((subscriptionId) => {
                  actionProvider.appSubscriptionInfos.push({
                    appSubscriptionID: subscriptionId,
                  } as WorkflowAppSubscriptionInfo);
                });
              }
            }
            setActionData(actionData);
          }
          operatorStep.actionProvider = actionProvider;
        } else {
          operatorStep.parameters.action_id = "";
        }
        operatorStep.isAuditRequired = false;

        selectedWorkflow.steps[id] = operatorStep;

        if(markPassOnAllAppsSucceed) {
          operatorStep.parameters.mark_pass_on_all_apps_succeed = markPassOnAllAppsSucceed
        }

        updateSelectedWorkflow();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onValuesChange = async (changedValues: any, _: any) => {
    const values = form.getFieldsValue(true);
    const query = buildQuery(values.artifact, values.conditions);
    setSearchQuery(query);
  };

  const resetAll = () => {
    form.resetFields();
    setSelectedConditions([{} as Condition]);
    setSearchQuery("");
    setSelectedArtifactId("");
    setSelectedSearchApps([] as Record<string, string | string[]>[]);
    setSearchAppInfos(new Map<string, AppInfo>());
    setSelectedActionId("");
    setActionData({} as Record<string, any>);
  };

  return (
    <Form
      form={form}
      name="searchOperatorCardForm"
      layout="vertical"
      autoComplete="off"
      onValuesChange={onValuesChange}
      onFinish={OnFinish}
    >
      <Spin spinning={loader}>
        <Space direction="vertical" size={token.sizeXXS} style={{ display: 'flex' }}>
          <Form.Item
            name="name"
            label={<FieldLabel label={"Name"} help={"Operator Name"} />}
            initialValue={selectedWorkflow.steps[id]?.name}
            rules={[
              { required: true, message: "Name is required!" },
            ]}
            extra={referenceName != "" && <Text italic type="secondary">Reference Name: {referenceName}</Text>}
          >
            <Input disabled={!editMode} onChange={(e) => setReferenceName(generateReferenceName(e.target.value, selectedWorkflow))} />
          </Form.Item>
          <Form.Item
            name="description"
            label={<FieldLabel label={"Description"} />}
            initialValue={""}
            validateTrigger="onSubmit"
          >
            <RichTextEditor
              height="100px"
              maxChars={4096}
              editMode={editMode}
              content={description}
              onChange={(value) => {
                setDescription(value);
                form.setFieldValue("description", value);
              }}
            />
          </Form.Item>
          <CollapsePanel
            name={
              <Space size={token.marginXXS}>
                <SvgIcon component={workflowIcons.parametersShortIcon} />
                <Text>Parameters</Text>
              </Space>
            }
            ghost={false}
            collapsePanel={false}
          >
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                disabled={!editMode}
                size="small"
                type="link"
                onClick={() => resetAll()}
              >
                Reset All
              </Button>
            </div>
            
            {searchQuery != "" && (
              <Form.Item
                name="query"
                label={<FieldLabel label={"Query"} />}
              >
                <Space>
                  <Text>
                    {searchQuery}
                  </Text>
                  <CopyOutlined
                    onClick={(e: any) => {
                      e.stopPropagation();
                      navigator.clipboard.writeText(searchQuery);
                      message.success("Copied!");
                    }}
                  />
                </Space>
              </Form.Item>
            )}
            <Form.Item
              name="artifact"
              label={<FieldLabel label={"Artifact"} help={"Artifact to be searched"} />}
              rules={[
                { required: true, message: "Artifact should be selected!" },
              ]}
            >
              <Select
                disabled={!editMode}
                onChange={(id) => {
                  form.resetFields([
                    "conditions",
                    "searchApps",
                    "action",
                    "actionApps",
                  ]);
                  setSelectedConditions([{} as Condition]);
                  setSelectedArtifactId(id);
                }}
                options={artifacts?.map((artifact: Artifact) => ({
                  value: artifact.id,
                  label: artifact.displayName,
                }))}
              />
            </Form.Item>
            <Form.Item
              name="conditions"
              label={<FieldLabel label={"Conditions"} help={"Conditions to filter search results"} />}
              validateTrigger="onSubmit"
              rules={[
                { required: true, message: "Conditions are required!" },
                { validator: (_, value) => validateFormFields(conditionsForm) },
              ]}>
              <ConditionsForm
                key={selectedArtifactId}
                editMode={editMode}
                required={true}
                conditions={selectedConditions}
                factList={Object.entries(getArtifactFieldsById(selectedArtifactId)).map(([name]) => name)}
                valueSuggestionsTree={parameterSuggestionsTree}
                onChange={(values) => {
                  values && setSelectedConditions(values);
                }}
                onRender={form => setConditionsForm(form)}
              />
            </Form.Item>
            {showSearchApps &&
              (<CollapsePanel
                name={
                  <Space size={token.marginXXS}>
                    <SvgIcon component={workflowIcons.appsShortIcon} />
                    <Text>Apps</Text>
                  </Space>
                }
                ghost={false}
                collapsePanel={false}
              >
                <Form.Item
                  name="searchApps"
                  validateTrigger="onSubmit"
                  rules={[
                    { validator: (_, value) => (!value || (value && value.length == 0)) ? Promise.reject("Apps are required!") : Promise.resolve() }
                  ]}>
                  <NodeCardApps
                    appInfos={searchAppInfos}
                    selectedApps={selectedSearchApps}
                    editMode={editMode}
                    onChange={(apps) => {
                      setSelectedSearchApps(
                        form.getFieldValue("searchApps")
                          ? form.getFieldValue("searchApps")
                          : apps as Record<string, string | string[]>[]
                      )
                      handleActionChangeOnAppSelection(apps as Record<string, string | string[]>[]);
                    }}
                  />
                </Form.Item>
              </CollapsePanel>
              )
            }
            <Form.Item
              name="markPassOnAllAppsSucceed"
              label={<FieldLabel label={"Mark Pass When All Apps Succeed"} help={"Mark operator as passed only when all apps succeeds"} />}
              style={{marginTop: "10px"}}
            >
              <Select
                disabled={!editMode}
                showAction={['focus', 'click']}
                allowClear
                value={markPassOnAllAppsSucceed}
                onChange={(val)=>setMarkPassOnAllAppsSucceed(val)}
                defaultValue={markPassOnAllAppsSucceed}
              >
                <>
                  <Option key={1} value={true}>
                    True
                  </Option>
                  <Option key={0} value={false}>
                    False
                  </Option>
                </>
              </Select>
            </Form.Item>
            <Form.Item
              name="actionId"
              label={<FieldLabel label={"Action (Optional)"} help={"Action to be taken on search results"} />}
            >
              <Select
                disabled={!editMode || disableActionSelection}
                allowClear
                onClear={() => setSelectedActionId("")}
                onChange={(id) => setSelectedActionId(id)}
                options={getArtifactActions(selectedArtifactId)?.map(
                  (action) => ({
                    value: action.id,
                    label: action.displayName,
                  })
                )}
              />
            </Form.Item>
            {showActionForm &&
              <Form.Item
                name="actionData"
                validateTrigger="onSubmit"
                rules={[
                  { validator: (_, value) => validateFormFields(actionDataForm) },
                ]}
              >
                <ActionForm
                  actionId={selectedActionId}
                  tenantId={selectedWorkflow.tenantID}
                  isConfigured={selectedWorkflow?.steps[id]?.isConfigured}
                  editMode={editMode}
                  actionData={actionData}
                  hideOutputs={true}
                  suggestionsTree={searchActionParameterSuggestionsTree}
                  onRender={(form) => setActionDataForm(form)}
                />
              </Form.Item>
            }
          </CollapsePanel>
        </Space>
      </Spin>
      {editMode && (
        <Row justify="space-between" style={{ marginTop: token.margin }}>
          <Button key="cancel" onClick={onClose}>
            Cancel
          </Button>
          <Button
            key="save"
            type="primary"
            htmlType="submit"
            size="middle"
          >
            Save
          </Button>
        </Row>
      )}
    </Form>
  );
};

export default SearchOperatorCard;
