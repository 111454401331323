import { AuthProvider, TokenFields , LoginOptions} from "./authprovider";
import jwtDecode from "jwt-decode";
import { JwtPayload } from "jwt-decode";


interface TokenClaims {
  zs_tenant_id: string;
  zs_user_id: string;
  zs_environment: string;
  zs_light_mode : boolean, 
  zs_light_mode_primary_color : string,
  zs_dark_mode_primary_color : string
  scope: string;
}

export class AbstractAuthProvider implements AuthProvider{
  async loginWithCredentials(options: LoginOptions): Promise<void> {
    (options)
    throw new Error("Method not implemented.");
  }
  async loginWithRedirect(options: LoginOptions): Promise<void> {
    (options)
    throw new Error("Method not implemented.");
  }
  async logout(): Promise<void> {
    throw new Error("Method not implemented.");
  }
  
  async refreshToken(): Promise<void> {
    throw new Error("Method not implemented.");
  }

  async handleRedirectCallback(): Promise<void> {
    throw new Error("Method not implemented.");
  }

  async changeTokenScope(scope: string): Promise<void> {
    (scope)
    throw new Error("Method not implemented.");
  }

  subscribe: (token: string , fields: TokenFields) => Promise<void>;

  constructor(subscribe: (token: string, fields: TokenFields) => Promise<void>) {
    this.subscribe = subscribe;
  }

  private extractContext(scopes: string) : string {
    const context = scopes.split(" ").find((scope) => scope.startsWith("zsgroup-"));
    return context ? context.replace("zsgroup-", "") : "";
  }

  processToken(token: string) : TokenFields {
    const decoded : JwtPayload & TokenClaims = jwtDecode(token);          
    const leeway = 1000; // Allow for 1 second leeway/ skew in token expiry
    const isAuthenticated = decoded.exp && decoded.exp * 1000 - leeway > Date.now() ? true : false;

    const  tokenFields: TokenFields = {
      isAuthenticated,
      issuer: decoded.iss ?? "",
      expiry: decoded.exp ?? 0,
      tenantId: decoded.zs_tenant_id,
      userId: decoded.zs_user_id,
      subject: decoded.sub ?? "",
      context: this.extractContext(decoded.scope ?? ""),
      lightMode: decoded.zs_light_mode,
      lightModePrimaryColor: decoded.zs_light_mode_primary_color,
      darkModePrimaryColor: decoded.zs_dark_mode_primary_color,
      isSso: false
    };
    return tokenFields;
  }

}