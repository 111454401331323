import { SvgIcon } from "components/SvgIcon";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppConfigStore, useSettingsStore } from "store";

import { Divider, Image, Layout, Menu, theme } from "antd";

import { companyIcons, menuIcons } from "assets/icons";
import { useSidebarStore } from "store";

const { Sider } = Layout;

interface MenuItem {
  name: string;
  path: string;
  icon: JSX.Element;
  selectedIcon: JSX.Element;
  hoverIcon: JSX.Element;
  hidden?: boolean;
}

const Sidebar: FC = () => {
  const appState = useAppConfigStore((state) => state);
  const isSelfHosted = appState.isSelfHosted;
  const navigate = useNavigate();
  const [hoverItem] = useState("");
  const sidebarCollapsed = useSidebarStore((state) => state.sidebarCollapsed);
  const { token } = theme.useToken();
  const { mode } = useSettingsStore((state) => ({
    mode: state.lightMode,
  }));
  const [isProduction, setIsProduction] = useState(false);

  useEffect(() => {
    setIsProduction(appState.isProduction);
  }, [appState]);

  const RegularMenuItems: MenuItem[] = [
    {
      name: "Home",
      path: "/home",
      icon: <SvgIcon component={menuIcons.menuHomeIcon} />,
      selectedIcon: <SvgIcon component={menuIcons.menuHomeSelectedIcon} />,
      hoverIcon: <SvgIcon component={menuIcons.menuHomeHoverIcon} />,
    },
    {
      name: "Dashboard",
      path: "/dashboard",
      icon: <SvgIcon component={menuIcons.menuDashboardIcon} />,
      selectedIcon: <SvgIcon component={menuIcons.menuDashboardSelectedIcon} />,
      hoverIcon: <SvgIcon component={menuIcons.menuDashboardHoverIcon} />,
    },
    {
      name: "Workspace",
      path: "/workspace",
      icon: <SvgIcon component={menuIcons.menuWorkspaceIcon} />,
      selectedIcon: <SvgIcon component={menuIcons.menuWorkspaceSelectedIcon} />,
      hoverIcon: <SvgIcon component={menuIcons.menuWorkspaceHoverIcon} />,
    },
    {
      name: "Workflows",
      path: "/workflows",
      icon: <SvgIcon component={menuIcons.menuHyprFlowsIcon} />,
      selectedIcon: <SvgIcon component={menuIcons.menuHyprFlowsSelectedIcon} />,
      hoverIcon: <SvgIcon component={menuIcons.menuHyprFlowsHoverIcon} />,
    },
    {
      name: "Search",
      path: "/search",
      icon: <SvgIcon component={menuIcons.menuSearchIcon} />,
      selectedIcon: <SvgIcon component={menuIcons.menuSearchSelectedIcon} />,
      hoverIcon: <SvgIcon component={menuIcons.menuSearchHoverIcon} />,
    },
    {
      name: "Cases",
      path: "/cases",
      icon: <SvgIcon component={menuIcons.menuCasesIcon} />,
      selectedIcon: <SvgIcon component={menuIcons.menuCasesSelectedIcon} />,
      hoverIcon: <SvgIcon component={menuIcons.menuCasesHoverIcon} />,
    },
  ];

  const setupItems = [
    {
      name: "Edges",
      path: "/edges",
      icon: <SvgIcon component={menuIcons.menuEdgesIcon} />,
      selectedIcon: <SvgIcon component={menuIcons.menuEdgesSelectedIcon} />,
      hoverIcon: <SvgIcon component={menuIcons.menuEdgesHoverIcon} />,
    },
    {
      name: "Apps",
      path: "/apps",
      icon: <SvgIcon component={menuIcons.menuAppsIcon} />,

      selectedIcon: <SvgIcon component={menuIcons.menuAppsSelectedIcon} />,
      hoverIcon: <SvgIcon component={menuIcons.menuAppsHoverIcon} />,
    },
    {
      name: "Users And Roles",
      path: "/users-and-roles",
      icon: <SvgIcon component={menuIcons.menuUsersAndRolesIcon} />,
      selectedIcon: (
        <SvgIcon component={menuIcons.menuUsersAndRolesSelectedIcon} />
      ),
      hoverIcon: <SvgIcon component={menuIcons.menuUsersAndRolesHoverIcon} />,
    },
    {
      name: "Administration",
      path: "/administration",
      icon: <SvgIcon component={menuIcons.menuAdministrationIcon} />,
      selectedIcon: (
        <SvgIcon component={menuIcons.menuAdministrationSelectedIcon} />
      ),
      hoverIcon: <SvgIcon component={menuIcons.menuAdministrationHoverIcon} />,
    },
  ];

  const builderItems: MenuItem[] = [
    {
      name: "Managed Mode",
      path: "/managed-mode",
      icon: <SvgIcon component={menuIcons.menuManagedModeIcon} />,
      selectedIcon: (
        <SvgIcon component={menuIcons.menuManagedModeSelectedIcon} />
      ),
      hoverIcon: <SvgIcon component={menuIcons.menuManagedModeHoverIcon} />,
      hidden: appState.isSelfHosted,
    },
    {
      name: "Developer",
      path: "/developer",
      icon: <SvgIcon component={menuIcons.menuDeveloperIcon} />,
      selectedIcon: <SvgIcon component={menuIcons.menuDeveloperSelectedIcon} />,
      hoverIcon: <SvgIcon component={menuIcons.menuDeveloperHoverIcon} />,
      hidden: isProduction || appState.isSelfHosted,
    },
  ];

  const [menuItems, setMenuItems] = useState(RegularMenuItems);

  useEffect(() => {
    setMenuItems(RegularMenuItems);
  }, []);

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={sidebarCollapsed}
      width={200}
      style={{
        overflow: "hidden",
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
        flexDirection: "row",
        backgroundColor: token.colorBgContainer,
        zIndex: 100,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: token.paddingContentHorizontalSM,
        }}
      >
        <Image
          preview={false}
          height={32}
          src={
            sidebarCollapsed
              ? companyIcons.hyprEdgeLogoShortSvg
              : mode
              ? companyIcons.hyprEdgeLogoWebSvg
              : companyIcons.hyprEdgeLogoWebDarkSvg
          }
          onClick={() => window.open("https://hypredge.com", "_blank")}
        />
      </div>
      <Menu
        mode="inline"
        selectedKeys={[location.pathname]}
        onClick={(e: any) => navigate(e.key)}
      >
        {menuItems.map((item) => {
          return (
            <Menu.Item
              key={item.path}
              icon={
                location.pathname == item.path
                  ? item.selectedIcon
                  : hoverItem == item.path
                  ? item.hoverIcon
                  : item.icon
              }
            >
              {!sidebarCollapsed &&
              location.pathname != item.path &&
              hoverItem == item.path
                ? item.name
                : item.name}
            </Menu.Item>
          );
        })}
        <Menu.Item disabled key={"sidebar-divider-1"}>
          <Divider />
        </Menu.Item>
        {setupItems.map((item) => {
          return (
            <Menu.Item
              key={item.path}
              icon={
                location.pathname == item.path
                  ? item.selectedIcon
                  : hoverItem == item.path
                  ? item.hoverIcon
                  : item.icon
              }
            >
              {!sidebarCollapsed &&
              location.pathname != item.path &&
              hoverItem == item.path
                ? item.name
                : item.name}
            </Menu.Item>
          );
        })}
        {builderItems.filter((item) => !item.hidden).length != 0
          &&
          <Menu.Item disabled key={"sidebar-divider-2"}>
            <Divider />
          </Menu.Item>
        }
        {builderItems
          .filter((item) => !item.hidden)
          .map((item) => {
            return (
              <Menu.Item
                key={item.path}
                icon={
                  location.pathname == item.path
                    ? item.selectedIcon
                    : hoverItem == item.path
                    ? item.hoverIcon
                    : item.icon
                }
              >
                {!sidebarCollapsed &&
                location.pathname != item.path &&
                hoverItem == item.path
                  ? item.name
                  : item.name}
              </Menu.Item>
            );
          })}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
