import { useEffect, useState, FC } from "react";
import {
  Row,
  Button,
  Space,
  theme,
  Typography,
  Card,
  Form,
  Spin,
  FormInstance,
  Input,
} from "antd";

import {  ArrowRightOutlined } from "@ant-design/icons";
import pRetry, { FailedAttemptError } from 'p-retry';

import { SvgIcon } from "components/SvgIcon";
import {  useSearchArtifactStore, useSearchStore, useSettingsStore, useWorkflowStepProvidersStore } from "store";
import { ParameterStoreEdgePolicyName, PolicyCategoryType, SearchActionInfo, SearchResultCardState, SearchResultCardType, SearchRunActionInfo, SearchRunActionStatus, SearchRunResultMeta } from "types";
import {  workflowIcons } from "assets/icons";
import { notification } from 'utility/notification';
import { FieldLabel } from "components/FieldLabel";
import { validateFormFields } from "utility";
import ActionForm from "components/HyprFlows/ActionForm";
import { buildSearchActionParametersTree } from "components/Suggestions";
import { buildParameterStoreTree } from "components/Suggestions/tree";
import { usePolicyStore } from "store/policy";
import { useParameterStoreStore } from "store/parameter_store";
import { ActionList } from "./actionList";
import { generateReferenceName } from "utility/search";

const { Text } = Typography;

export interface ActionPanelProps {
  searchId: string;
  resultMeta: SearchRunResultMeta;
  maxHeight: string;
}

const ActionPanel: FC<ActionPanelProps> = ({
  searchId,
  resultMeta,
  maxHeight,
}) => {

  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const [actionCardTitle, setActionCardTitle] = useState("Actions");
  const [actionLoader, setActionLoader] = useState(false);
  const [selectedSearchRunActionInfo, setSelectedSearchRunActionInfo] = useState<SearchRunActionInfo>({} as SearchRunActionInfo)
  const [selectedAction, setSelectedAction] = useState<string>("");
  const [actionDataForm, setActionDataForm] = useState<undefined | FormInstance>(undefined);
  const [showActionList, setShowActionList] = useState(false);
  const [actionData, setActionData] = useState<Record<string, any>>({});
  const [parametersSuggestionsTree, setParametersSuggestionsTree] = useState<[]>([]);

  const { mode } = useSettingsStore((state) => ({
    mode: state.lightMode,
  }));

  const abortController = new AbortController();

  const loadParametersStore = async () => {
    try {
      const paramStorePolicy = await usePolicyStore.getState().getPolicy(PolicyCategoryType.WorkflowGeneral, ParameterStoreEdgePolicyName, false);
      if (paramStorePolicy) {
        if (paramStorePolicy?.value.data && paramStorePolicy?.value.data != "" && paramStorePolicy?.id) {
          const edgeId = paramStorePolicy.value.data;
          const storeId = paramStorePolicy.id;
          await useParameterStoreStore.getState().getParameters(edgeId, storeId);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const {
    searchRunActionSelectedDatas,
    currentSearchRunActions,
    runSearchActions,
    getSearchRunAction,
    clearCurrentSearchRunAction,
    setSearchCardsState,
  } = useSearchStore((state) => ({
    searchRunActionSelectedDatas: state.searchRunActionSelectedDatas,
    currentSearchRunActions: state.currentSearchRunActions,
    runSearchActions: state.runSearchActions,
    getSearchRunAction: state.getSearchRunAction,
    clearCurrentSearchRunAction: state.clearCurrentSearchRunAction,
    setSearchCardsState: state.setSearchCardsState,
  }));

  const { artifacts } = useSearchArtifactStore((state) => ({ artifacts: state.artifacts, }));

  const { actionProvidersMap, getActionProviders } =
    useWorkflowStepProvidersStore((state) => ({
      actionProvidersMap: state.actionProvidersMap,
      getActionProviders: state.getActionProviders,
    }));

  const syncSearchRunActionInBackground = async (searchRunActionId: string) => {
    const sr = await getSearchRunAction(searchRunActionId, true);
    if (sr.status == SearchRunActionStatus.Running) {
      throw new Error(sr.status)
    }
  }

  //TODO - add global parameters to access in search action params
  const buildActionParametersSuggestions = () => {
    const suggestionsTree: [] = [];
    buildParameterStoreTree(suggestionsTree);
    if (Object.keys(searchRunActionSelectedDatas).length && searchRunActionSelectedDatas[resultMeta.appSubscriptionID]?.areRowsSelected == true) {
      buildSearchActionParametersTree(resultMeta.artifactName, suggestionsTree);
    }
    setParametersSuggestionsTree(suggestionsTree);
  };

  const buildActionParameters = () => {
    if (!actionData.paramValues) {
      actionData.paramValues = {};
    }
    const artifact = artifacts.find((x) => x.name == resultMeta.artifactName)
    if (!artifact) {
      return
    }
    if (Object.keys(searchRunActionSelectedDatas).length && searchRunActionSelectedDatas[resultMeta.appSubscriptionID]?.areRowsSelected == true) {
      const action = artifact.actions.find((x) => x.id == selectedAction)
      if (!action || !action.parameters) {
        return
      }
      Object.entries(action?.parameters).forEach(([key, value]) => {
        if (!actionData.paramValues[key]) {
          actionData.paramValues[key] = `{{$.${resultMeta.artifactName}.${value}}}`
        }
      })
    }
  };

  useEffect(() => {
    loadParametersStore();
    return () => { abortController.abort("exiting search action panel page") };
  }, []);

  const loadActionProviders = async (actionId: string) => {
    try {
      setActionLoader(true);
      await getActionProviders(actionId);
    } catch (error) {
      console.log(error);
    } finally {
      setActionLoader(false);
    }
  };

  useEffect(() => {
    if (selectedAction && selectedAction != "") {
      loadActionProviders(selectedAction);
      buildActionParameters();
      buildActionParametersSuggestions();
      setShowActionList(true);
    } else {
      setShowActionList(false);
    }
  }, [selectedAction, searchRunActionSelectedDatas]);

  const getAppInfo = (providerId: string) => {
    const actionProvider = actionProvidersMap.get(selectedAction);
    return actionProvider?.providers.find((provider) => provider.id == providerId)?.appInfo;
  };

  const onActionSelect = (actionInfo: SearchActionInfo) => {
    form.setFieldValue("tenantId", resultMeta.tenantID);
    setActionCardTitle(`Action - ${actionInfo.displayName}`)
    setSelectedAction(actionInfo.id);
    form.setFieldValue("actionId", actionInfo.id);
    const runActionInfo: SearchRunActionInfo = {
      name: actionInfo.displayName,
      referenceName: generateReferenceName(actionInfo.name, currentSearchRunActions),
      description: actionInfo.description,
      actionID: actionInfo.id
    } as SearchRunActionInfo;
    setSelectedSearchRunActionInfo(runActionInfo);
  };

  const onActionChange = (actionData: any) => {
    if (actionData) {
      selectedSearchRunActionInfo.parameters = actionData.paramValues;
      const providerId = actionData.providerId as string;
      if (providerId) {
        const appInfo = getAppInfo(providerId);
        if (appInfo) {
          selectedSearchRunActionInfo.providerID = providerId;
          selectedSearchRunActionInfo.appID = appInfo.id;
          selectedSearchRunActionInfo.providerParameters = actionData.providerParamValues;
          const subscriptions = actionData.appSubscriptions as string[];
          if (subscriptions.length) {
            selectedSearchRunActionInfo.appSubscriptionID = subscriptions[0]
          }
        }
      }
      setActionData(actionData);
      setSelectedSearchRunActionInfo(selectedSearchRunActionInfo);
    }
  }

  const validateActionInputs = (actionInfo: SearchRunActionInfo) => {
    if (Object.entries(searchRunActionSelectedDatas).length == 0) {
      return false;
    }

    if (!actionInfo.actionID || actionInfo.actionID == "") {
      return false
    }
    if (!actionInfo.providerID || actionInfo.providerID == "") {
      return false;
    }
    return true
  }


  const onActionSubmit = async (values: any) => {
    form
      .validateFields()
      .then(async () => {
        if (Object.entries(searchRunActionSelectedDatas).length && validateActionInputs(selectedSearchRunActionInfo)) {
          try {
            setActionLoader(true);
            clearCurrentSearchRunAction();
            const newSearchRunAction = await runSearchActions(resultMeta.searchRunID, selectedSearchRunActionInfo, searchRunActionSelectedDatas);
            notification.success({
              message: "Action execution is pipelined succesfully",
              duration: 6,
            });
            pRetry(() => syncSearchRunActionInBackground(newSearchRunAction.id), {
              retries: 60,
              minTimeout: 5000,
              maxTimeout: 5000,
              signal: abortController.signal
            }).catch((e: FailedAttemptError) => {
              console.log("pretry sync search run status completed, exiting sync of status");
            })

            setSelectedAction("");
            setActionData({});
            setSearchCardsState(SearchResultCardType.InsightsCard, SearchResultCardState.Expanded);
          } catch (error) {
            console.log(error);
            notification.error({
              message: "Something went wrong while pipeling action execution",
              duration: 6,
            });
          } finally {
            setActionLoader(false);
          }
        } else {
          console.warn("search results shoudl be selected");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  return (
    <Card
      size="small"
      title={
        <Space direction="horizontal">
          <SvgIcon component={workflowIcons.actionShortIcon} />
          <Text style={{ color: token.colorTextLightSolid, maxWidth: "200px" }} ellipsis>
            {actionCardTitle}
          </Text>
        </Space>
      }
      styles={{
        header: {
          backgroundColor: token.colorPrimary,
          textAlign: "left",
        },
        body: {
          backgroundColor: token.colorBgContainer,
          padding: token.padding,
          maxHeight: maxHeight,
          overflowX: "hidden",
        }
      }}
      extra={
        <ArrowRightOutlined size={20}
          style={{ color: token.colorTextLightSolid }}
          onClick={() => {
            setSearchCardsState(SearchResultCardType.ActionCard, SearchResultCardState.Collapsed);
          }}
        />
      }
    >
      <div style={{ display: "flex", flexDirection: "column", paddingLeft: token.paddingXXS }}>
        {!showActionList ?
          <ActionList onSelect={onActionSelect} />
          :
          <Form
            form={form}
            name="searchOperatorCardForm"
            layout="vertical"
            autoComplete="off"
            onFinish={onActionSubmit}
          >
            <Spin spinning={actionLoader}>
              <Form.Item
                name="name"
                label={<FieldLabel label={"Name"} help={"Name of action"} />}
                initialValue={selectedSearchRunActionInfo.name}
                rules={[
                  { required: true, message: "Name is required!" },
                ]}
                extra={selectedSearchRunActionInfo.referenceName != "" && <Text italic type="secondary">Reference Name: {selectedSearchRunActionInfo.referenceName}</Text>}
              >
                <Input disabled={false}
                  onChange={(e) => {
                    selectedSearchRunActionInfo.referenceName = generateReferenceName(e.target.value, currentSearchRunActions);
                    setSelectedSearchRunActionInfo(selectedSearchRunActionInfo)
                  }}
                />
              </Form.Item>
              <Form.Item
                name="actionData"
                validateTrigger="onSubmit"
                rules={[
                  { validator: (_, value) => validateFormFields(actionDataForm) }
                ]}
              >
                <ActionForm
                  tenantId={resultMeta.tenantID}
                  actionId={selectedAction}
                  isConfigured={false}
                  editMode={true}
                  actionData={actionData}
                  hideOutputs={true}
                  suggestionsTree={parametersSuggestionsTree}
                  onRender={(form) => setActionDataForm(form)}
                  onChange={onActionChange}
                />
              </Form.Item>
            </Spin>
            <Row justify="space-between" style={{ marginTop: token.marginLG }}>
              { selectedAction != "" && 
                <Button key="back" onClick={() => setSelectedAction("")}>
                  Back
                </Button>
              }
              <Button
                key="action"
                type="primary"
                htmlType="submit"
              >
                Take Action
              </Button>
            </Row>
          </Form>
        }
      </div>
    </Card>
  );
};

export default ActionPanel;



