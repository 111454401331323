import { FC, useState } from "react";
import {
  theme,
  Tooltip,
  Card,
  Button
} from "antd";
import { OpenAIOutlined } from "@ant-design/icons";

import { SvgIcon } from "components/SvgIcon";
import { commonIcons } from "assets/icons";
import styles from "./SearchBar.module.scss";
import { useOrganizationStore } from "store";
import { AISearchQueryEngine, NativeSearchQueryEngine } from "types";


export interface SearchFilterButtonProps {
  isAiBasedQuery: boolean;
  onClick: () => void;
  onEngineChanged: (engine: string) => void;
}
export const SearchFilterButton: FC<SearchFilterButtonProps> = ({
  isAiBasedQuery,
  onClick,
  onEngineChanged,
}) => {
  const { token } = theme.useToken();
  const [hover, setHover] = useState(false);
  const { mspEnabled } = useOrganizationStore((state) => ({
    mspEnabled: state.mspEnabled,
  }));

  return (
    <>
      {mspEnabled &&
        <Tooltip
          title={"Show search options"}
          placement={"bottom"}
        >
          <Button
            style={{
              cursor: "default",
              height: "2px",
              width: "2px",
              display: "flex",
              marginRight: "10px",
              marginLeft: "2px",
              backgroundColor: hover ? token.colorPrimaryBgHover : token.colorBgMask,
            }}
            icon={
              <SvgIcon
                className={isAiBasedQuery ? styles.aiEnabled : styles.aiDisabled}
                size={14}
                component={commonIcons.filterSortIcon}
                hexColor={token.colorBgMask}
              />
            }
            onClick={() => onClick()}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          />
        </Tooltip>
      }
      {!mspEnabled &&
        <OpenAIOutlined
          className={isAiBasedQuery ? styles.aiEnabled : styles.aiDisabled}
          onClick={() => onEngineChanged(isAiBasedQuery ? NativeSearchQueryEngine : AISearchQueryEngine)}
          style={{
            color: isAiBasedQuery
              ? token.colorSuccess
              : token.colorPrimary,
            marginRight: "5px",
          }}
        />
      }
    </>
  )
};