import  { FC } from "react";
import { Card, Space, Typography, theme } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined} from "@ant-design/icons";
import { SvgIcon } from "components/SvgIcon";
const { Text } = Typography;

export interface VerticalCollapsedCardProps {
  title: string;
  maxHeight: string;
  expandLeft: boolean;
  icon?: any;
  onExpand: () => void;
}


export const VerticalCollapsedCard: FC<VerticalCollapsedCardProps> = ({
  title,
  maxHeight,
  expandLeft,
  icon,
  onExpand
}) => {
  const { token } = theme.useToken();
  return (
    <Card
      size="small"
      style={{
        minWidth: "45px",
        maxWidth: "45px"
      }}
      title={
        expandLeft ? (
          <ArrowLeftOutlined style={{ color: token.colorTextLightSolid }} size={20} onClick={ () => onExpand()} />
        ): (
          <ArrowRightOutlined style={{ color: token.colorTextLightSolid }} size={20} onClick={ () => onExpand()} />
        )
      }
      styles={{
        header: {
          textAlign: "center",
          backgroundColor: token.colorPrimary,
        },
        body: {
          backgroundColor: token.colorPrimary,
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          minHeight: maxHeight,
          maxHeight: maxHeight,
          overflow: "hidden"
        }
      }}
    >
      <Space direction="vertical">
        
        <div
          style={{
            writingMode: "sideways-lr",
            textOrientation: "mixed",
          }}
        >
          <Text 
            style={{
              color: token.colorTextLightSolid,
              fontSize: 16
            }}
          > {title} </Text>
        </div>
        <SvgIcon style={{marginTop: "2px", marginLeft: "5px"}} size={20} component={icon}/>
      </Space>
    </Card>
  )
}
