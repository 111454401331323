import { useAppConfigStore } from "store";
import { App } from "types";


export const getAppLogoUrl = (appId: string, lightMode: boolean) => {
  const appState = useAppConfigStore.getState();
  const appLogoBasePath = appState.appLogoBasePath;
  

  if(lightMode) {
    return appLogoBasePath + `/${appId}/${appId}.svg`
  }
  return appLogoBasePath + `/${appId}/${appId}_dark.svg`
}