import { useState, useEffect } from "react";
import { Form, Select, Space, Typography } from "antd";
import type { FormInstance } from 'antd';

import { FieldLabel } from "components/FieldLabel";
import { FC } from "react";
import { useDeveloperXStore } from "store";
import { OAuthGrantTypeMap } from "types";
import { validateFormFields } from "utility";
import CollapsePanel from "components/CollapsePanel";
import HttpHeaders from "../Headers";
import Labels from "../Labels";


const { Text } = Typography;


const OAuthHttpConfiguration: FC = () => {
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const [headersForm, setHeadersForm] = useState<undefined | FormInstance>(undefined);

  const {
    selectedApp,
  } = useDeveloperXStore((state) => ({
    selectedApp: state.selectedApp,
  }));

  const onValuesChange = async () => {
    // const currentValues = form.getFieldsValue(true);  
    // const newValues: any = {}; 
    // parameters && Object.entries(parameters).map(([name, value]) => {
    //   if (name in currentValues) {
    //     if (value.type == IODataType.Array) {
    //       newValues[name] = (currentValues[name] as string).split(" ");
    //     } else {
    //       newValues[name] = currentValues[name];
    //     }
    //   }
    // });
    // onChange?.(newValues);
  };

  const syncCurrentState = async () => {
    try {
      form.setFieldsValue({
        oAuthGrantTypes: selectedApp.httpConfiguration.oAuthGrantTypes,
      });

    } catch (error) {
      console.log(error);
    }
  };



  const getRules = (name: string, required: boolean): any[] => {
    const rules = [];
    if (required) {
      rules.push({ required: true, message: `${name} is required!` })
    }
    return rules;
  };



  useEffect(() => {
    syncCurrentState();
  }, [selectedApp]);

  return (
    <Form
      form={form}
      name="oAuthConfigurartion"
      layout="vertical"
      autoComplete="off"
      onValuesChange={onValuesChange}
      disabled={false}
    >
      <Space size={0} direction="vertical" style={{ display: 'flex' }}>
        <Form.Item
          name="oAuthGrantTypes"
          required
          label={
            <FieldLabel label={"OAuth Grant Type"} help={"Supported OAuth Grant Type"} />
          }
          rules={[
            { required: true, message: `OAuth grant type must be selected!` }
          ]}
        >
          <Select
            placeholder={`Select OAuth Grant Type`}
            allowClear
            mode="multiple"
          >
            {Object.entries(OAuthGrantTypeMap).map(([k, v]) => (
              <Select.Option key={k} value={v}>
                {k}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <CollapsePanel
          name={<Text>{"Headers"}</Text>}
          ghost={false}
          collapsePanel={true}
        >
          <Form.Item
            name="headers"
            rules={[
              { validator: (_, value) => validateFormFields(headersForm) },
            ]}
          >
            <HttpHeaders
              editMode={true}
              headers={selectedApp?.httpConfiguration?.headers?.oauth}
              onRender={(form) => setHeadersForm(form)}
            />
          </Form.Item>
        </CollapsePanel>
        <CollapsePanel
          name={<Text>{"Labels"}</Text>}
          ghost={false}
          collapsePanel={true}
        >
          <Form.Item
            name="labels"
            rules={[]}
          >
            <Labels
              editMode={true}
              labels={selectedApp?.httpConfiguration?.labels?.oauth || []}
              onChange={(labels) => form.setFieldValue("labels", labels)}
            />
          </Form.Item>
        </CollapsePanel>

      </Space>
    </Form>
  );
};

export default OAuthHttpConfiguration;
