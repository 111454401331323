import { Deserializer } from 'ts-jsonapi';

// export interface SerializerOptions {
//   [index: string]: any,
//   id: string,
//   attributes: Array<string>,
//   topLevelLinks?: Links,
//   keyForAttribute?: ((attribute: string) => string) | caseOptions,
//   ref?: string | boolean | Function,
//   typeForAttribute?: (attribute: any, user: any) => any,
//   nullIfMissing?: boolean,
//   pluralizeType?: boolean,
//   ignoreRelationshipData?: boolean,
//   relationshipLinks?: Links,
//   relationshipMeta?: RelationshipMeta,
//   dataLinks?: Links,
//   dataMeta?: any,
//   included?: boolean,
//   includedLinks?: Links,
//   embed?: boolean,
//   meta?: any
// }


const relationshipAttributeMapping = new Map<string, string>([
  ["app-subscriptions", "appSubscriptions"],
  ["edge-subscriptions", "edgeSubscriptions"],
  ["case-attachments", "caseAttachments"],
  ["search-runs", "searchRuns"],
  ["cases", "links"],
]);

export function keyForAttribute(attribute: string): string {
  const modifiedAttribute = relationshipAttributeMapping.get(attribute);
  if (modifiedAttribute) {
    return modifiedAttribute;
  }
  return attribute;
}
export const jsonApiDeserializer = new Deserializer({
  keyForAttribute: keyForAttribute,
  pluralizeType: false,
});

export const camelCaseEnforcerJsonApiDeserializer = new Deserializer({ keyForAttribute: 'camelCase' });

export const organizationId = "bda37b9d-3403-442a-9c10-2c52796a5d45";
export const defaultEdgeId = "264b49df-9842-40ff-800d-8be069100903"; 
//SendEmail action for approvals
export const sendEmailActionId = "3c960ec6-4146-469e-b53e-285b4cda0d49";

//Updater update setting uuid
export const updateSettingsId = "4cfb4b19-2ee6-4baa-bfb7-4b67d1a6bedd"

//base api's path
